import { useQuery } from "react-query";
import { listarCaixas } from "../../services/api";

const useGetCaixa = (empresaId, page, startDate, endDate) => {
  return useQuery(["caixas", empresaId, page, startDate, endDate], () =>
    listarCaixas(empresaId, page, startDate, endDate)
  ); // Save Itens in cache
};

export default useGetCaixa;
