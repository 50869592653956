import { Form, Formik } from "formik";
import * as Yup from "yup";
import CurrencyInput from "../../../Form/CurrencyInput";
import { useContext, useMemo, useState } from "react";
import { CaixaContext } from "../../../../contexts/caixaContext";
import { convertPrice } from "../../../../utils/convertPrice";
import { formatPrice } from "../../../../utils/formatPrice";

import "./styles.css";
import { fecharCaixa } from "../../../../services/api";
import { AuthContext } from "../../../../contexts/auth";
import Loading from "react-loading";

import { toast } from "react-toastify";

const CloseCaixaForm = ({ onClose }) => {
  const {
    caixa,
    caixaValue,
    refetchStatus,
    dinheiroValue,
    cartaoValue,
    pixValue,
  } = useContext(CaixaContext);

  const [isSenting, setIsSenting] = useState(false);

  const validationSchema = Yup.object({
    withdraw: Yup.string(),
    valor_informado_cartao: Yup.string().required(
      "Obrigatório informar o valor final em cartão no caixa."
    ),
    valor_informado_dinheiro: Yup.string().required(
      "Obrigatório informar o valor final em dinheiro no caixa."
    ),
    valor_informado_pix: Yup.string().required(
      "Obrigatório informar o valor final em pix no caixa."
    ),
  });

  const { user } = useContext(AuthContext);
  const initialValues = {
    value: "",
    withdraw: "",
    valor_informado_dinheiro: "",
    valor_informado_cartao: "",
    valor_informado_pix: "",
  };

  const handleSubmit = async (values) => {
    const {
      withdraw,
      value,
      valor_informado_dinheiro,
      valor_informado_cartao,
      valor_informado_pix,
    } = values;
    const saldo_final = convertPrice(value) - convertPrice(withdraw);
    try {
      setIsSenting(true);
      const response = await fecharCaixa(
        caixa.id_empresa,
        caixa._id,
        user._id,
        saldo_final,
        convertPrice(valor_informado_dinheiro),
        convertPrice(valor_informado_cartao),
        convertPrice(valor_informado_pix)
      );
      onClose();
      refetchStatus();
    } catch (e) {
      if (e.response?.data?.msg) {
        toast(e.response.data.msg, { type: "error" });
      } else {
        toast("Erro ao fechar carrinho", { type: "error" });
      }
    } finally {
      setIsSenting(false);
    }
    //handleOpen(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, handleChange, values }) => (
        <Form>
          <p>{errors.value}</p>
          <div className="close-container">
            <div className="close-form-content">
              <div className="input-box">
                <label>Valor esperado (PIX)</label>
                <input
                  disabled={true}
                  id="finalValue"
                  type="text"
                  name="finalValue"
                  value={`R$ ${formatPrice(pixValue)}`}
                />
              </div>
              <div className="input-box">
                <label>
                  Valor em caixa<span style={{ color: "red" }}>*</span>
                </label>
                <CurrencyInput
                  label="valor_informado_pix"
                  name="valor_informado_pix"
                  type="text"
                  placeholder="R$ 0,00"
                />
              </div>
              <div className="input-box">
                <label>Diferença de valor</label>
                <CurrencyInput
                  disabled={true}
                  style={{
                    backgroundColor:
                      pixValue - convertPrice(values.valor_informado_pix) <= 0
                        ? "var(--bs-teal)"
                        : "var(--bs-red)",
                    color: "white",
                  }}
                  label="difference_pix"
                  name="difference_pix"
                  type="text"
                  value={`R$ ${formatPrice(
                    Math.abs(
                      pixValue - convertPrice(values.valor_informado_pix)
                    )
                  )}`}
                />
              </div>
            </div>
            <div className="close-form-content">
              <div className="input-box">
                <label>Valor esperado (DINHEIRO)</label>
                <input
                  disabled={true}
                  id="finalValue"
                  type="text"
                  name="finalValue"
                  value={`R$ ${formatPrice(dinheiroValue)}`}
                />
              </div>
              <div className="input-box">
                <label>
                  Valor em caixa<span style={{ color: "red" }}>*</span>
                </label>
                <CurrencyInput
                  label="valor_informado_dinheiro"
                  name="valor_informado_dinheiro"
                  type="text"
                  placeholder="R$ 0,00"
                />
              </div>
              <div className="input-box">
                <label>Diferença de valor</label>
                <CurrencyInput
                  disabled={true}
                  style={{
                    backgroundColor:
                      dinheiroValue -
                        convertPrice(values.valor_informado_dinheiro) <=
                      0
                        ? "var(--bs-teal)"
                        : "var(--bs-red)",
                    color: "white",
                  }}
                  label="difference_dinheiro"
                  name="difference_dinheiro"
                  type="text"
                  value={`R$ ${formatPrice(
                    Math.abs(
                      dinheiroValue -
                        convertPrice(values.valor_informado_dinheiro)
                    )
                  )}`}
                />
              </div>
            </div>
            <div className="close-form-content">
              <div className="input-box">
                <label>Valor esperado (CARTÃO)</label>
                <input
                  disabled={true}
                  id="finalValue"
                  type="text"
                  name="finalValue"
                  value={`R$ ${formatPrice(cartaoValue)}`}
                />
              </div>
              <div className="input-box">
                <label>
                  Valor em caixa <span style={{ color: "red" }}>*</span>
                </label>
                <CurrencyInput
                  label="valor_informado_cartao"
                  name="valor_informado_cartao"
                  type="text"
                  placeholder="R$ 0,00"
                />
              </div>
              <div className="input-box">
                <label>Diferença de valor</label>
                <CurrencyInput
                  disabled={true}
                  style={{
                    backgroundColor:
                      cartaoValue -
                        convertPrice(values.valor_informado_cartao) <=
                      0
                        ? "var(--bs-teal)"
                        : "var(--bs-red)",
                    color: "white",
                  }}
                  label="difference"
                  name="difference"
                  type="text"
                  value={`R$ ${formatPrice(
                    Math.abs(
                      cartaoValue - convertPrice(values.valor_informado_cartao)
                    )
                  )}`}
                />
              </div>
            </div>
            <div className="close-form-content">
              <div className="input-box">
                <label>Valor esperado</label>
                <input
                  disabled={true}
                  id="finalValue"
                  type="text"
                  name="finalValue"
                  value={`R$ ${formatPrice(caixaValue)}`}
                />
              </div>
              <div className="input-box">
                <label>
                  Valor em caixa<span style={{ color: "red" }}>*</span>
                </label>
                <CurrencyInput
                  disabled={true}
                  name="value"
                  type="text"
                  value={`R$ ${formatPrice(
                    convertPrice(values.valor_informado_pix) +
                      convertPrice(values.valor_informado_dinheiro) +
                      convertPrice(values.valor_informado_cartao)
                  )}`}
                  placeholder="R$ 0,00"
                />
              </div>
              <div className="input-box">
                <label>Diferença de valor</label>
                <CurrencyInput
                  disabled={true}
                  style={{
                    backgroundColor:
                      caixaValue -
                        (convertPrice(values.valor_informado_pix) +
                          convertPrice(values.valor_informado_dinheiro) +
                          convertPrice(values.valor_informado_cartao)) <=
                      0
                        ? "var(--bs-teal)"
                        : "var(--bs-red)",
                    color: "white",
                  }}
                  label="difference"
                  name="difference"
                  type="text"
                  value={`R$ ${formatPrice(
                    Math.abs(
                      caixaValue -
                        (convertPrice(values.valor_informado_pix) +
                          convertPrice(values.valor_informado_dinheiro) +
                          convertPrice(values.valor_informado_cartao))
                    )
                  )}`}
                />
              </div>
              <div className="input-box">
                <label>Retirar do caixa</label>
                <CurrencyInput
                  label="withdraw"
                  name="withdraw"
                  type="text"
                  placeholder="R$ 0,00"
                />
                {errors.withdraw ? (
                  <div className="error">{errors.withdraw}</div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="value-caixa">
            <div className="values-caixa-content">
              <p>Ficando no caixa total: </p>
              <p>
                R${" "}
                {formatPrice(
                  convertPrice(values.valor_informado_pix) +
                    convertPrice(values.valor_informado_dinheiro) +
                    convertPrice(values.valor_informado_cartao) -
                    convertPrice(values.withdraw)
                )}
              </p>
            </div>
          </div>
          <div className="modal-buttons">
            <button
              className="button-default cancel-button"
              type="button"
              onClick={onClose}
            >
              Cancelar
            </button>
            <button
              className="button-default confirm-button"
              type="submit"
              disabled={isSenting}
            >
              {isSenting ? (
                <>
                  <Loading type="spin" color="white" height={20} width={20} />
                  ...Enviando
                </>
              ) : (
                "Confirmar"
              )}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CloseCaixaForm;
