import React, { useContext, useState } from "react";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { FiSearch } from "react-icons/fi";

import { formatPrice } from "../../../utils/formatPrice";
import useGetCaixa from "../../../hooks/useGetCaixa";
import LeftMenu from "../../../components/LeftMenu";

import { SidebarContext } from "../../../AppRoutes";
import PermissionGate from "../../../services/PermissionGate";
import { Pagination } from "../../../components/Pagination";
import { IoMdOpen } from "react-icons/io";
import { useNavigate } from "react-router-dom";

import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";

registerLocale("pt-BR", ptBR);

function CaixaHistory() {
  const { sidebar, setSidebar } = useContext(SidebarContext);
  const empresa = localStorage.getItem("empresa");
  const empresaParse = JSON.parse(empresa);
  const empresaId = empresaParse._id;

  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    const futureDate = new Date(today.setDate(today.getDate() - 15));
    return futureDate;
  });

  const [endDate, setEndDate] = useState(new Date());

  const { data: caixasResponse, refetch } = useGetCaixa(
    empresaId,
    page,
    startDate,
    endDate
  );
  return (
    <>
      <PermissionGate permissions={["default"]}>
        <LeftMenu setSidebar={setSidebar} sidebar={sidebar} />
        <div className="content-page">
          <div className="m-5">
            <div className="list-header">
              <div className="title">
                <h1>Histórico do Caixa</h1>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "36px",
                marginRight: "36px",
              }}
            >
              <div
                className="input-box-list"
                style={{ width: "10%", padding: 0 }}
              >
                <label>Data inicial</label>
                <DatePicker
                  selected={startDate}
                  locale="pt-BR"
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => setStartDate(date)}
                />
              </div>
              <div
                className="input-box-list"
                style={{ width: "10%", padding: 0 }}
              >
                <label>Data final</label>
                <DatePicker
                  selected={endDate}
                  locale="pt-BR"
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => setEndDate(date)}
                />
              </div>
            </div>
            <div className="caixa-infos-column">
              <table className="table">
                <thead>
                  <tr /*style={{borderBottom:"2px solid #4281FF"}}*/>
                    <th scope="col" style={{ paddingLeft: "15px" }}>
                      ABERTURA
                    </th>
                    <th scope="col" style={{ paddingLeft: "15px" }}>
                      SALDO INICIAL
                    </th>
                    <th scope="col" style={{ paddingLeft: "15px" }}>
                      SALDO FINAL
                    </th>
                    <th scope="col" style={{ paddingLeft: "15px" }}>
                      FECHAMENTO
                    </th>
                    <th scope="col" style={{ paddingLeft: "15px" }}>
                      VALOR SUPRIMENTOS
                    </th>
                    <th scope="col" style={{ paddingLeft: "15px" }}>
                      VALOR SANGRIAS
                    </th>
                    <th scope="col" style={{ paddingLeft: "15px" }}>
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody className={"result search"}>
                  {caixasResponse?.data?.caixas &&
                    caixasResponse.data.caixas.map((item) => (
                      <tr key={item._id}>
                        <td>
                          {new Date(item.data_abertura).toLocaleDateString(
                            "pt-BR",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}
                        </td>
                        <td>R$ {formatPrice(item.saldo_inicial)}</td>
                        <td>R$ {formatPrice(item.saldo_final)}</td>
                        <td>
                          {new Date(item.data_abertura).toLocaleDateString(
                            "pt-BR",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}
                        </td>
                        <td>
                          R${" "}
                          {formatPrice(
                            item.lancamentos_caixa.reduce(
                              (acc, item) =>
                                item.valor >= 0 ? acc + item.valor : acc,
                              0
                            )
                          )}
                        </td>
                        <td>
                          R$
                          {formatPrice(
                            item.lancamentos_caixa.reduce(
                              (acc, item) =>
                                item.valor < 0 ? acc + item.valor : acc,
                              0
                            )
                          )}
                        </td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(`/caixa/historico/${item._id}`)
                          }
                        >
                          <IoMdOpen />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <Pagination
                onPageChange={(page) => setPage(page)}
                currentPage={page}
                totalCountOfRegisters={caixasResponse?.data?.totalCaixas || 0}
                registersPerPage={10}
              />
            </div>
          </div>
        </div>
      </PermissionGate>
    </>
  );
}

export default CaixaHistory;
