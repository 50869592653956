import React from "react";
import SuprimentoForm from "../../Forms/SuprimentoForm";

import "./styles.css";

const SuprimentoModal = ({ onClose }) => {
  return (
    <div>
      <h1 className="modal-title">Adicionar um suprimento</h1>
      <div className="modal-form">
        <SuprimentoForm onClose={onClose} />
      </div>
    </div>
  );
};

export default SuprimentoModal;
