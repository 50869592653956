import React, { useState, useContext, useEffect } from "react";
import './style.css'
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import { SidebarContext } from "../../AppRoutes";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import moment from 'moment'

import { AuthContext } from "../../contexts/auth";

import { deleteEntregador, getEntregadores } from "../../services/api";

import PermissionGate from "../../services/PermissionGate";

import { useNavigate } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu";
import ModalEditEntregador from "./modalEditEntregador";
import ConfirmDialog from "../../components/ConfirmDialog";

import { BarContext } from "../../components/LeftMenu";

import { useFormik } from "formik";
import * as Yup from "yup";

import * as FiIcons from 'react-icons/fi'
import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import * as FaIcons from 'react-icons/fa'
import { date } from "yup/lib/locale";

const Teste = styled.div`
    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;
`;



const ListEntregadores = ({list = []} ) =>{
    const { user } = useContext(AuthContext);
    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const objIdEmpresa = empresaParse._id;
    const vinculo_empresa = empresaParse.cnpj;
    const navigate = useNavigate();

    const { sidebar, setSidebar } = useContext(SidebarContext);;
    //console.log("LISTUSERS",sidebar);

    const [order, setOrder] = useState(1)
    const [columnOrder, setColumnOrder] = useState('title')

    const [entregadores, setEntregadores] = useState([]);
    const [filter, setFilter] = useState('');
    
    const [_idEntregadorEdit, set_idEntregadorEdit] = useState('');
    const [nomeEntregadorEdit, setNomeEntregadorEdit] = useState('');
    const [veiculoEdit, setVeiculoEdit] = useState('');
    const [telefoneEdit, setTelefoneEdit] = useState('');
    const [placaEdit, setPlacaEdit] = useState('');

    const [result, setResult] = useState(false);

    const [showEditEntregador, setEditEntregador] = useState(true);
    
    const [loading, setLoading] = useState(true);

    const [refresh, setRefresh] = useState(false);

    const INITIAL_DATA = {
        value: "",
        label: 'Selecione uma empresa',
    };
            
    const [selectData, setselectData] = useState(INITIAL_DATA);

    useEffect(() => {
        (async () => {
       
            const response = await getEntregadores(objIdEmpresa);
            //console.log("TESTEEE:::",user._id)
            //const teste = [response.data]
            setEntregadores(response.data.entregadores);
            console.log(response.data)
            setLoading(false);
            setRefresh(false);
            
        })();
    },[refresh]);
  
    const handleEdit = async idToEdit => {
        //setEditEntregador(!showEditEntregador);
        console.log(JSON.stringify(entregadores))
        const entregadorToEdit = entregadores.find(entregador => entregador._id === idToEdit);
        if (entregadorToEdit) {
            set_idEntregadorEdit(entregadorToEdit._id);
            setNomeEntregadorEdit(entregadorToEdit.name);
            setVeiculoEdit(entregadorToEdit.veiculo);
            console.log("entregadorToEdit.telefone>",entregadorToEdit.telefone)
            setTelefoneEdit(entregadorToEdit.telefone);
            setPlacaEdit(entregadorToEdit.placa);
            setEditEntregador(!showEditEntregador);  // Supondo que isso abra o modal
        }
        /*const response = await getEntregadores(idToEdit);
        //console.log("Infos do Edit:",response.data.user)
        //console.log(idToEdit,"----");
        if(showEditEntregador){
            set_idEntregadorEdit(idToEdit)            
            setNomeEntregadorEdit(response.data.user.name)
            setVeiculoEdit(response.data.user.email)            
            setRoleEdit(response.data.user.role)           

        }*/
    }

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [shouldDelete, setShouldDelete] = useState(false);
    const [idToDelete, setIdToDelete] = useState('');
    const handleDelete = (idRecebido) => {
        // Abre Componente de Confirmação do delete
        setConfirmOpen(true)

        setIdToDelete(idRecebido)
        if(idRecebido){
            //console.log("ID RECEBIDO:",idRecebido)
            setShouldDelete(true);
        }
    }
  
    const deleteReg = async () => {
        //console.log("O ID CLICADO FOI :::",idToDelete);
        //console.log("ID do Usuário que irá efetuar a exclusão", user._id);    
        if(shouldDelete){
            deleteEntregador(idToDelete, user._id).then( cast => {
                //console.log("RESULT DELETE:",cast);
                if(cast.status===201){
                    toast(cast.data.msg,{ autoClose: 5000 , type: "success"});
                    setRefresh(true);
                }else{
                    toast(cast.data.msg,{ autoClose: 5000 , type: "error"});
                }
            }).catch( err => {
                //console.log("ERROR:",err);
                toast(err.response.data.msg,{ autoClose: 5000 , type: "error"});
            });
        }

    }

    const handleOrder = fieldName => {
        setOrder(-order)
        setColumnOrder(fieldName)
        //console.log("order:",order);
        //console.log("fieldName",fieldName)
    }

    var arrayEntregadores = [];
    arrayEntregadores = entregadores.sort( (a, b) => {
        return a[columnOrder] < b[columnOrder] ? -order : order;
    })

    const handleRefresh = () => {
        //window.location.reload(false);
        setLoading(true);
        setRefresh(true);
    }

    const handleCadastro = () => {
        //setSidebar(!sidebarTeste);
        navigate("/cadastro-entregador");
        
    }

    
    if (filter){
        const exp = eval(`/${filter.replace(/[^\d\w]+/,'.*')}/i`)

        list = entregadores.filter( item => exp.test( item.name ) )
    }

    const handleFilter = e => {
        setFilter(e.target.value);
        setResult(!result);
    }


    if(loading){
        return (
         
        <>
        <div className="loading"><AiIcons.AiOutlineLoading3Quarters style={{fontSize:"100px", color:"rgb(180,180,180)"}}/></div>
        <PermissionGate permissions={['default']}>
      
            <LeftMenu setSidebar={setSidebar} sidebar={sidebar}></LeftMenu>

            <Teste sidebar={sidebar}>

                <div className="m-5">
                    <div className="list-header">
                        <div className="title">
                            <h1>Entregadores</h1>
                        </div>
                    </div>

                    <div style={{display:"flex", justifyContent:"space-between"/*, height:"80px"*/}}>

                        <div className="input-box-list" style={{width:"30%"}}>                
                            <input className="input-field" placeholder="Pesquisar" onChange={handleFilter}/>
                            <i className="icon"><FiIcons.FiSearch/></i>
                        </div>

                        <div className="div-buttons">
                            <div className="continue-button">                           
                                <button onClick={handleCadastro}>
                                    <AiIcons.AiOutlinePlusCircle style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Adicionar</a> 
                                </button>                            
                            </div>

                            <div className="refresh-button">                                                       
                                <button className="refresh-button" onClick={handleRefresh}>
                                    <SlIcons.SlRefresh style={{color:"#4281FF",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a >Atualizar</a> 
                                </button>
                            </div>
                        </div>

                    </div>

                    <table className="table">
                        <thead>
                            <tr style={{borderBottom:"2px solid #4281FF"}}>
                                <th scope="col">Nome</th>
                                <th scope="col">Telefone</th>
                                <th scope="col">Veículo</th>
                                <th scope="col">Placa</th>
                                <th scope="col" style={{paddingLeft:"35px"}}>...</th>
                            </tr>
                        </thead>

                        <tbody className={result?"result":"result search"}>
                                                
                        </tbody>
                    </table>    
                </div>
            </Teste>

        </PermissionGate>       
        </>


        );
    }

    return(

        <>
        <PermissionGate permissions={['default']}>
  

            <LeftMenu setSidebar={setSidebar} sidebar={sidebar}></LeftMenu>

            
            <ConfirmDialog
                title="Deletar Entregador?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={deleteReg}
            >
                Tem certeza que deseja deletar o entregador?
            </ConfirmDialog>

            <ModalEditEntregador setEditEntregador={setEditEntregador} showEditEntregador={showEditEntregador} 
            setRefresh={setRefresh}
            selectData={selectData}
            setselectData={setselectData}
            _idEntregadorEdit={_idEntregadorEdit}
            nomeEntregadorEdit={nomeEntregadorEdit} 
            veiculoEdit={veiculoEdit} 
            telefoneEdit={telefoneEdit}   
            placaEdit={placaEdit} 
            userID={user._id}
            id_empresa={objIdEmpresa}
            />

            <Teste sidebar={sidebar}>

                <div className="m-5">
                    <div className="list-header">
                        <div className="title">
                            <h1>Entregadores</h1>
                        </div>
                    </div>

                    <div style={{display:"flex", justifyContent:"space-between"/*, height:"80px"*/}}>

                        <div className="input-box-list" style={{width:"30%"}}>                
                            <input className="input-field" id="search" placeholder="Pesquisar" onChange={handleFilter}/>
                            <i className="icon"><FiIcons.FiSearch/></i>
                        </div>

                        <div className="div-buttons">
                            <div className="continue-button">                           
                                <button onClick={handleCadastro}>
                                    <AiIcons.AiOutlinePlusCircle style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Adicionar</a> 
                                </button>                            
                            </div>

                            <div className="refresh-button">                                                       
                                <button className="refresh-button" onClick={handleRefresh}>
                                    <SlIcons.SlRefresh style={{color:"#4281FF",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a >Atualizar</a> 
                                </button>
                            </div>
                        </div>

    

                    </div>
                    


                    <table className="table">
                        <thead>
                            <tr /*style={{borderBottom:"2px solid #4281FF"}}*/>
                                <th scope="col" onClick={ e => handleOrder('name')} style={{cursor:"pointer"}}>Nome<FaIcons.FaSort/></th>
                                <th scope="col" onClick={ e => handleOrder('email')} style={{cursor:"pointer"}}>Telefone<FaIcons.FaSort/></th>
                                <th scope="col" onClick={ e => handleOrder('role')} style={{cursor:"pointer"}}>Veículo<FaIcons.FaSort/></th>
                                <th scope="col" onClick={ e => handleOrder('createdAt')} style={{cursor:"pointer"}}>Placa<FaIcons.FaSort/></th>
                                <th scope="col" style={{paddingLeft:"15px"}}>Ações</th>
                            </tr>
                        </thead>

                        <tbody className={result?"result":"result search"}>
                            
                        {
                        filter ? 
                            list.map((entregador) => (
                                
                                <tr key={entregador._id}>
                                    <td>{entregador.name}</td>
                                    <td>{entregador.telefone}</td>
                                    <td>{entregador.veiculo}</td>
                                    <td>{entregador.placa}</td>                                
                                    <td>
                                        <a className="btn btn-sm btn-primary" onClick={e => handleEdit(entregador._id)}><FiIcons.FiEdit2 style={{color:"white"}}/></a>
                                        <a className="btn btn-sm btn-danger" style={{marginLeft:"5px"}} onClick={e => handleDelete(entregador._id)}><FiIcons.FiTrash2 style={{color:"white"}}/></a>
                                    </td>
                                </tr>
                            )) :
                            arrayEntregadores.map(({_id, id_entregador, name, telefone, veiculo, placa}) => (
                               
                                <tr key={_id}>
                                    <td>{name}</td>
                                    <td>{telefone}</td>
                                    <td>{veiculo}</td>     
                                    <td>{placa}</td>                              
                                    <td>
                                        <span className="btn btn-sm btn-primary" onClick={e => handleEdit(_id)}><FiIcons.FiEdit2 style={{color:"white"}}/></span>
                                        <span className="btn btn-sm btn-danger" style={{marginLeft:"5px"}} onClick={e => handleDelete(_id)}><FiIcons.FiTrash2 style={{color:"white"}}/></span>
                                    </td>
                                </tr>
                               
                            ))
                        }
                            
                        </tbody>


                    </table>

                            
                </div>
            </Teste>

        </PermissionGate>       
        </>
        

        );
    };
    
    export default ListEntregadores;