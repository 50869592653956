import styled from "styled-components";
import React, {useRef} from 'react'
import './style.css'
import PermissionGate from "../../services/PermissionGate";
import moment from 'moment'
import CryptoJS from 'crypto-js';
import { useFormik } from "formik";
import * as Yup from "yup";

import * as AiIcons from 'react-icons/ai';
import { getRevendas, getEmpresas, updateTempoEntregaEmpresa, ImprimirPedido, updateUser } from "../../services/api";
import AsyncSelect from "react-select/async";

import { AuthContext } from "../../contexts/auth";
import { useEffect, useContext, useState } from "react";

import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { FaMotorcycle, FaStore } from "react-icons/fa";
import { BiComment } from "react-icons/bi";

import { useNavigate } from "react-router-dom";	

const DivPedidoContainer = styled.div`
  z-index:15;
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ showModalAddObsItem }) => (showModalAddObsItem ? 'none' :'flex' )};
  float: left;
  min-width: 100%;
  min-height: 100%;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 5px 0;
  list-style: none;
  background-color: rgba(0,0,0,0.5);
  background-clip: padding-box;
  align-items:center;
  justify-content:center;
  opacity: ${({ showModalAddObsItem }) => (showModalAddObsItem ? '0' :'1' )};
  transition: opacity .3s ease-in-out;

  // Add keyframes for the fade-in effect
  animation: fadeIn .3s ease-in-out;
  animation-fill-mode: forwards;

  // Define the keyframes for the animation
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const DivPedido = styled.div`
    z-index:20;
    position: absolute;
    top: 15%;
    left: 30%;
    float: left;
    min-width: 40%;
    min-height: 35%;
    --max-width: 50%;
    padding: 5px 0;
    list-style: none;
    --background-color: white;
    background-color:rgb(247,247,247)!important;
    background-clip: padding-box;
    border-radius: 15px;
    padding:20px;

`;

const ModalAddObsItem = ({
    showModalAddObsItem,
    setShowModalAddObsItem,
    obsItem,
    setObsItem,
    handleSaveObsItem
}) => {
   
    const closeModalAddObsItem = () => {
        setShowModalAddObsItem(!showModalAddObsItem);
    }
    
    const saveModalAddObsItem = () => {
        handleSaveObsItem();
        setShowModalAddObsItem(!showModalAddObsItem);
    }

    const descriptionRef = useRef();
    const handleMaxCharDescricao = (e) => {
        const newValue = e.target.value;
        if (newValue.length <= 200) {
        setObsItem(newValue);
        }
    };

    return (
        <DivPedidoContainer showModalAddObsItem={showModalAddObsItem}>
            <DivPedido>                
                <div className="closeModalPedido" onClick={() => closeModalAddObsItem()}>
                    <div
                    iconname="x"
                    iconcolor="#2B2B2B"
                    iconsize={18}
                    className="iconCancel"
                    style={{ height: 18, display:'flex' }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={18}
                            height={18}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#2B2B2B"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-x"
                        >
                            <line x1={18} y1={6} x2={6} y2={18} />
                            <line x1={6} y1={6} x2={18} y2={18} />
                        </svg>
                    </div>
                </div>

                <div className="divTitleItemAdicionado">
                    <span className="divTitleItemAdicionadoSpan">Observação do Item</span> 
                </div>

              
                <div className="obsTextBoxContainerModalAddItem">
                    <div className="obsTextBoxData">
                        <textarea placeholder="Digite aqui a observação desejada" 
                        value={obsItem} onChange={handleMaxCharDescricao} 
                        maxLength="200" className="text-areaObs" 
                        ref={descriptionRef}
                        />
                        <div className="divCounterChar">{obsItem.length}/200</div>               
                    </div>
                </div>
          

                <div className="divBtnContinueCart">
                    {/*<button className="buttonContinue" onClick={()=> closeModal()}>Não preciso de troco</button>*/}
                    <div style={{width:'100%'}}>
                        <button className="buttonCart" onClick={() => saveModalAddObsItem()}>Confirmar</button>
                    </div>
                </div>

            </DivPedido>
        </DivPedidoContainer>
    )
}

export default ModalAddObsItem;