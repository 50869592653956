import React from "react";
import SangriaForm from "../../Forms/SangriaForm";

import "./styles.css";

const SangriaModal = ({ onClose }) => {
  return (
    <div>
      <h1 className="modal-title">Adicionar uma sangria</h1>
      <div className="modal-form">
        <SangriaForm onClose={onClose} />
      </div>
    </div>
  );
};

export default SangriaModal;
