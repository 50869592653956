import React, { useContext, useState } from "react";

import LeftMenu from "../../components/LeftMenu";

import { SidebarContext } from "../../AppRoutes";
import PermissionGate from "../../services/PermissionGate";

import ClosedCaixa from "./ClosedCaixa";
import OpenedCaixa from "./OpenedCaixa";
import { CaixaProvider } from "../../contexts/caixaContext";
import CaixaComponent from "./CaixaComponent";

const Caixa = () => {
  const { sidebar, setSidebar } = useContext(SidebarContext);

  return (
    <>
      <PermissionGate permissions={["default"]}>
        <LeftMenu setSidebar={setSidebar} sidebar={sidebar} />
        <CaixaProvider>
          <CaixaComponent />
        </CaixaProvider>
      </PermissionGate>
    </>
  );
};

export default Caixa;
