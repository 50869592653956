import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './style.css'
import PermissionGate from "../../services/PermissionGate";
import LeftMenu from "../../components/LeftMenu";
import styled from 'styled-components';
import QRCode from 'qrcode.react';
import { SidebarContext } from "../../AppRoutes";
import { getPedidosByStatus, getPedidosFinalizadosHistorico } from "../../services/api";
import moment from 'moment'
import momentTz from 'moment-timezone';
import CryptoJS from 'crypto-js';
import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import { Doughnut } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend
} from 'chart.js';

const centerTextPlugin = {
    id: 'centerTextPlugin',
    beforeDraw: (chart) => {
      const ctx = chart.ctx;
      ctx.save(); // Salva o estado atual do contexto antes de fazer alterações

      const quantidade = chart.data.datasets[0].data[0];
      const faturamento = chart.data.datasets[0].faturamento;

      const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
      const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

      // Ajusta a fonte e posição para a quantidade
      ctx.font = 'bold 16px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'bottom';
      ctx.fillText(quantidade, centerX, centerY + 40); // Desloca a quantidade para cima

      // Ajusta a fonte e posição para o faturamento
      ctx.font = 'bold 14px Arial';
      ctx.textBaseline = 'top';
      ctx.fillText(`R$ ${faturamento.toLocaleString()}`, centerX, centerY + 50); // Desloca o faturamento para baixo

      ctx.restore(); // Restaura o contexto para o estado anterior
    }
};

ChartJS.register(ArcElement, Tooltip, Legend, centerTextPlugin);

const Teste = styled.div`

    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;
`;

const Desempenho = () => {

    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user')
    const user = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    const userParse = JSON.parse(user)
    const userID = userParse._id;

    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const idEmpresa = empresaParse.id_empresa;
    const vinculo_empresa = empresaParse.cnpj;

    const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const [pedidosEmAnalise, setPedidosEmAnalise] = useState([]);
    const [pedidosEmProducao, setPedidosEmProducao] = useState([]);
    const [pedidosEmEntrega, setPedidosEmEntrega] = useState([]);
    const [pedidosFinalizadosHoje, setPedidosFinalizadosHoje] = useState([]);
    const [faturamentoTotalHoje, setFaturamentoTotalHoje] = useState(0);
    const [pedidosFinalizadosHistorico, setPedidosFinalizadosHistorico] = useState([]);
    const [pedidosEntregues, setPedidosEntregues] = useState([]);
    const [pedidosRetirados, setPedidosRetirados] = useState([]);
    const [faturamentoTotalPedidosFinalizados, setFaturamentoTotalPedidosFinalizados] = useState(0);
    const [ticketMedioPedidos, setTicketMedioPedidos] = useState(0);
    const [percentualFaturamento, setPercentualFaturamento] = useState(0);
    const [percentualPedidos, setPercentualPedidos] = useState(0);
    const [percentualTicketMedio, setPercentualTicketMedio] = useState(0);        

    // State para controlar o período selecionado
    const [periodo, setPeriodo] = useState('7dias'); // [7dias, 15dias, 30dias, hoje]
    //const [datePeriod, setDatePeriod] = useState(moment().subtract(6, 'days').format('DD/MM/YYYY'));
    const [datePeriod, setDatePeriod] = useState(momentTz().tz('America/Sao_Paulo').subtract(6, 'days').format('DD/MM/YYYY'));
    

    // Dados para os gráficos de pedidos por modalidade
    const [data, setData] = useState({
        entregas: { quantidade: 0, faturamento: 0 },
        retiradas: { quantidade: 0, faturamento: 0 },
        taxa_entrega: { quantidade: 0, faturamento: 0 }
    });

    const commonOptions = {
        rotation: -90,// * Math.PI / 180,  // Start angle to make a semicircle
        circumference: 180,// * Math.PI / 180,  // Draw only half circle
        cutout: '80%',  // Thicker gauge for better visibility
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: true // Desabilita as tooltips padrão
            }
        },
        animation: {
            animateRotate: true
        },
        elements: {
            arc: {
                borderWidth: 0
            }
        }
    };

    // Incluindo o plugin no gráfico
    const optionsEntregaRetirada = {
        ...commonOptions,
        plugins: {
        ...commonOptions.plugins,
        centerTextPlugin // Integração do plugin personalizado
        }
    };
   

    const handleChangePeriodo = (novoPeriodo) => {
        setPeriodo(novoPeriodo);
        // Fetch data based on the selected period
        /*if(novoPeriodo === 'hoje'){
            setDatePeriod(moment().format('DD/MM/YYYY'));
        }
        if(novoPeriodo === '7dias'){
            setDatePeriod(moment().subtract(6, 'days').format('DD/MM/YYYY'));
        }
        if(novoPeriodo === '15dias'){
            setDatePeriod(moment().subtract(14, 'days').format('DD/MM/YYYY'));
        }
        if(novoPeriodo === '30dias'){
            setDatePeriod(moment().subtract(29, 'days').format('DD/MM/YYYY'));
        }*/
        if (novoPeriodo === 'hoje') {
            setDatePeriod(momentTz().tz('America/Sao_Paulo').format('DD/MM/YYYY'));
        }
        if (novoPeriodo === '7dias') {
            setDatePeriod(momentTz().tz('America/Sao_Paulo').subtract(6, 'days').format('DD/MM/YYYY'));
        }
        if (novoPeriodo === '15dias') {
            setDatePeriod(momentTz().tz('America/Sao_Paulo').subtract(14, 'days').format('DD/MM/YYYY'));
        }
        if (novoPeriodo === '30dias') {
            setDatePeriod(momentTz().tz('America/Sao_Paulo').subtract(29, 'days').format('DD/MM/YYYY'));
        }        
    };

    const fetchData = async () => {
        //const { vinculo_empresa } = response.data.vinculo; // Ajuste conforme a estrutura de sua resposta
        const forReport = true;
        const pedidosAnalise = await getPedidosByStatus(userID, idEmpresa, vinculo_empresa, '1');
        const pedidosProducao = await getPedidosByStatus(userID, idEmpresa, vinculo_empresa, '2');
        const pedidosEntrega = await getPedidosByStatus(userID, idEmpresa, vinculo_empresa, '3');
        const pedidosFinalizados = await getPedidosByStatus(userID, idEmpresa, vinculo_empresa, '4', forReport);

        //console.log("pedidosFinalizados>",JSON.stringify(pedidosFinalizados))
        setPedidosEmAnalise(pedidosAnalise.data.pedidos);
        setPedidosEmProducao(pedidosProducao.data.pedidos);
        setPedidosEmEntrega(pedidosEntrega.data.pedidos);
        setPedidosFinalizadosHoje(pedidosFinalizados.data.pedidos);

        /*let faturamentoTotal = 0;

        pedidosFinalizados.data.pedidos.forEach(pedido => {  
            if(pedido.valor_total !== undefined){                
                faturamentoTotal += pedido.valor_total;
            }
        });
        console.log("faturamentoTotal>", faturamentoTotal)
        setFaturamentoTotalHoje(faturamentoTotal.toFixed(2).toString().replace(".", ","));*/
        let faturamentoTotal = 0;
        //const hoje = moment().startOf('day');
        const hoje = momentTz().tz('America/Sao_Paulo').startOf('day');

        pedidosFinalizados.data.pedidos.forEach(pedido => {
            const createdAtDate = momentTz(pedido.createdAt).tz('America/Sao_Paulo')//moment(pedido.createdAt);
            if (createdAtDate.isSame(hoje, 'day') && pedido.valor_total !== undefined) {
                faturamentoTotal += pedido.valor_total;
            }
        });

        console.log("faturamentoTotal>", faturamentoTotal);
        setFaturamentoTotalHoje(faturamentoTotal.toFixed(2).toString().replace(".", ","));
    
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        console.log(pedidosEntregues)
        const fetchPedidosFinalizados = async () => {
            try {
                const response = await getPedidosFinalizadosHistorico(userID, idEmpresa, vinculo_empresa, '4', periodo);
                if (response.data.pedidos) {
                    const pedidos = response.data.pedidos;
                    const pedidosComparativos = response.data.pedidosComparativos;
                    console.log("pedidosComparativos>",pedidosComparativos)
                    setPedidosFinalizadosHistorico(pedidos);

                    // Calcular faturamento total e ticket médio para o período atual
                    let faturamentoTotalAtual = 0;
                    pedidos.forEach(pedido => {
                        if (pedido.valor_total !== undefined) {
                            faturamentoTotalAtual += pedido.valor_total;
                        }                       
                    });
                    const entregues = pedidos.filter(pedido => pedido.entrega.tipo_entrega.toLowerCase() === 'entrega');
                    const retirados = pedidos.filter(pedido => pedido.entrega.tipo_entrega.toLowerCase() === 'retirada');

                    setPedidosEntregues(entregues);
                    setPedidosRetirados(retirados);

                    const faturamentoEntregues = entregues.reduce((total, pedido) => total + (pedido.valor_total || 0), 0);
                    const faturamentoRetirados = retirados.reduce((total, pedido) => total + (pedido.valor_total || 0), 0);
                    const faturamentoTaxa_entrega = entregues.reduce((total, pedido) => total + (pedido.entrega.valor || 0), 0);

                    setData({
                        entregas: {
                            quantidade: entregues.length,
                            faturamento: faturamentoEntregues.toFixed(2).toString().replace(".", ",")
                        },
                        retiradas: {
                            quantidade: retirados.length,
                            faturamento: faturamentoRetirados.toFixed(2).toString().replace(".", ",")
                        },
                        taxa_entrega: {
                            quantidade: entregues.length,
                            faturamento: faturamentoTaxa_entrega.toFixed(2).toString().replace(".", ",")
                        }
                    });

                    const ticketMedioAtual = pedidos.length ? (faturamentoTotalAtual / pedidos.length) : 0;

                     // Filtrar pedidos criados hoje
                     //const hoje = moment().startOf('day');
                     const hoje = momentTz().tz('America/Sao_Paulo').startOf('day');
                     
                     const pedidosCriadosHoje = pedidos.filter(pedido => {
                         const createdAtDate = momentTz(pedido.createdAt).tz('America/Sao_Paulo')//moment(pedido.createdAt);
                         return createdAtDate.isSame(hoje, 'day');
                     });

                    setFaturamentoTotalPedidosFinalizados(faturamentoTotalAtual.toFixed(2).toString().replace(".", ","));
                    setTicketMedioPedidos(ticketMedioAtual.toFixed(2).toString().replace(".", ","));

                    // Calcular faturamento total e ticket médio para o período comparativo
                    let faturamentoTotalComparativo = 0;
                    pedidosComparativos.forEach(pedido => {
                        if (pedido.valor_total !== undefined) {
                            faturamentoTotalComparativo += pedido.valor_total;
                        }
                    });

                    const ticketMedioComparativo = pedidosComparativos.length ? (faturamentoTotalComparativo / pedidosComparativos.length) : 0;

                    // Calcular percentuais de aumento ou diminuição
                    const percentualFaturamento = faturamentoTotalComparativo ? ((faturamentoTotalAtual - faturamentoTotalComparativo) / faturamentoTotalComparativo) * 100 : 100;
                    const percentualPedidos = pedidosComparativos.length ? ((pedidos.length - pedidosComparativos.length) / pedidosComparativos.length) * 100 : 100;
                    const percentualTicketMedio = ticketMedioComparativo ? ((ticketMedioAtual - ticketMedioComparativo) / ticketMedioComparativo) * 100 : 100;

                    setPercentualFaturamento(percentualFaturamento);
                    setPercentualPedidos(percentualPedidos);
                    setPercentualTicketMedio(percentualTicketMedio);
                }
            } catch (error) {
                console.error('Erro ao buscar pedidos finalizados:', error);
            }
        };

        fetchPedidosFinalizados();
    }, [periodo]);

    return (
        <>
        <PermissionGate permissions={['default']}>
    

            <LeftMenu setSidebar={setSidebar} sidebar={sidebar}/>

            <Teste sidebar={sidebar}>
                <div className="m-5">                
                    <form style={{marginTop:"-30px"}}>
                    
                        <div className="form-header" style={{marginBottom:"0px"}}>
                            <div className="title">
                                <h1>Meu Desempenho</h1>
                            </div>
                        </div>


                        <div className="desempenho-container">
                            <div className="desempenho-item desempenho-azul">
                                <span>Faturamento de hoje</span>
                                <strong>R$ {faturamentoTotalHoje}</strong>
                            </div>
                            <div className="desempenho-item desempenho-laranja">
                                <span>Em análise agora</span>
                                <strong>{pedidosEmAnalise.length}</strong>
                            </div>
                            <div className="desempenho-item desempenho-amarelo">
                                <span>Em produção agora</span>
                                <strong>{pedidosEmProducao.length}</strong>
                            </div>
                            <div className="desempenho-item desempenho-verde">
                                <span>Pronto para entrega</span>
                                <strong>{pedidosEmEntrega.length}</strong>
                            </div>
                        </div>

                        <div className="desempenho-adicional-container">
                            <div className="periodo-selecao">
                                <div>
                                    <span>Período: {`${datePeriod} à ${momentTz().tz('America/Sao_Paulo').format('DD/MM/YYYY')}`}</span>
                                </div>
                                <div>
                                    <button type="button" onClick={() => handleChangePeriodo('hoje')} className={periodo === 'hoje' ? 'ativo' : ''} style={{minWidth: "100px"}}>Hoje</button>
                                    <button type="button" onClick={() => handleChangePeriodo('7dias')} className={periodo === '7dias' ? 'ativo' : ''}>Últimos 7 dias</button>
                                    <button type="button" onClick={() => handleChangePeriodo('15dias')} className={periodo === '15dias' ? 'ativo' : ''}>Últimos 15 dias</button>
                                    <button type="button" onClick={() => handleChangePeriodo('30dias')} className={periodo === '30dias' ? 'ativo' : ''}>Últimos 30 dias</button>
                                </div>
                            </div>                            
                            <div className="desempenho-metricas">
                                <div className="metrica">
                                    <span>Faturamento</span>
                                    <strong>R$ {faturamentoTotalPedidosFinalizados}</strong>
                                    <small style={{ color: percentualFaturamento >= 0 ? 'green' : 'red' }}>
                                        {percentualFaturamento >= 0 ? `▲ ${percentualFaturamento.toFixed(2)}%` : `▼ ${percentualFaturamento.toFixed(2)}%`}
                                    </small>
                                </div>
                                <div className="metrica">
                                    <span>Pedidos</span>
                                    <strong>{pedidosFinalizadosHistorico.length}</strong>
                                    <small style={{ color: percentualPedidos >= 0 ? 'green' : 'red' }}>
                                        {percentualPedidos >= 0 ? `▲ ${percentualPedidos.toFixed(2)}%` : `▼ ${percentualPedidos.toFixed(2)}%`}
                                    </small>
                                </div>
                                <div className="metrica">
                                    <span>Ticket médio</span>
                                    <strong>R$ {ticketMedioPedidos}</strong>
                                    <small style={{ color: percentualTicketMedio >= 0 ? 'green' : 'red' }}>
                                        {percentualTicketMedio >= 0 ? `▲ ${percentualTicketMedio.toFixed(2)}%` : `▼ ${percentualTicketMedio.toFixed(2)}%`}
                                    </small>
                                </div>
                            </div>
                            <div className="desempenho-graficos">                    
                                <div className="grafico">
                                <Doughnut data={{
                                    datasets: [{
                                        data: [data.entregas.quantidade, pedidosFinalizadosHistorico.length - data.entregas.quantidade], // porcentagem de preenchimento
                                        backgroundColor: ['#007bff', '#e9ecef'],
                                        //label: ['Entrega', 'Outros'],
                                        faturamento: data.entregas.faturamento // Passa o faturamento para uso no plugin
                                    }]
                                }} options={optionsEntregaRetirada} />
                                    <span>Entrega</span>
                                </div>
                                <div className="grafico">
                                <Doughnut data={{
                                    datasets: [{
                                        data: [data.retiradas.quantidade, pedidosFinalizadosHistorico.length - data.retiradas.quantidade], // porcentagem de preenchimento
                                        backgroundColor: ['#28a745', '#e9ecef'],
                                        faturamento: data.retiradas.faturamento // Passa o faturamento para uso no plugin
                                    }]
                                }} options={optionsEntregaRetirada} />
                                    <span>Retirada</span>
                                </div>
                                <div className="grafico">
                                <Doughnut data={{
                                    datasets: [{
                                        data: [data.taxa_entrega.quantidade, pedidosFinalizadosHistorico.length - data.taxa_entrega.quantidade], // porcentagem de preenchimento
                                        backgroundColor: ['red', '#e9ecef'],
                                        faturamento: data.taxa_entrega.faturamento // Passa o faturamento para uso no plugin
                                    }]
                                }} options={optionsEntregaRetirada} />
                                    <span>Taxa de Entrega</span>
                                </div>
                            </div>
                            
                        </div>

                    </form>
                </div>
            </Teste>
                
        </PermissionGate>       
        </>
    )
}

export default Desempenho;