import React, { useState } from 'react';
import logoPedeJA from '../../img/logoPedeJA.png';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation } from 'react-router-dom';

import { cpf } from 'cpf-cnpj-validator';
import { cnpj } from 'cpf-cnpj-validator';
import { toast } from 'react-toastify';

const AddEmpresa = () => {
    
    const location = useLocation();
    const { infoEmpresaObj, passwordObj, linkObj } = location.state || {};
    //console.log(infoEmpresaObj);

    const navigate = useNavigate();
    const [optSelected, setOptSelected] = useState('info');
    const [cpfCnpjValue, setCpfCnpjValue] = useState(infoEmpresaObj?infoEmpresaObj.cpf_cnpj:"");
    const [hasComputer, setHasComputer] = useState(infoEmpresaObj?infoEmpresaObj.hasComputer:"");


    const handleOptSelected = (opt) => {        

        if(opt === "credential") {
            if(infoEmpresaObj){
                setOptSelected(opt);
                navigate("/credenciais", {state: {infoEmpresaObj, passwordObj, linkObj}});
            }
            else {
                toast('Preencha as informações da conta', {autoClose:5000, type: 'warning'});
            }
        }

        if(opt === "import") {
            if(passwordObj){
                setOptSelected(opt);
                navigate("/integracao", {state: {infoEmpresaObj, passwordObj, linkObj}});
            } else {
                toast('Preencha as credenciais', {autoClose:5000, type: 'warning'});
            }            
        }
    }



    //Validações
    const SignUpSchema = Yup.object().shape({
        establishmentName: Yup.string().required('Nome do estabelecimento é obrigatório'),
        personName: Yup.string().required('Nome é obrigatório'),
        phone: Yup.string().required('Telefone é obrigatório'),
        email: Yup.string().email('E-mail inválido').required('E-mail é obrigatório'),

        cpf_cnpj: Yup.string().test  //validar cpf ou cnpj      
        ('cpf_cnpj', 'CPF ou CNPJ inválido', value => {
            if (value) {
                const regex = /[.]/;
                const hasPattern = regex.test(value);
                //console.log(hasPattern)
                //var semMask = value;
                if (hasPattern) {   
                    value = value.replace(/\D/g, '');
                    //console.log(value)
                } if (value.length === 11) {
                    return cpf.isValid(value);
                } else if (value.length === 14) {
                    return cnpj.isValid(value);
                }
            }
            return false;
        })

    });

    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        //enableReinitialize: true,
        initialValues: {    
            establishmentName: infoEmpresaObj?infoEmpresaObj.establishmentName:"",
            personName: infoEmpresaObj?infoEmpresaObj.personName:"",
            phone: infoEmpresaObj?infoEmpresaObj.phone:"",
            email: infoEmpresaObj?infoEmpresaObj.email:"",      
            cpf_cnpj: infoEmpresaObj ?
                        infoEmpresaObj.cpf_cnpj : "",
        },
        onSubmit: (values) => {
          
          const establishmentName = values.establishmentName;
          const personName = values.personName;
          const phone = values.phone;
          const email = values.email;
          var cpf_cnpj = values.cpf_cnpj;  
          const regex = /[.]/;
          const hasPattern = regex.test(cpf_cnpj);   
          if(!hasPattern) {
            if(cpf_cnpj.length === 11) {    
                cpf_cnpj = cpf.format(cpf_cnpj);
            } else if(cpf_cnpj.length === 14) {
                cpf_cnpj = cnpj.format(cpf_cnpj);
            }
          } 

          //console.log("dados>",establishmentName, personName, phone, email, cpf_cnpj)
          const infoEmpresaObj = {
            establishmentName: establishmentName,
            hasComputer: hasComputer,
            personName: personName,
            phone: phone,
            email: email,
            cpf_cnpj: cpf_cnpj
          };

          navigate(`/credenciais`, { state: { infoEmpresaObj, passwordObj, linkObj } });
          //handleSubmitAPI();   
          
        },
    });

    const dynamicMask = () => {
        if (!cpfCnpjValue || cpfCnpjValue.length === 0) 
            return "999999999999999999";
    
        if (cpfCnpjValue.replace(/\D/g, "").length < 12) {
            return "999.999.999-999999";
         } else {
            return "99.999.999/9999-99";
        }
    }
    
    return (    
        <div className='containerAddEmpresa'>
            <div className='divSidePanel'>
                <div className='divSidePanelContent'>
                    <div className='divLogo'>
                        <img width={140} height={42} src={logoPedeJA} alt='logoPedeJA' />
                    </div>
                    <div className='divMenu'>
                        <div className='divMenuItem'>          
                            <div style={{display:'flex', flexDirection:'row'}}>                 
                                {optSelected==="info"?<div className='menuItemSelected'/>:null}
                                <p className={optSelected==="info"?'btnOptionsAddEmpresa checkedOptAddEmpresa':"btnOptionsAddEmpresa"} onClick={() => handleOptSelected("info")}>1. Informações da Conta</p>
                            </div>
                            <div style={{display:'flex', flexDirection:'row'}}>
                                {optSelected==="credential"?<div className='menuItemSelected'/>:null}
                                <p className={optSelected==="credential"?'btnOptionsAddEmpresa checkedOptAddEmpresa':"btnOptionsAddEmpresa"} onClick={() => handleOptSelected("credential")}>2. Senha</p>
                            </div>
                            <div style={{display:'flex', flexDirection:'row'}}>
                                {optSelected==="import"?<div className='menuItemSelected'/>:null}
                                <p className={optSelected==="import"?'btnOptionsAddEmpresa checkedOptAddEmpresa':"btnOptionsAddEmpresa"} onClick={() => handleOptSelected("import")}>3. Importar Cardápio</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>      
          



            <div className="containerAddEmpresaForm">
                <form onSubmit={formik.handleSubmit} className="formAddEmpresa">

                    <div className='informationsAccount'>

                        <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px', backgroundColor: 'white', color: '#333' }}>
                            <h2>Informações do Delivery</h2>
                            <div style={{ marginBottom: '20px' }}>
                            <label htmlFor="establishmentName" style={{ display: 'block', marginBottom: '5px' }}>Qual é o nome oficial do seu estabelecimento?<span style={{color:'red'}}>*</span></label>
                            <input type="text" id="establishmentName" placeholder="Nome do estabelecimento" 
                            value={formik.values.establishmentName}
                            onChange={formik.handleChange}
                            style={{ width: '100%', padding: '10px', marginBottom: '10px', border: '1px solid #ccc', borderRadius: '4px' }} />
                            {formik.errors.establishmentName && (
                                    <div className="error">{formik.errors.establishmentName}</div>
                                )}
                            </div>

                            <div style={{ marginBottom: '20px' }}>
                            <label htmlFor="hasComputer" style={{ display: 'block', marginBottom: '5px' }}>Você possui computador?</label>
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <button onClick={() => setHasComputer("sim")} type='button'
                                className={hasComputer==="sim"?'btnQuestionPCsim':'btnQuestionPC'}>Sim</button>
                                <button onClick={() => setHasComputer("nao")} type='button'
                                className={hasComputer==="nao"?'btnQuestionPCnao':'btnQuestionPC'}>Não</button>
                            </div>                            
                            </div>
                            <h2>Informações de Contato</h2>
                            <div style={{ marginBottom: '20px' }}>
                            <label htmlFor="personName" style={{ display: 'block', marginBottom: '5px' }}>Seu nome<span style={{color:'red'}}>*</span></label>
                            <input type="text" id="personName" placeholder="Seu nome" 
                            value={formik.values.personName}
                            onChange={formik.handleChange}
                            style={{ width: '100%', padding: '10px', marginBottom: '10px', border: '1px solid #ccc', borderRadius: '4px' }} />
                             {formik.errors.personName && (
                                    <div className="error">{formik.errors.personName}</div>
                                )}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                            <label htmlFor="phone" style={{ display: 'block', marginBottom: '5px' }}>Telefone<span style={{color:'red'}}>*</span></label>
                            <InputMask type="tel" id="phone" mask="(99) 99999-9999" maskChar={''} placeholder="(__) _ ____-____" 
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            style={{ width: '100%', padding: '10px', marginBottom: '10px', border: '1px solid #ccc', borderRadius: '4px' }} />
                            {formik.errors.phone && (
                                    <div className="error">{formik.errors.phone}</div>
                                )}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                            <label htmlFor="email" style={{ display: 'block', marginBottom: '5px' }}>Seu melhor E-mail<span style={{color:'red'}}>*</span></label>
                            <input type="email" id="email" placeholder="Digite aqui o seu E-mail" 
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            style={{ width: '100%', padding: '10px', marginBottom: '10px', border: '1px solid #ccc', borderRadius: '4px' }} />
                            {formik.errors.email && (
                                    <div className="error">{formik.errors.email}</div>
                                )}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label htmlFor="cpf_cnpj" style={{ display: 'block', marginBottom: '5px' }}>CPF ou CNPJ<span style={{color:'red'}}>*</span></label>
                                <InputMask //mask={maskCpf_Cnpj} 
                                mask={dynamicMask()} 
                                name='cpf_cnpj' 
                                onChange={(e) => {
                                    setCpfCnpjValue(e.currentTarget.value);
                                    formik.setFieldValue('cpf_cnpj',e.currentTarget.value);
                                }} 
                                value={formik.values.cpf_cnpj}
                                maskChar={''}
                                type="cpf_cnpj" id="cpf_cnpj" placeholder="Digite o CPF ou CNPJ"
                                style={{ width: '100%', padding: '10px', marginBottom: '10px', border: '1px solid #ccc', borderRadius: '4px' }} 
                                />
                                {formik.errors.cpf_cnpj && (
                                    <div className="error">{formik.errors.cpf_cnpj}</div>
                                )}
                            </div>
                            <button type="submit" style={{ padding: '10px 20px', border: 'none', borderRadius: '4px', backgroundColor: '#007bff', color: 'white' }}>Próximo</button>
                        </div>
                
                    </div>
                    
                </form>
            </div>





        </div>     
    );
}   

export default AddEmpresa;

