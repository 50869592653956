import React, { Children, useContext, useEffect, useState, createContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation
} from "react-router-dom";
import * as AiIcons from "react-icons/ai";

import useCheckLicense from './hooks/useCheckLicense'; // Importar o hook

import LoginPage from "./pages/Login";
import HomePage from "./pages/Home";
import Pdv from "./pages/Pdv";
import AddUser from "./pages/Users/adduser";
import Empresa from "./pages/Users/empresa";
import AddCategoria from "./pages/ListCategoria/addCategoria";
import AddAdicional from "./pages/ListAdicional/addAdicional";
import AddItemAdicional from "./pages/ListAdicional/addItemAdicional";
import EditItemAdicional from "./pages/ListAdicional/editItemAdicional";
import AddItem from "./pages/ListItem/addItem";
import EditItem from "./pages/ListItem/editItem";
import Revenda from "./pages/Users/revenda";
import Cliente from "./pages/Cliente";
import Vendedor from "./pages/Vendedor";
import Orcamento from "./pages/Orcamento";
import ListUsers from "./pages/ListUsers";
import ListEntregadores from "./pages/ListEntregadores";
import ListEmpresa from "./pages/ListEmpresa";
import ListCategoria from "./pages/ListCategoria";
import ListItem from "./pages/ListItem";
import ListAdicional from "./pages/ListAdicional";
import ListCliente from "./pages/ListCliente";
import ListVendedor from "./pages/ListVendedor";
import ListOrcamento from "./pages/ListOrcamento";
import RecuperarSenha from "./pages/RecuperarSenha";
import RedefinirSenha from "./pages/RedefinirSenha";
import Cardapio from "./pages/Cardapio";
import DetalheProduto from "./pages/Cardapio/detalheProduto";
import Carrinho from "./pages/Cardapio/carrinho";
//import Mapa from "./pages/Mapa";
import AddRaioEntrega from "./pages/Mapa/addRaioEntrega";
import EnderecoEstabelecimento from "./pages/Endereco/enderecoEstabelecimento";
import WhatsSync from "./pages/Whatsapp/sync";
import WhatsApp from "./pages/Whatsapp";
import Geral from "./pages/MinhaConta/geral";
import PaymentMethods from "./pages/MinhaConta/metodosPagamento";
import Planos from "./pages/MinhaConta/planos";
import LandPage from "./pages/LandPage";
import AddEmpresa from "./pages/AddEmpresaCliente";
import AddEntregador from "./pages/ListEntregadores/addEntregador";
import AddCredencial from "./pages/AddEmpresaCliente/credenciais";
import IntegracaoCardapio from "./pages/AddEmpresaCliente/integracao";
import Endereco from "./pages/AddEmpresaCliente/endereco";
import PlanosAdmin from "./pages/PlanosAdmin/addPlanos";
import ListPlanosAdmin from "./pages/PlanosAdmin/listPlansAdmin";
import FinalizarPedido from "./pages/Cardapio/finalizarPedido";
import AddEndereco from "./pages/Cardapio/addEndereco";
import ConfigPrinter from "./pages/Impressora";
import HorarioFuncionamento from "./pages/HorarioFuncionamento";
import MeusPedidosCfg from "./pages/MeusPedidosConfig"
import RoboCfg from "./pages/roboConfig"
import InfoLoja from "./pages/Cardapio/infoLoja";
import RelatorioGeral from "./pages/Relatorios/geral";
import Desempenho from "./pages/Relatorios/desempenho";

//import TesteSocketIO from "./pages/Whatsapp/socketIOcomponent"; // Teste Socket IO

import { AuthProvider, AuthContext } from "./contexts/auth";
import Caixa from "./pages/Caixa";
import CaixaHistory from "./pages/Caixa/CaixaHistory";
import CaixaById from "./pages/Caixa/CaixaById";
import ImportaCardapio from "./pages/ImportaCardapio";

// Criando o contexto Sidebar
export const SidebarContext = createContext({
  sidebar: true,
  setSidebar: () => { }, // Valor inicial como uma função vazia
});
export const SidebarProvider = ({ children }) => {
  const [sidebar, setSidebar] = useState(true); // Use useState aqui

  return (
    <SidebarContext.Provider value={{ sidebar, setSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};

const AppRoutes = () => {
  /*const Private = ({ children }) => {
    const { authenticated, loading, hasLicense } = useContext(AuthContext);

    if (loading) {
      return (
        <div className="loading">
          <AiIcons.AiOutlineLoading3Quarters
            style={{ fontSize: "100px", color: "rgb(180,180,180)" }}
          />
        </div>
      );
    }

    if (!authenticated) {
      return <Navigate to="/login" />;
    }

    return children;
  };*/


  const PrivateRoute = ({ children }) => {

    useCheckLicense(); 
    const { authenticated, 
      loading, 
      hasLicense, redirectToPlanos
    } = useContext(AuthContext);
    //const [redirectToPlanos, setRedirectToPlanos] = useState(false);

    if (loading) {
      return (
        <div className="loading">
          <AiIcons.AiOutlineLoading3Quarters style={{ fontSize: '100px', color: 'rgb(180,180,180)' }} />
        </div>
      );
    }

    if (!authenticated) {
      return <Navigate to="/login" />;
    }

    //console.log("hasLicense>",hasLicense);
    if (!hasLicense && hasLicense!==null && window.location.pathname !== '/planos' && redirectToPlanos) {      
      return <Navigate to="/planos" />;
    }

    /*
    // Gerenciar o delay e redirecionamento
    if (!hasLicense && window.location.pathname !== '/planos') {
      // Atrasar a navegação
      setTimeout(() => {
        setRedirectToPlanos(true);
      }, 1000); // 1000ms = 1 segundo
    
      // Mostrar um estado de carregamento se necessário
      if (redirectToPlanos) {
        return <Navigate to="/planos" />;
      }
    }*/

    return children;
  };

  return (
    <Router>
      <AuthProvider>
        <SidebarProvider>
          <Routes>
            <Route exact path="/landpage" element={<LandPage />} />
            <Route exact path="/informacoes" element={<AddEmpresa />} />
            <Route exact path="/credenciais" element={<AddCredencial />} />
            <Route exact path="/integracao" element={<IntegracaoCardapio />} />
            <Route exact path="/endereco" element={<Endereco />} />
            <Route exact path="/login" element={<LoginPage />} />
            <Route
              exact
              path="/"
              element={
                <PrivateRoute>
                  <HomePage />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/pdv"
              element={
                <PrivateRoute>
                  <Pdv />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/cadastro-user"
              element={
                <PrivateRoute>
                  <AddUser />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/empresa"
              element={
                <PrivateRoute>
                  <Empresa />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/cadastro-categoria"
              element={
                <PrivateRoute>
                  <AddCategoria />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/cadastro-item"
              element={
                <PrivateRoute>
                  <AddItem />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/cadastro-adicional"
              element={
                <PrivateRoute>
                  <AddAdicional />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/cadastro-item-adicional"
              element={
                <PrivateRoute>
                  <AddItemAdicional />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/edit-item-adicional"
              element={
                <PrivateRoute>
                  <EditItemAdicional />
                </PrivateRoute>
              }
            />            
            <Route
              exact
              path="/edit-item"
              element={
                <PrivateRoute>
                  <EditItem />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/revenda"
              element={
                <PrivateRoute>
                  <Revenda />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/cadastro-cliente"
              element={
                <PrivateRoute>
                  <Cliente />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/cadastro-entregador"
              element={
                <PrivateRoute>
                  <AddEntregador />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/cadastro-vendedor"
              element={
                <PrivateRoute>
                  <Vendedor />
                </PrivateRoute>
              }
            />
            <Route
              path="/orcamento/:type"
              element={
                <PrivateRoute>
                  <Orcamento />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/list-entregadores"
              element={
                <PrivateRoute>
                  <ListEntregadores />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/list-users"
              element={
                <PrivateRoute>
                  <ListUsers />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/list-empresa"
              element={
                <PrivateRoute>
                  <ListEmpresa />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/list-categoria"
              element={
                <PrivateRoute>
                  <ListCategoria />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/list-item"
              element={
                <PrivateRoute>
                  <ListItem />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/list-adicional"
              element={
                <PrivateRoute>
                  <ListAdicional />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/list-cliente"
              element={
                <PrivateRoute>
                  <ListCliente />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/list-vendedor"
              element={
                <PrivateRoute>
                  <ListVendedor />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/list-orcamento"
              element={
                <PrivateRoute>
                  <ListOrcamento />
                </PrivateRoute>
              }
            />
            {/*<Route exact path="/mapa" element={<PrivateRoute><Mapa/></PrivateRoute>} />*/}
            <Route
              exact
              path="/endereco-estabelecimento"
              element={
                <PrivateRoute>
                  <EnderecoEstabelecimento />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/raio-entrega"
              element={
                <PrivateRoute>
                  <AddRaioEntrega />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/whatsapp-sync"
              element={
                <PrivateRoute>
                  <WhatsSync />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/whatsapp"
              element={
                <PrivateRoute>
                  <WhatsApp />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/impressora"
              element={
                <PrivateRoute>
                  <ConfigPrinter />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/horario-funcionamento"
              element={
                <PrivateRoute>
                  <HorarioFuncionamento />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/meus-pedidos-configuracao"
              element={
                <PrivateRoute>
                  <MeusPedidosCfg />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/robo-configuracao"
              element={
                <PrivateRoute>
                  <RoboCfg />
                </PrivateRoute>
              }
            />            
            <Route
              exact
              path="/planos"
              element={
                <PrivateRoute>
                  <Planos />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/planos-admin"
              element={
                <PrivateRoute>
                  <PlanosAdmin />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/list-plans-admin"
              element={
                <PrivateRoute>
                  <ListPlanosAdmin />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/relatorio-geral"
              element={
                <PrivateRoute>
                  <RelatorioGeral />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/desempenho"
              element={
                <PrivateRoute>
                  <Desempenho />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/caixa"
              element={
                <PrivateRoute>
                  <Caixa />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/caixa/historico"
              element={
                <PrivateRoute>
                  <CaixaHistory />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/caixa/historico/:caixaId"
              element={
                <PrivateRoute>
                  <CaixaById />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/importacao-cardapio"
              element={
                <PrivateRoute>
                  <ImportaCardapio />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/cardapio/:nomeEmpresaForUrl/:idEmpresa"
              element={<Cardapio />}
            />
            <Route
              exact
              path="/:nomeEmpresaForUrl/:idEmpresa/detalhes/:objIdProduto"
              element={<DetalheProduto />}
            />
            <Route
              exact
              path="/:nomeEmpresaForUrl/:idEmpresa/carrinho"
              element={<Carrinho />}
            />
            <Route
              exact
              path="/:nomeEmpresaForUrl/:idEmpresa/cadastrar-endereco"
              element={<AddEndereco />}
            />
            <Route
              exact
              path="/:nomeEmpresaForUrl/:idEmpresa/finalizar-pedido"
              element={<FinalizarPedido />}
            />
            <Route
              exact
              path="/:nomeEmpresaForUrl/:idEmpresa/info-loja"
              element={<InfoLoja />}
            />
            <Route exact path="/recuperar-senha" element={<RecuperarSenha />} />
            <Route
              exact
              path="/redefinir-senha"
              element={<RedefinirSenha />}
            />{" "}
            {/* Após configurar o serviço de e-mail definir rota Privada */}
          </Routes>
        </SidebarProvider>
      </AuthProvider>
    </Router>
  );
};

export default AppRoutes;
