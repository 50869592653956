import React, { useState, useContext, useEffect } from "react";
import './style.css'
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import { SidebarContext } from "../../AppRoutes";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import moment from 'moment'

import { AuthContext } from "../../contexts/auth";

import { getUsers, getUser, getEmpresa, getVinculoEmpresa, deleteUser } from "../../services/api";

import PermissionGate from "../../services/PermissionGate";

import { useNavigate } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu";
import ModalEditUser from "../../components/ModalEditUser";
import ConfirmDialog from "../../components/ConfirmDialog";

import { BarContext } from "../../components/LeftMenu";

import { useFormik } from "formik";
import * as Yup from "yup";

import * as FiIcons from 'react-icons/fi'
import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import * as FaIcons from 'react-icons/fa'
import { date } from "yup/lib/locale";

const Teste = styled.div`
    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;
`;



const ListUsers = ({list = []} ) =>{
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();

    const { sidebar, setSidebar } = useContext(SidebarContext);;
    //console.log("LISTUSERS",sidebar);

    const [order, setOrder] = useState(1)
    const [columnOrder, setColumnOrder] = useState('title')

    const [users, setUsers] = useState([]);
    //const [usersNomeEmpresa, setUsersNomeEmpresa] = useState([]);
    const [filter, setFilter] = useState('');
    
    const [_idUserEdit, set_idUserEdit] = useState('');
    const [usernameEdit, setUsernameEdit] = useState('');
    const [emailEdit, setEmailEdit] = useState('');
    const [roleEdit, setRoleEdit] = useState('');

    const [result, setResult] = useState(false);

    const [showEditUser, setEditUser] = useState(true);
    
    const [loading, setLoading] = useState(true);

    const [refresh, setRefresh] = useState(false);

    const INITIAL_DATA = {
        value: "",
        label: 'Selecione uma empresa',
    };
            
    const [selectData, setselectData] = useState(INITIAL_DATA);

    useEffect(() => {
        (async () => {
       
            const response = await getUsers(user._id);
            //console.log("TESTEEE:::",user._id)
            //const teste = [response.data]
            setUsers(response.data.user);
            //setUsersNomeEmpresa(response.data.empresas_vinculo);
            console.log(response.data)
            setLoading(false);
            setRefresh(false);
            
        })();
    },[refresh]);
  
    const handleEdit = async idToEdit => {
        setEditUser(!showEditUser);
        const response = await getUser(idToEdit);
        //console.log("Infos do Edit:",response.data.user)
        //console.log(idToEdit,"----");
        if(showEditUser){
            set_idUserEdit(idToEdit)            
            setUsernameEdit(response.data.user.name)
            setEmailEdit(response.data.user.email)            
            setRoleEdit(response.data.user.role)

            if (response.data.user.vinculo_empresa){
                //console.log("TEM EMPRESA VINCULADA!")
                const responseVinculo = await getVinculoEmpresa(idToEdit);            
                if(responseVinculo.data.vinculo){ 
       
                    setselectData({
                        value:responseVinculo.data.vinculo.id_empresa,
                        label:responseVinculo.data.vinculo.id_empresa + " - " + responseVinculo.data.vinculo.cnpj + " - " + responseVinculo.data.vinculo.name
                    })
                }
                if(!responseVinculo.data.vinculo){
                    //console.log("MSG:", responseVinculo.data.msg)
                }
                
            }
            else {
                //console.log("NÃO TEM EMPRESA VINCULADA!")
                setselectData({
                    value:"",
                    label:"",
                })
            }

        }
    }

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [shouldDelete, setShouldDelete] = useState(false);
    const [idToDelete, setIdToDelete] = useState('');
    const handleDelete = (idRecebido) => {
        // Abre Componente de Confirmação do delete
        setConfirmOpen(true)

        setIdToDelete(idRecebido)
        if(idRecebido){
            //console.log("ID RECEBIDO:",idRecebido)
            setShouldDelete(true);
        }
    }
  
    const deleteReg = async () => {
        //console.log("O ID CLICADO FOI :::",idToDelete);
        //console.log("ID do Usuário que irá efetuar a exclusão", user._id);
        
          
        //const response = await deleteUser(idToDelete, user._id);
        if(shouldDelete){
            deleteUser(idToDelete, user._id).then( cast => {
                //console.log("RESULT DELETE:",cast);
                if(cast.status===201){
                    toast(cast.data.msg,{ autoClose: 5000 , type: "success"});
                    setRefresh(true);
                }else{
                    toast(cast.data.msg,{ autoClose: 5000 , type: "error"});
                }
            }).catch( err => {
                //console.log("ERROR:",err);
                toast(err.response.data.msg,{ autoClose: 5000 , type: "error"});
            });
        }

    }

    const handleOrder = fieldName => {
        setOrder(-order)
        setColumnOrder(fieldName)
        //console.log("order:",order);
        //console.log("fieldName",fieldName)
    }

    var arrayUsers = [];
    arrayUsers = users.sort( (a, b) => {
        return a[columnOrder] < b[columnOrder] ? -order : order;
    })

    const handleRefresh = () => {
        //window.location.reload(false);
        setLoading(true);
        setRefresh(true);
    }

    const handleCadastro = () => {
        //setSidebar(!sidebarTeste);
        navigate("/cadastro-user");
        
    }

    
    if (filter){
        const exp = eval(`/${filter.replace(/[^\d\w]+/,'.*')}/i`)

        list = users.filter( item => exp.test( item.name ) )
        //console.log(list.length)
        if(list.length==0){
            list = users.filter( item => exp.test( item.email ) )
            ////console.log(list);
        }
        if(list.length==0){
            list = users.filter( item =>          
                    exp.test( item.userEmpresa[0].name )            
            )
            ////console.log(list);
        }
    }

    const handleFilter = e => {
        setFilter(e.target.value);
        setResult(!result);
    }

    const focusSearch = () => {
        document.getElementById("search").focus();
    }

    if(loading){
        return (
         
        <>
        <div className="loading"><AiIcons.AiOutlineLoading3Quarters style={{fontSize:"100px", color:"rgb(180,180,180)"}}/></div>
        <PermissionGate permissions={['viewUser']}>
      
            <LeftMenu setSidebar={setSidebar} sidebar={sidebar}></LeftMenu>

            <Teste sidebar={sidebar}>

                <div className="m-5">
                    <div className="list-header">
                        <div className="title">
                            <h1>Usuários</h1>
                        </div>
                    </div>

                    <div style={{display:"flex", justifyContent:"space-between"/*, height:"80px"*/}}>

                        <div className="input-box-list" style={{width:"30%"}}>                
                            <input className="input-field" placeholder="Pesquisar" onChange={handleFilter}/>
                            <i className="icon"><FiIcons.FiSearch/></i>
                        </div>

                        <div className="div-buttons">
                            <div className="continue-button">                           
                                <button onClick={handleCadastro}>
                                    <AiIcons.AiOutlinePlusCircle style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Adicionar</a> 
                                </button>                            
                            </div>

                            <div className="refresh-button">                                                       
                                <button className="refresh-button" onClick={handleRefresh}>
                                    <SlIcons.SlRefresh style={{color:"#4281FF",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a >Atualizar</a> 
                                </button>
                            </div>
                        </div>

                    </div>

                    <table className="table">
                        <thead>
                            <tr style={{borderBottom:"2px solid #4281FF"}}>
                                <th scope="col">Código</th>
                                <th scope="col">Nome</th>
                                <th scope="col">E-mail de Acesso</th>
                                <th scope="col">Papel do Usuário</th>
                                <th scope="col">Data Inclusão</th>
                                <th scope="col">Empresa Vinculada</th>
                                <th scope="col">Nome da Empresa</th>
                                <th scope="col" style={{paddingLeft:"35px"}}>...</th>
                            </tr>
                        </thead>

                        <tbody className={result?"result":"result search"}>
                                                
                        </tbody>
                    </table>    
                </div>
            </Teste>

        </PermissionGate>       
        </>


        );
    }

    return(

        <>
        <PermissionGate permissions={['viewUser']}>
  

            <LeftMenu setSidebar={setSidebar} sidebar={sidebar}></LeftMenu>

            
            <ConfirmDialog
                title="Deletar Usuário?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={deleteReg}
            >
                Tem certeza que deseja deletar o usuário?
            </ConfirmDialog>

            <ModalEditUser setEditUser={setEditUser} showEditUser={showEditUser} 
            setRefresh={setRefresh}
            selectData={selectData}
            setselectData={setselectData}
            _idUserEdit={_idUserEdit}
            usernameEdit={usernameEdit} 
            emailEdit={emailEdit} 
            roleEdit={roleEdit}    
            editPerfil={false}
            />

            <Teste sidebar={sidebar}>

                <div className="m-5">
                    <div className="list-header">
                        <div className="title">
                            <h1>Usuários</h1>
                        </div>
                    </div>

                    <div style={{display:"flex", justifyContent:"space-between"/*, height:"80px"*/}}>

                        <div className="input-box-list" style={{width:"30%"}}>                
                            <input className="input-field" id="search" placeholder="Pesquisar" onChange={handleFilter}/>
                            <i className="icon"><FiIcons.FiSearch/></i>
                        </div>

                        <div className="div-buttons">
                            <div className="continue-button">                           
                                <button onClick={handleCadastro}>
                                    <AiIcons.AiOutlinePlusCircle style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Adicionar</a> 
                                </button>                            
                            </div>

                            <div className="refresh-button">                                                       
                                <button className="refresh-button" onClick={handleRefresh}>
                                    <SlIcons.SlRefresh style={{color:"#4281FF",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a >Atualizar</a> 
                                </button>
                            </div>
                        </div>

    

                    </div>
                    


                    <table className="table">
                        <thead>
                            <tr /*style={{borderBottom:"2px solid #4281FF"}}*/>
                                <th scope="col" onClick={ e => handleOrder('id_user')} style={{cursor:"pointer"}}>Código<FaIcons.FaSort/></th>
                                <th scope="col" onClick={ e => handleOrder('name')} style={{cursor:"pointer"}}>Nome<FaIcons.FaSort/></th>
                                <th scope="col" onClick={ e => handleOrder('email')} style={{cursor:"pointer"}}>E-mail de Acesso<FaIcons.FaSort/></th>
                                <th scope="col" onClick={ e => handleOrder('role')} style={{cursor:"pointer"}}>Papel do Usuário<FaIcons.FaSort/></th>
                                <th scope="col" onClick={ e => handleOrder('createdAt')} style={{cursor:"pointer"}}>Data Inclusão<FaIcons.FaSort/></th>
                                <th scope="col" onClick={ e => handleOrder('vinculo_empresa')} style={{cursor:"pointer"}}>Empresa Vinculada<FaIcons.FaSort/></th>                                
                                <th scope="col" onClick={focusSearch} style={{cursor:"pointer"}}>Nome da Empresa<FiIcons.FiSearch style={{marginLeft:"5px"}}/></th>
                                <th scope="col" style={{paddingLeft:"15px"}}>Ações</th>
                            </tr>
                        </thead>

                        <tbody className={result?"result":"result search"}>
                            
                        {
                        filter ? 
                            list.map((user) => (
                                
                                <tr key={user._id}>
                                    <td>{user.id_user}</td>
                                    <td>{user.name}</td>
                                    <td>{user.email}</td>
                                    <td>{user.role}</td>
                                    {/* Só mostra data se existir */}
                                    {user.createdAt ?
                                        <td>{moment(user.createdAt).format("DD/MM/YYYY")}</td>
                                        :
                                        <td></td>
                                    }
                                    <td>{user.vinculo_empresa}</td>
                                    <td>{user.userEmpresa.length>0 ? user.userEmpresa[0].name : ''}</td>
                                    
                                    <td>
                                        <a className="btn btn-sm btn-primary" onClick={e => handleEdit(user._id)}><FiIcons.FiEdit2 style={{color:"white"}}/></a>
                                        <a className="btn btn-sm btn-danger" style={{marginLeft:"5px"}} onClick={e => handleDelete(user._id)}><FiIcons.FiTrash2 style={{color:"white"}}/></a>
                                    </td>
                                </tr>
                            )) :
                            arrayUsers.map(({_id, id_user, name, email, role, createdAt, vinculo_empresa, userEmpresa}) => (
                               
                                <tr key={_id}>
                                    <td>{id_user}</td>
                                    <td>{name}</td>
                                    <td>{email}</td>
                                    <td>{role}</td>
                                    {/* Só mostra data se existir */}
                                    {createdAt ?
                                        <td>{moment(createdAt).format('DD/MM/YYYY')}</td>
                                        :
                                        <td></td>
                                    }
                                    <td>{vinculo_empresa}</td>                                   
                                    <td>{userEmpresa.length>0 ? userEmpresa[0].name : ''}</td>
                                   
                                    <td>
                                        <span className="btn btn-sm btn-primary" onClick={e => handleEdit(_id)}><FiIcons.FiEdit2 style={{color:"white"}}/></span>
                                        <span className="btn btn-sm btn-danger" style={{marginLeft:"5px"}} onClick={e => handleDelete(_id)}><FiIcons.FiTrash2 style={{color:"white"}}/></span>
                                    </td>
                                </tr>
                               
                            ))
                        }
                            
                        </tbody>


                    </table>

                            
                </div>
            </Teste>

        </PermissionGate>       
        </>
        

        );
    };
    
    export default ListUsers;