import React from "react";

import "./styles.css";
import CloseCaixaForm from "../../Forms/CloseCaixaForm";

const CloseCaixaModal = ({ onClose }) => {
  return (
    <div>
      <h1 className="modal-title">Fechamanto de caixa</h1>
      <div className="modal-form">
        <CloseCaixaForm onClose={onClose} />
      </div>
    </div>
  );
};

export default CloseCaixaModal;
