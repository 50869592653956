import React, {
  useState,
  createContext,
  useEffect,
  useMemo,
  useContext,
} from "react";

import useGetEmpresa from "../hooks/useGetEmpresa";
import { convertPrice } from "../utils/convertPrice";
import useGetCaixaById from "../hooks/useGetCaixaById";
import { AuthContext } from "./auth";

export const CaixaContext = createContext();

export const CaixaProvider = ({ children }) => {
  const [caixa, setCaixa] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const empresa = localStorage.getItem("empresa");
  const empresaParse = JSON.parse(empresa);
  const empresa_id = empresaParse.id_empresa;
  const empresaId = empresaParse._id;
  const vinculoEmpresa = empresaParse.cnpj;

  const { user } = useContext(AuthContext);

  const { data, refetch: refetchStatus } = useGetEmpresa(empresaId);

  const caixaId = data?.data?.empresa.caixa_aberto
    ? data?.data?.empresa.caixa_aberto_id
    : data?.data?.empresa.last_caixa_id;

  const { data: currentCaixa, refetch } = useGetCaixaById(user._id, caixaId);

  useEffect(() => {
    if (data && currentCaixa) {
      setIsLoading(true);
      let caixa_items = currentCaixa.data.empresa
        ? currentCaixa.data.empresa.lancamentos_caixa
        : [];

      setCaixa({
        ...caixa,
        empresaId: empresaId,
        empresa_id,
        vinculoEmpresa,
        caixa_items,
        status: data.data.empresa.caixa_aberto ? "open" : "closed",
        ...currentCaixa.data.empresa,
      });
    }
    setIsLoading(false);
  }, [data?.data, currentCaixa]);

  //"Dinheiro", "Cartão", "Pix"
  const pixValue = useMemo(() => {
    if (!caixa.caixa_items) return 0;
    return caixa.caixa_items.reduce((acc, item) => {
      if (item.tipo_lancamento === "Pix") {
        return acc + item.valor;
      }

      return acc;
    }, 0);
  });

  const cartaoValue = useMemo(() => {
    if (!caixa.caixa_items) return 0;
    return caixa.caixa_items.reduce((acc, item) => {
      if (item.tipo_lancamento === "Cartão") {
        return acc + item.valor;
      }

      return acc;
    }, 0);
  });

  const dinheiroValue = useMemo(() => {
    if (!caixa.caixa_items) return 0;
    return caixa.caixa_items.reduce((acc, item) => {
      if (item.tipo_lancamento === "Dinheiro") {
        return acc + item.valor;
      }

      return acc;
    }, 0);
  });

  const finalValue = useMemo(() => {
    if (!caixa.caixa_items) return 0;
    return caixa.caixa_items.reduce(
      (acc, item) => acc + convertPrice(item.value),
      0
    );
  }, [caixa]);

  const suprimentosValue = useMemo(() => {
    if (!caixa.caixa_items) return 0;
    return caixa.caixa_items.reduce((acc, item) => {
      if (item.descricao !== "Saldo Inicial" && item.valor >= 0) {
        return acc + item.valor;
      }

      return acc;
    }, 0);
  }, [caixa]);

  const sangriasValue = useMemo(() => {
    if (!caixa.caixa_items) return 0;
    return caixa.caixa_items.reduce((acc, item) => {
      if (item.descricao !== "Saldo Inicial" && item.valor <= 0) {
        return acc + item.valor;
      }

      return acc;
    }, 0);
  }, [caixa]);

  const caixaValue = useMemo(() => {
    if (!caixa.caixa_items) return caixa.saldo_inicial;
    return sangriasValue + suprimentosValue + caixa.saldo_inicial;
  }, [suprimentosValue, sangriasValue, caixa]);

  return (
    <CaixaContext.Provider
      value={{
        caixa,
        setCaixa,
        finalValue,
        isLoading,
        refetch,
        suprimentosValue,
        sangriasValue,
        caixaValue,
        refetchStatus,
        dinheiroValue,
        cartaoValue,
        pixValue,
      }}
    >
      {children}
    </CaixaContext.Provider>
  );
};
