import React, { useState, useContext, useEffect } from "react";
import AsyncSelect from 'react-select/async';
import './style.css'
import styled from 'styled-components';
import { SidebarContext } from "../../AppRoutes";
import { AuthContext } from "../../contexts/auth";
import InputMask from 'react-input-mask';
import { toast } from "react-toastify";

import { registerEntregador, getVinculoEmpresa } from "../../services/api";

import PermissionGate from "../../services/PermissionGate";

import { useNavigate } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu";

import { useFormik } from "formik";
import * as Yup from "yup";

import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'

const Teste = styled.div`

    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;
`;



const AddEntregador = () =>{
    const { user } = useContext(AuthContext);

    const userID = user._id;
    const [empresa, setEmpresa] = useState({});
    const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES

    //Validações
    const SignUpSchema = Yup.object().shape({
        phone: Yup.string().required("Obrigatório"),
        //placa: Yup.string().required("Obrigatório"),
        tipo: Yup.string().required("Obrigatório"),
        nome: Yup.string().required("Obrigatório"),
    });

    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        //enableReinitialize: true,
        initialValues: {
          phone: "",
          placa: "",
          tipo: "",
          nome: "",
        },
        onSubmit: (values) => {
          const telefone=values.phone;
          const placa=values.placa;
          const veiculo=values.tipo;
          const nome=values.nome;

          handleSubmitAPI(nome,veiculo,telefone,placa);
          
        },
    });    

    const navigate = useNavigate();
    
    const handleSubmitAPI = (nome,veiculo,telefone,placa) =>{
        //e.preventDefault();
        const createdBy = user._id;
        const id_empresa = empresa._id;
        console.log("submit user!",{createdBy, id_empresa, nome, telefone, veiculo, placa});
        registerEntregador(createdBy, id_empresa, nome, telefone, veiculo, placa).then(cast => {
            //console.log("Usuário criado com Sucesso!:::",cast)
            if (cast.status===201){
                toast(cast.data.msg, {autoClose: 5000, type: "success"})       
                navigate("/list-entregadores");
            }else{
                toast(cast.data.msg, {autoClose: 5000, type: "error"})
            }
        }).catch(err => {
            //console.log('Ocorreu algum erro no servidor!');
            //console.log(err.response.data.msg);
            toast(err.response.data.msg, {autoClose: 5000, type: "error"})
        });        
        
    }
    
    const handleBack = () => {
        //setSidebar(!sidebar)
        navigate("/list-entregadores");
        
    }

    useEffect(() => {
        
        const fetchEmpresa = async () => {
            try {
                const response = await getVinculoEmpresa(userID);
                if (response.data.vinculo) {
                    const empresa = response.data.vinculo;                
                    setEmpresa(empresa);
                }
            } catch (error) {
                console.error("Erro ao buscar dados:", error);
            }
        };

        fetchEmpresa();

    }, [userID]);

    return(

        <>
        <PermissionGate permissions={['viewUser']}>
        

            <LeftMenu setSidebar={setSidebar} sidebar={sidebar}/>

            <Teste sidebar={sidebar}>
                <div className="m-5">
                    {/* <div className="form"> */}
                        {/*<form onSubmit={handleSubmitButton}>*/}
                        <form onSubmit={formik.handleSubmit} style={{marginTop:"-30px"}}>
                        
                            <div className="form-header" style={{marginBottom:"0px"}}>
                                <div className="title">
                                    <h1>Cadastrar Entregador</h1>
                                </div>
                            </div>

                            <div style={{display:"flex", justifyContent:"right"/*, height:"80px"*/}}>


                                <div className="div-buttons">
                                    <div className="continue-button">                           
                                        <button type="submit">
                                            <AiIcons.AiOutlineSave style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Salvar</a> 
                                        </button>                            
                                    </div>

                                    <div className="back-button">                                                       
                                        <button onClick={handleBack}>
                                            <SlIcons.SlActionUndo style={{color:"#ff4c4c",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a >Voltar</a> 
                                        </button>
                                    </div>
                                </div>

                            </div>
                        
                            <div className="input-group">                                

                                <div className="input-box" style={{width:"68%"}}>
                                    <label>Nome<span style={{color:"red"}}>*</span></label>
                                    <input 
                                    placeholder="Digite o nome do Entregador"
                                    id="nome"
                                    type="nome"
                                    name="nome"
                                    onChange={formik.handleChange}
                                    value={formik.values.nome}
                                    />
                                    {formik.errors.nome && (
                                        <div className="error">{formik.errors.nome}</div>
                                    )}
                                </div>

                                <div className="input-box" style={{width:"30%"}}>
                                    <label>Veículo<span style={{color:"red"}}>*</span></label>
                                    {/*<!-- Custom select structure --> */}
                                    <div className="selectdiv">
                                    <label style={{width:"100%"}}>
                                        <select 
                                        id="tipo"
                                        type="tipo"
                                        name="tipo"
                                        onChange={formik.handleChange}
                                        value={formik.values.tipo}
                                        className={formik.values.tipo === ""?'invalid-option':'beleza'}
                                        style={{width:"100%"}}
                                        >
                                            <option value="" className="invalid-option">Selecione um Tipo</option>
                                            <option>Bicicleta</option>
                                            <option>Carro</option>
                                            <option>Motocicleta</option>
                                        </select>
                                        {formik.errors.tipo && (
                                        <div className="error" style={{fontWeight: "lighter"}}>{formik.errors.tipo}</div>
                                        )}
                                    </label>
                                    </div>
                                    {/*<!-- Custom select structure --> */}
                                </div>  

                                <div className="input-box" style={{width: "48%", transition:sidebar?"200ms":"100ms"}}>
                                    <label htmlFor="telefone">Telefone<span style={{color:"red"}}>*</span></label>
                                    <InputMask type="tel" id="phone" mask="(99) 99999-9999" maskChar={''} placeholder="(__) _ ____-____" 
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    style={{ width: '100%', padding: '10px', marginBottom: '10px', height:'45px', borderRadius: '10px' }} />
                                    {formik.errors.phone && (
                                            <div className="error">{formik.errors.phone}</div>
                                        )}
                                </div>

                                

                                <div className="input-box" style={{width:"48%"}}>
                                    <label htmlFor="placa">Placa</label>                            
                                    <InputMask 
                                    placeholder="Digita a placa do veículo"                                     
                                    id="placa"
                                    name="placa"
                                    type="placa"
                                    //value={placa}
                                    //onChange={e => setPassword(e.target.value)}
                                    onChange={formik.handleChange}
                                    value={formik.values.placa}
                                    />
                                    {formik.errors.placa && (
                                        <div className="error">{formik.errors.placa}</div>
                                    )}
                                </div>                                                                                                        


                            </div>

                            {/* <div className="continue-button">
                                <button type="submit"><a>Cadastrar</a> </button>
                            </div> */}
                        </form>
                    {/* </div> */}

                </div>
            </Teste>                       
   
        </PermissionGate>       
        </>
        

        );
    };
    
    export default AddEntregador;