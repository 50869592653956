import AppRoutes from "./AppRoutes";
import { ToastContainer } from "react-toastify";

function App() {

  return (

    <div translate="no" className='app'>
      <ToastContainer position="top-center"/>
      <AppRoutes/>
    </div>

  );
}

export default App;
