import { Form, Formik } from "formik";
import * as Yup from "yup";
import CurrencyInput from "../../../Form/CurrencyInput";
import { addLancamentoCaixa } from "../../../../services/api";
import { useContext, useState } from "react";
import { CaixaContext } from "../../../../contexts/caixaContext";
import { AuthContext } from "../../../../contexts/auth";
import { convertPrice } from "../../../../utils/convertPrice";
import Loading from "react-loading";

import { toast } from "react-toastify";

const SangriaForm = ({ onClose }) => {
  const validationSchema = Yup.object({
    description: Yup.string().required("Obrigatório informar uma descrição"),
    value: Yup.string().required("Obrigatório informar um valor inicial"),
  });

  const { caixa, refetch } = useContext(CaixaContext);
  const { user } = useContext(AuthContext);

  const [isSenting, setIsSenting] = useState(false);

  const initialValues = { value: "", description: "" };

  const handleSubmit = async (values) => {
    const { value, description } = values;

    try {
      setIsSenting(true);
      await addLancamentoCaixa(
        caixa.id_empresa,
        caixa._id,
        description,
        "Dinheiro",
        -convertPrice(value),
        user._id
      );
      onClose();
      refetch();
    } catch (e) {
      if (e.response?.data?.msg) {
        toast(e.response.data.msg, { type: "error" });
      } else {
        toast("Erro ao adicionar suprimento", { type: "error" });
      }
    } finally {
      setIsSenting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, handleChange, values }) => (
        <Form>
          <div className="input-box">
            <label>
              Valor<span style={{ color: "red" }}>*</span>
            </label>
            <CurrencyInput
              label="value"
              name="value"
              type="text"
              placeholder="R$ 0,00"
            />
          </div>
          <div className="input-box">
            <label>
              Descrição<span style={{ color: "red" }}>*</span>
            </label>
            <input
              placeholder="Digite a descrição da sangria"
              id="description"
              type="description"
              name="description"
              value={values.description}
              onChange={handleChange}
            />
            {errors.description ? (
              <div className="error">{errors.description}</div>
            ) : null}
          </div>
          <div className="modal-buttons">
            <button
              className="button-default cancel-button"
              type="button"
              onClick={onClose}
              disabled={isSenting}
            >
              Cancelar
            </button>
            <button
              className="button-default confirm-button"
              type="submit"
              disabled={isSenting}
            >
              {isSenting ? (
                <>
                  <Loading type="spin" color="white" height={20} width={20} />
                  ...Enviando
                </>
              ) : (
                "Confirmar"
              )}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SangriaForm;
