import { useQuery } from "react-query";
import { getCaixaById } from "../../services/api";

const useGetCaixaById = (userId, caixaId) => {
  return useQuery(["caixa", caixaId], () => getCaixaById(userId, caixaId), {
    enabled: !!caixaId,
  }); // Save Itens in cache
};

export default useGetCaixaById;
