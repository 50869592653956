import styled from "styled-components";
import React, {useRef} from 'react'
import './style.css'
import PermissionGate from "../../services/PermissionGate";
import moment from 'moment'
import CryptoJS from 'crypto-js';
import { useFormik } from "formik";
import * as Yup from "yup";
import Loading from "react-loading";
import * as AiIcons from 'react-icons/ai';
import { calcularDistancia, deleteEnderecoCliente, getRevendas, getEmpresas, updateTempoEntregaEmpresa, ImprimirPedido, updateUser } from "../../services/api";
import AsyncSelect from "react-select/async";
import InputMoney from "../ListItem/InputMoney";
import { AuthContext } from "../../contexts/auth";
import { useEffect, useContext, useState } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { FaMotorcycle, FaStore } from "react-icons/fa";
import { BiComment } from "react-icons/bi";
import { useNavigate } from "react-router-dom";	
import { GrLocation } from "react-icons/gr";
import { RiCloseCircleLine } from "react-icons/ri";
import { updateClienteAddress } from "../../services/api";
import { v4 as uuidv4 } from 'uuid';

const DivPedidoContainer = styled.div`
  z-index:15;
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ showModalPagamentos }) => (showModalPagamentos ? 'none' :'flex' )};
  float: left;
  min-width: 100%;
  min-height: 100%;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 5px 0;
  list-style: none;
  background-color: rgba(0,0,0,0.5);
  background-clip: padding-box;
  align-items:center;
  justify-content:center;
  opacity: ${({ showModalPagamentos }) => (showModalPagamentos ? '0' :'1' )};
  transition: opacity .3s ease-in-out;

  // Add keyframes for the fade-in effect
  animation: fadeIn .3s ease-in-out;
  animation-fill-mode: forwards;

  // Define the keyframes for the animation
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const DivPedido = styled.div`
    z-index:20;
    position: absolute;
    top: 10%;
    left: 20%;
    float: left;
    min-width: 60%;
    min-height: 15%;
    max-width: 60%;
    padding: 5px 0;
    list-style: none;
    --background-color: white;
    background-color:rgb(247,247,247)!important;
    background-clip: padding-box;
    border-radius: 15px;
    padding:20px;

`;

const ModalPagamentos = ({
    showModalPagamentos,
    setShowModalPagamentos,
    formaPagamento, 
    setFormaPagamento,
    selectedPayment,
    setSelectedPayment,
    selectedCliente,
    setSelectedCliente,
    selectedAddress,
    setSelectedAddress,
    empresa,
    subTotal,
    valorTotal,
    savedFormaEntrega
}) => {    
    const idEmpresa = empresa?.id_empresa;
    const [valorTroco, setValorTroco] = useState(0);
    const [lancamentoDivididoToogle, setLancamentoDivididoToogle] = useState(null); // ["cash", "card"]
    const [valueToPay, setValueToPay] = useState(0);
    const [valorTrocoDividido, setValorTrocoDividido] = useState(0);
    const [pagamentosDivididos, setPagamentosDivididos] = useState([]);
   
    const closeModalPagamentos = () => {
        setShowModalPagamentos(!showModalPagamentos);
    }

    const handleChangePaymentType = (paymentType) => {
        setFormaPagamento(paymentType);
        setSelectedPayment(null);
        if (paymentType !== 'Dividido') {
            setPagamentosDivididos([]);
        }        
    }

    const handleToogleLancamentoDividido = (type) => {
        setLancamentoDivididoToogle(type);
        setValueToPay(parseFloat(calculaValorRestante().replace(',','.')));
    }

    const handleAddDividedPayment = () => {
        if (valueToPay <= 0) {
            toast('O valor a ser pago não pode ser igual a zero.', { autoClose: 3000, type: 'warning' });
            return;
        }

        const totalDivided = pagamentosDivididos.reduce((sum, payment) => sum + payment.value, 0);
        const valorRestante = valorTotal - totalDivided;

        if (totalDivided + valueToPay > valorTotal) {
            toast('O valor dos lançamentos não pode ultrapassar o valor total.', { autoClose: 3000, type: 'warning' });
            return;
        }

        if (lancamentoDivididoToogle === 'cash' && valorTrocoDividido <= valueToPay && valorTrocoDividido !== 0) {
            toast('O valor do troco deve ser maior que o valor a ser pago.', { autoClose: 3000, type: 'warning' });
            return;
        }
        const type = lancamentoDivididoToogle === 'cash' ? 'Dinheiro' : 'Cartão';
        const newPayment = {
            id: uuidv4(),
            type: type,
            value: valueToPay,
            troco: lancamentoDivididoToogle === 'cash' && valorTrocoDividido > 0 ? valorTrocoDividido : "Não informado",
        };

        console.log("valorTrocoDividido>>",valorTrocoDividido)

        setPagamentosDivididos([...pagamentosDivididos, newPayment]);
        setSelectedPayment({
            paymentType: 'Dividido',
            pagamentosDivididos: [...pagamentosDivididos, newPayment],
        });

        setLancamentoDivididoToogle(null);
        setValueToPay(0);
        setValorTrocoDividido(0);
    }

    const handleRemoveDividedPayment = (index) => {
        const updatedPayments = pagamentosDivididos.filter((_, i) => i !== index);
        setPagamentosDivididos(updatedPayments);
        setSelectedPayment(updatedPayments.length > 0 ? {
            paymentType: 'Dividido',
            pagamentosDivididos: updatedPayments,
        } : null);
    }

    const handleEditDividedPayment = (index) => {
        const paymentToEdit = pagamentosDivididos[index];
        console.log("paymentToEdit>>",paymentToEdit);

        const typeToogle = paymentToEdit.type === 'Dinheiro' ? 'cash' : 'card';
        setLancamentoDivididoToogle(typeToogle);
        setValueToPay(paymentToEdit.value);
        setValorTrocoDividido(paymentToEdit.troco === "Não informado" ? 0 : paymentToEdit.troco);
        handleRemoveDividedPayment(index);
    }

    const handleRemovePayment = () => {
        setSelectedPayment(null);
        setValorTroco(0);
    }

    const calculaValorRestante = () => {
        const totalDivided = pagamentosDivididos.reduce((sum, payment) => sum + payment.value, 0);
        const totalPaid = formaPagamento !== 'Dividido' && selectedPayment ? selectedPayment.valorPago : totalDivided;
        return Math.max(0, valorTotal - totalPaid).toFixed(2).toString().replace('.', ',');
    }


    const calculaValorTroco = () => {
        const totalDividedTroco = pagamentosDivididos
            .filter(payment => payment.type === 'Dinheiro' && payment.troco !== "Não informado")
            .reduce((sum, payment) => payment.troco - payment.value, 0);
            console.log("totalDividedTroco>>",totalDividedTroco);
            console.log("selectedPayment.valorTroco>>", selectedPayment.valorTroco - valorTotal);
        const totalSingleTroco = selectedPayment && selectedPayment.paymentType === 'Dinheiro' ? selectedPayment.valorTroco - valorTotal : 0;
        console.log("TESTEEEE>>",Math.max(0, totalDividedTroco + totalSingleTroco).toFixed(2).toString().replace('.', ','))
        return Math.max(0, totalDividedTroco + totalSingleTroco).toFixed(2).toString().replace('.', ',');
    }

    const calculaValorTrocoDividido = (pagamento) => {
        console.log("pagamento>>",pagamento)
        const totalDividedTroco = pagamentosDivididos
            .filter(payment => payment.type === 'Dinheiro' && payment.troco !== "Não informado" && payment.id === pagamento.id)
            .reduce((sum, payment) => payment.troco - payment.value, 0);
        return Math.max(0, totalDividedTroco).toFixed(2).toString().replace('.', ',');
    }


    const saveModalPayment = () => {
        /*if (formaPagamento === "Dinheiro" && valorTroco !== 0) {
            toast('Informe o valor do troco!', { autoClose: 3000, type: 'warning' });
            return;
        }*/
        if (formaPagamento === "Dinheiro" && valorTroco <= valorTotal && valorTroco !== 0) {
            toast('O valor do troco deve ser maior que o total!', { autoClose: 3000, type: 'warning' });
            return;
        }
        if (formaPagamento === "Dinheiro" && valorTroco >= valorTotal || formaPagamento === "Dinheiro" && valorTroco === 0) {
            toast('Pagamento efetuado com sucesso!', { autoClose: 1000, type: 'success' });
            setSelectedPayment({
                paymentType: formaPagamento,
                valorTroco: valorTroco,
                valorPago: valorTotal,
            });
            closeModalPagamentos();
        }
        if (formaPagamento === "Cartao" || formaPagamento === "Pix") {
            toast('Pagamento efetuado com sucesso!', { autoClose: 1000, type: 'success' });
            setSelectedPayment({
                paymentType: formaPagamento,
                valorTroco: 0,
                valorPago: valorTotal,
            });
            closeModalPagamentos();
        }
        if (formaPagamento === "Dividido") {
            toast('Pagamento efetuado com sucesso!', { autoClose: 1000, type: 'success' });
            setSelectedPayment({
                paymentType: formaPagamento,
                pagamentosDivididos: pagamentosDivididos,
            });
            closeModalPagamentos();
        }
    }


    const [isFocused, setIsFocused] = useState(false);
    const handleFocus = () => {
        setIsFocused(true);
    };
    
    const handleBlur = () => {
        setIsFocused(false);
    };

    const [isFocused2, setIsFocused2] = useState(false);
    const handleFocus2 = () => {
        setIsFocused2(true);
    };
    
    const handleBlur2 = () => {
        setIsFocused2(false);
    };

    const onChange = (event, nameObject, isNumber) => {
        setValorTroco(isNumber ? Number(event.target.value) : event.target.value);
        //formik.setFieldValue("preco", isNumber ? Number(event.target.value) : event.target.value );
    };

    const onChangeValorTrocoDividido = (event, nameObject, isNumber) => {
        setValorTrocoDividido(isNumber ? Number(event.target.value) : event.target.value);
        //formik.setFieldValue("preco", isNumber ? Number(event.target.value) : event.target.value );
    };

    const onChangeValueToPay = (event, nameObject, isNumber) => {
        setValueToPay(isNumber ? Number(event.target.value) : event.target.value);
        //formik.setFieldValue("preco", isNumber ? Number(event.target.value) : event.target.value );
    };    

    return (
        <DivPedidoContainer showModalPagamentos={showModalPagamentos}>
            <DivPedido>     
                <div className="divModalEntregaPDV" style={{minHeight:130}}> 
                    <div className="divTitleAndContentModalEntregaPDV">         
                        <div className="closeModalPedido" onClick={() => closeModalPagamentos()}>
                            <div
                                iconname="x"
                                iconcolor="#2B2B2B"
                                iconsize={18}
                                className="iconCancel"
                                style={{ height: 18, display: 'flex' }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={18}
                                    height={18}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#2B2B2B"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-x"
                                >
                                    <line x1={18} y1={6} x2={6} y2={18} />
                                    <line x1={6} y1={6} x2={18} y2={18} />
                                </svg>
                            </div>
                        </div>

                        <h4>Lançar pagamentos</h4>

                        <div className="divSelectFormaEntregaModal">
                            <div className="divFormaEntrega_1" onClick={() => handleChangePaymentType('Dinheiro')} 
                                style={{
                                    background: formaPagamento === "Dinheiro" ? '#1095F3' : '',
                                    color: formaPagamento === "Dinheiro" ? 'white' : ''
                                }}>
                                <span>Dinheiro</span>
                            </div>
                            <div className="divFormaPagamento_meio" onClick={() => handleChangePaymentType('Cartao')} 
                                style={{
                                    background: formaPagamento === "Cartao" ? '#1095F3' : '',
                                    color: formaPagamento === "Cartao" ? 'white' : ''
                                }}>
                                <span>Cartão</span>
                            </div>
                            <div className="divFormaPagamento_meio" onClick={() => handleChangePaymentType('Pix')} 
                                style={{
                                    background: formaPagamento === "Pix" ? '#1095F3' : '',
                                    color: formaPagamento === "Pix" ? 'white' : ''
                                }}>
                                <span>PIX</span>
                            </div>
                            <div className="divFormaEntrega_2" onClick={() => handleChangePaymentType('Dividido')} 
                                style={{
                                    background: formaPagamento === "Dividido" ? '#1095F3' : '',
                                    color: formaPagamento === "Dividido" ? 'white' : ''
                                }}>
                                <span>Dividido</span>
                            </div>
                        </div>

                        <div className="divModalFormaPagamentoDinheiro">
                            {calculaValorRestante() === "0,00" && (
                                <div className="contentLeftModalPagamentos">
                                    <div className="divPagamentoSelecionadoContent">
                                        <span>Todos os pagamentos foram lançados, clique em Finalizar Pagamento</span>                                           
                                    </div>
                                </div>
                            )}
                            {!selectedPayment && calculaValorRestante() !== "0,00" &&
                                formaPagamento === "Dinheiro" ?
                                    <div className="contentLeftModalPagamentos">
                                        <label style={{ fontWeight: 500 }}>Troco para:</label>
                                        <div style={{ padding: "0px", width: '100%' }}>
                                            <InputMoney
                                                onChange={(event) => setValorTroco(Number(event.target.value))}
                                                value={valorTroco}
                                                // margem e estilo
                                                className="inputPersonalizedTroco"
                                                placeholder="Troco"
                                            />  
                                        </div>
                                    </div>
                                    :
                                    formaPagamento === "Dividido" && calculaValorRestante()!=="0,00" ?
                                        <div className="contentLeftModalPagamentos">   
                                            <div className="divFormaPagamentoDividido">
                                                <button onClick={() => handleToogleLancamentoDividido("cash")} 
                                                    style={{
                                                        background: lancamentoDivididoToogle === "cash" && 'rgb(16, 149, 243)',
                                                        color: lancamentoDivididoToogle === "cash" && 'white'
                                                    }}>
                                                    <span>Dinheiro</span>
                                                </button>
                                                <button onClick={() => handleToogleLancamentoDividido("card")}
                                                    style={{
                                                        background: lancamentoDivididoToogle === "card" && 'rgb(16, 149, 243)',
                                                        color: lancamentoDivididoToogle === "card" && 'white'
                                                    }}>
                                                    <span>Cartão</span>
                                                </button>
                                            </div>   

                                            {lancamentoDivididoToogle === "cash" &&
                                                <>
                                                    <label style={{ fontWeight: 500 }}>Valor a ser pago</label>
                                                    <div style={{ padding: "0px", width: '100%' }}>
                                                        <InputMoney
                                                            onChange={(event) => setValueToPay(Number(event.target.value))}
                                                            value={valueToPay}
                                                            // margem e estilo
                                                            className="inputPersonalizedTroco"
                                                            placeholder="Troco"
                                                        />  
                                                    </div>  
                                                    <label style={{ fontWeight: 500 }}>Troco para:</label>
                                                    <div style={{ padding: "0px", width: '100%' }}>
                                                        <InputMoney
                                                            onChange={(event) => setValorTrocoDividido(Number(event.target.value))}
                                                            value={valorTrocoDividido}
                                                            // margem e estilo
                                                            className="inputPersonalizedTroco"
                                                            placeholder="Troco"
                                                        />  
                                                    </div>   
                                                </>   
                                            }   
                                            {lancamentoDivididoToogle === "card" &&
                                                <>  
                                                    <label style={{ fontWeight: 500 }}>Valor a ser pago</label>
                                                    <div style={{ padding: "0px", width: '100%' }}>
                                                        <InputMoney
                                                            onChange={(event) => setValueToPay(Number(event.target.value))}
                                                            value={valueToPay}
                                                            // margem e estilo
                                                            className="inputPersonalizedTroco"
                                                            placeholder="Troco"
                                                        />  
                                                    </div>   
                                                </>
                                            }   
                                            <div className="divBtnModalPagLancarDividido">
                                                <button onClick={() => handleAddDividedPayment()}>Lançar pagamento</button>  
                                            </div>
                                        </div>
                                    : 
                                    !selectedPayment ? (
                                        <div className="contentLeftModalPagamentos">                                            
                                        </div>
                                        )
                                        : null
                            }                                                                                                  
                            <div className="summary-container-modalPagamentos" style={{minWidth:/*formaPagamento==="Dividido"&&*/'50%'}}>
                                <div className="summary-item-modalPagamentos">
                                    <span className="label-modalPagamentos">Subtotal</span>
                                    <span className="value-modalPagamentos">R$ {subTotal.toFixed(2).toString().replace('.', ',')}</span>
                                </div>
                                {savedFormaEntrega?.formaEntrega === "Entrega" &&
                                    <div className="summary-item-modalPagamentos">
                                        <span className="label-modalPagamentos">Entrega</span>
                                        <span className="value-modalPagamentos">R$ {savedFormaEntrega?.valorEntrega.toFixed(2).toString().replace('.', ',')}</span>
                                    </div>
                                }
                                <div className="summary-item-modalPagamentos total-modalPagamentos">
                                    <span className="label-modalPagamentos">Total</span>
                                    <span className="value-modalPagamentos">R$ {valorTotal.toFixed(2).toString().replace('.', ',')}</span>
                                </div>

                                {pagamentosDivididos.length === 0 && !selectedPayment && <div className="message-modalPagamentos">Faça os lançamentos de pagamento ao lado</div>}
                                {pagamentosDivididos.length > 0 && 
                                    <div className="pagamentosLancados-modalPagamentos">                                       
                                        <span>Pagamentos</span>
                                        <span style={{ fontSize: 9 }}>Passe o mouse sobre o pagamento para editar ou excluir</span>                                       
                                        <div className="paiDividido" style={{ marginTop: 5 , width:'100%'}}>
                                            {pagamentosDivididos.map((pagamento, index) => (
                                                <div className="divBorderBottomPaymentDividido" key={index}>
                                                    <div className="lista-pedidos" style={{ /*height: pagamento.type === "Dinheiro" ? 20 : 40,*/ paddingLeft:12 }}> 
                                                        <div className="divFormaPaymentDivididoPai">                                               
                                                            <div className="divFormaPaymentDividido">
                                                                <div style={{ display: 'flex', alignItems: 'start'}}>
                                                                    <span style={{ marginLeft: -10 }}>{index + 1}º</span>
                                                                    <span style={{ marginLeft: 10 }}>{pagamento.type}</span>
                                                                </div>
                                                                <div>
                                                                    R$ {pagamento.value.toFixed(2).toString().replace('.', ',')}
                                                                </div>  
                                                            </div> 
                                                            {pagamento.type === "Dinheiro" &&
                                                                <div className="lista-pedidos" style={{ height: 20, paddingLeft:12, width:'100%', justifyContent:'space-between' }}>  
                                                                    <div style={{ display: 'flex', alignItems: 'start', justifyContent:'space-between', width:'75%' }}>                                                       
                                                                        <span>{calculaValorTrocoDividido(pagamento) ==="0,00"?"Troco":`Troco para R$ ${pagamento.troco.toFixed(2).toString().replace('.', ',')}`}</span>
                                                                    </div>
                                                                    <div style={{ minWidth: calculaValorTrocoDividido(pagamento)=== "0,00"? 100 : 52 }}>
                                                                        {calculaValorTrocoDividido(pagamento)=== "0,00" ? "Não informado" : `R$ ${calculaValorTrocoDividido(pagamento)}`}
                                                                    </div> 
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="divBtnsEditAndAddObsItem" style={{width:'100px'}}>
                                                            <div style={{ width: '50%', borderRight: '1px solid lightgray' }}>
                                                                <button className="btnEditModalPaymentDividido" onClick={() => handleEditDividedPayment(index)}>Editar</button>
                                                            </div>
                                                            <div style={{ width: '50%' }}>
                                                                <button className="btnRemoveModalPaymentDividido" onClick={() => handleRemoveDividedPayment(index)}>Excluir</button>
                                                            </div>
                                                        </div>                                     
                                                    </div>                                                    
                                                </div>
                                            ))}
                                            
                                        </div>
                                    </div>
                                }

                                {selectedPayment && !pagamentosDivididos.length && 
                                    <div className="pagamentosLancados-modalPagamentos">                                       
                                        <span>Pagamentos</span>
                                        <span style={{ fontSize: 9 }}>Passe o mouse sobre o pagamento para editar ou excluir</span>                                       
                                        <div className="pai" style={{ marginTop: 5, width:'100%' }}>
                                            <div className="lista-pedidos" style={{ height: 20, paddingLeft:12 }}>                                                
                                                <div style={{ display: 'flex', alignItems: 'start', minWidth: 200 }}>
                                                    <span style={{ marginLeft: -10 }}>1º</span>
                                                    <span style={{ marginLeft: 10 }}>{selectedPayment.paymentType}</span>
                                                </div>
                                                <div style={{ minWidth: 60 }}>
                                                    R$ {selectedPayment.valorPago.toFixed(2).toString().replace('.', ',')}
                                                </div>                                        
                                            </div>
                                            {selectedPayment.paymentType === "Dinheiro" &&
                                                <div className="lista-pedidos" style={{ height: 20, paddingLeft:12 }}>  
                                                    <div style={{ display: 'flex', alignItems: 'start', minWidth: 160 }}>                                                       
                                                        <span style={{ marginLeft: 10 }}>{calculaValorTroco() ==="0,00"?"Troco":`Troco para R$ ${selectedPayment.valorTroco.toFixed(2).toString().replace('.', ',')}`}</span>
                                                    </div>
                                                    <div style={{ minWidth: calculaValorTroco()=== "0,00"? 100 : 52 }}>
                                                        {calculaValorTroco()=== "0,00" ? "Não informado" : `R$ ${calculaValorTroco()}`}
                                                    </div> 
                                                </div>
                                            }
                                            <div className="line-separator" />
                                            <div className="divBtnsEditAndAddObsItem">
                                                <div style={{ width: '100%', borderRight: '1px solid lightgray' }}>
                                                    <button className="btnRemoveItemRelative" onClick={() => handleRemovePayment()}>Excluir</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {calculaValorRestante() === "0,00" ?
                                    <div className="missing-amount-modalPagamentos" style={{ background: '#28a745' }}>
                                        <div className="summary-item-modalPagamentos total-modalPagamentos" 
                                            style={{ justifyContent: 'center', width: '100%', borderBottom: 'none' }}>
                                            <span className="label-modalPagamentos" style={{ color: 'white' }}>O valor total foi pago</span>
                                        </div>
                                    </div>
                                    :
                                    <div className="missing-amount-modalPagamentos">
                                        <div className="summary-item-modalPagamentos total-modalPagamentos" style={{ borderBottom: 'none', width: '100%' }}>
                                            <span className="label-modalPagamentos">Falta</span>
                                            <span className="value-modalPagamentos">R$ {calculaValorRestante()}</span>
                                        </div>
                                    </div>
                                }                                        
                            </div>
                        </div>
                    </div>
                    <div className="divBtnAddFormaEntregaModal"
                        style={{
                            background: !formaPagamento || formaPagamento==="Dividido" && calculaValorRestante() !== "0,00" ? '#bbb' : '',
                            cursor: !formaPagamento || formaPagamento==="Dividido" && calculaValorRestante() !== "0,00" ? 'not-allowed' : ''
                        }}
                    >
                        <button onClick={() => saveModalPayment()} disabled={!formaPagamento || formaPagamento==="Dividido" && calculaValorRestante() !== "0,00"}
                            style={{
                                background: !formaPagamento || formaPagamento==="Dividido" && calculaValorRestante() !== "0,00" ? '#bbb' : '',
                                cursor: !formaPagamento || formaPagamento==="Dividido" && calculaValorRestante() !== "0,00" ? 'not-allowed' : ''
                            }}
                        >
                            {selectedPayment && calculaValorRestante()=== "0,00" ? "Finalizar Pagamento" : "Adicionar Forma de Pagamento"}
                        </button>
                    </div>
                </div>
            </DivPedido>
        </DivPedidoContainer>
    )

}

export default ModalPagamentos;