import React from "react";
import "./styles.css";

const PaginationItem = ({ number, isCurrent = false, onPageChange }) => {
  if (isCurrent) {
    return <button className="button-default pagination-item">{number}</button>;
  }

  return (
    <button
      className="button-default cancel-button"
      onClick={() => onPageChange(number)}
    >
      {number}
    </button>
  );
};

export default PaginationItem;
