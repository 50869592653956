import { AppConfigContext } from "antd/es/app/context";
import axios from "axios";
import { toast } from 'react-toastify';

const isDevelopment = window.location.hostname === "localhost" ? true : false;
const apiUrl = isDevelopment
  ? process.env.REACT_APP_SERVER_URL_DEV
  : process.env.REACT_APP_SERVER_URL_PROD;
const apiInstancesWppUrl = process.env.REACT_APP_API_INSTANCES_WPP_URL;
export const api = axios.create({
  baseURL: apiUrl,
});

const apiInstancesWpp = axios.create({
  baseURL: apiInstancesWppUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

// Função para configurar o token de autorização
const setAuthToken = (token) => {
  if (token) {
    // Aplica o token de autorização a todas as requisições se o token estiver presente
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    // Remove o header de autorização se o token não estiver presente
    delete api.defaults.headers.common["Authorization"];
  }
};

export const createSession = async (email, password) => {
  return api.post("/auth/login", { email, password });
};

export const checkLicense = (userId) => {
  return api.get(`/check-license/${userId}`);
};

export const getUsers = async (userID) => {
  return api.get("/list-users/" + userID);
};

export const getEntregadores = async (id_empresa) => {
  return api.get("/list-entregadores/" + id_empresa);
};

export const listarCaixas = async (id, page, startDate, endDate) => {
  return api.get("/list-caixas/" + id, {
    params: {
      page,
      per_page: 10,
      startDate,
      endDate,
    },
  });
};

export const getUserRole = async (userID) => {
  return api.get("/user-role/" + userID);
};

export const getPlansIugu = async (userID) => {
  return api.get("/list-plans/" + userID);
};

export const getFilPlansIugu = async (userID) => {
  return api.get("/list-fil-plans/" + userID);
};


export const getUser = async (userID) => {
  return api.get("/user/" + userID);
};

export const getEmpresaWithObjId = async (empresaObjId) => {
  return api.get("/empresaWithObjId/" + empresaObjId);
};

export const getEmpresa = async (userID, id_empresa) => {
  return api.post("/get-empresa/" + userID, { id_empresa });
};

export const getCaixaById = async (userID, id_caixa) => {
  return api.post("/get-caixa/" + userID, { id_caixa });
};

export const testeImpressao = async (companyId) => {
  return api.post("/print-test", {companyId});
}

export const getPedidos = async (userID, vinculo_empresa) => {
  return api.post("/get-pedidos/" + userID, { vinculo_empresa });
};

export const getPedido = async (
  userID,
  id_empresa,
  vinculo_empresa,
  id_pedido
) => {
  try {
    return await api.post("/get-pedido/" + userID, {
      id_empresa,
      vinculo_empresa,
      id_pedido,
    });
  } catch (error) {
    console.log("Erro ao buscar pedido", error);
    throw error;
  }
};

export const getCategorias = async (
  userID,
  id_empresa,
  vinculo_empresa,
  empresaObjId
) => {
  return api.post("/get-categorias/" + userID, {
    id_empresa,
    vinculo_empresa,
    empresaObjId,
  });
};

export const getAdicionais = async (
  userID,
  id_empresa,
  vinculo_empresa,
  empresaObjId
) => {
  return api.post("/get-adicionais/" + userID, {
    id_empresa,
    vinculo_empresa,
    empresaObjId,
  });
};

export const getAdicionaisCardapio = async (id_empresa, nomeEmpresa) => {
  return api.post("/get-adicionais-cardapio/", { id_empresa, nomeEmpresa });
};

export const getCategoriasCardapio = async (id_empresa, nomeEmpresa) => {
  return api.post("/get-categorias-cardapio/", { id_empresa, nomeEmpresa });
};

export const getItens = async (
  userID,
  id_empresa,
  vinculo_empresa,
  empresaObjId
) => {
  return api.post("/get-itens/" + userID, {
    id_empresa,
    vinculo_empresa,
    empresaObjId,
  });
};

export const getItensCardapio = async (id_empresa, nomeEmpresa) => {
  return api.post("/get-itens-cardapio/", { id_empresa, nomeEmpresa });
};

export const getItem = async (
  userID,
  id_empresa,
  vinculo_empresa,
  empresaObjId,
  itemObjId
) => {
  return api.post("/get-item/" + userID, {
    id_empresa,
    vinculo_empresa,
    empresaObjId,
    itemObjId,
  });
};

export const getItemCardapio = async (id_empresa, itemObjId) => {
  return api.post("/get-item-cardapio/", { id_empresa, itemObjId });
};

export const getPedidosByStatus = async (
  userID,
  id_empresa,
  vinculo_empresa,
  status_pedido,
  forReport
) => {
  return api.post("/get-pedidosStatus/" + userID, {
    id_empresa,
    vinculo_empresa,
    status_pedido,
    forReport
  });
};


export const getPedidosFinalizadosHistorico = async (
  userID,
  id_empresa,
  vinculo_empresa,
  status_pedido,
  periodo
) => {
  return api.post("/get-pedidosFinalizadosHistorico/" + userID, {
    id_empresa,
    vinculo_empresa,
    status_pedido,
    periodo,
  });
};

export const getPedidosFinalizadosPeriodo = async (
  userID,
  id_empresa,
  vinculo_empresa,
  status_pedido,
  startDate,
  endDate
) => {
  return api.post("/get-pedidosFinalizadosPeriodo/" + userID, {
    id_empresa,
    vinculo_empresa,
    status_pedido,
    startDate,
    endDate,
  });
};

export const getPedidosToPrint = async (
  userID,
  id_empresa,
  vinculo_empresa
) => {
  return api.post("/get-pedidos-toprint/" + userID, {
    id_empresa,
    vinculo_empresa,
  });
};

export const getCliente = async (userID, id_empresa, telefone) => {
  return api.post("/get-cliente/" + userID, { id_empresa, telefone });
};

export const getVendedor = async (userID, id_vendedor) => {
  return api.post("/get-vendedor/" + userID, { id_vendedor });
};

export const getOrcamento = async (userID, id_orcamento) => {
  return api.post("/get-orcamento/" + userID, { id_orcamento });
};

export const getVinculoEmpresa = async (userID) => {
  return api.get("/vinculo-empresa/" + userID);
};

export const getEmpresas = async (userID) => {
  return api.get("/list-empresas/" + userID);
};

export const getRevendas = async (userID) => {
  return api.get("/list-revendas/" + userID);
};

export const getClientes = async (userID) => {
  return api.get("/list-clientes/" + userID);
};

export const getCompanyResponses = async (companyId) => {
  return api.get("/company-responses/" + companyId);
};

export const getVendedores = async (userID) => {
  return api.get("/list-vendedores/" + userID);
};

export const getOrcamentos = async (userID) => {
  return api.get("/list-orcamentos/" + userID);
};

export const updateUser = async (
  id,
  userID,
  email,
  password,
  name,
  vinculo_empresa,
  role
) => {
  return api.post("/update-user/" + userID, {
    id,
    email,
    password,
    name,
    vinculo_empresa,
    role,
  });
};

export const updateEntregador = async (
  idToEdit,
  userID,
  id_empresa,
  name,
  veiculo,
  telefone,
  placa
) => {
  return api.post("/update-entregador/" + userID, {
    idToEdit,
    id_empresa,
    name,
    veiculo,
    telefone,
    placa,
  });
};

export const updateTempoEntregaEmpresa = async (
  userID,
  _id,
  id,
  tempoBalcaoMin,
  tempoBalcaoMax,
  tempoEntregaMin,
  tempoEntregaMax,
  tipo_impressao
) => {
  return api.post("/update-empresaTempoEntrega/" + userID, {
    _id,
    id,
    tempoBalcaoMin,
    tempoBalcaoMax,
    tempoEntregaMin,
    tempoEntregaMax,
    tipo_impressao,
  });
};

export const updateTempoHorarioFuncionamento = async (
  userID,
  _id,
  id,
  status_loja,
  horario_funcionamento,
  timezone
) => {
  return api.post("/update-empresaHorarioFuncionamento/" + userID, {
    _id,
    id,
    status_loja,
    horario_funcionamento,
    timezone,
  });
};

export const updateTypeRegion = async (
  _id,
  id,
  userID,
  region_type_delivery
) => {
  return api.post("/update-empresa/" + userID, {
    _id: _id,
    id: id,
    region_type_delivery: region_type_delivery,
  });
};

export const updateClienteAddress = async (
  id_empresa,
  telefone,
  endereco,
  enderecoToEdit
) => {
  return api.post("/update-endereco-cliente/", {
    id_empresa,
    telefone,
    endereco,
    enderecoToEdit,
  });
};

export const updateRaioEntregaEmpresa = async (
  _id,
  id,
  userID,
  raio_entrega,
  valor_entrega
) => {
  return api.post("/update-raioEntrega/" + userID, {
    _id: _id,
    id: id,
    raio_entrega: raio_entrega,
    valor_entrega: valor_entrega,
  });
};

export const updateBairroEntregaEmpresa = async (
  _id,
  id,
  userID,
  bairro_entrega,
  valor_entrega
) => {
  return api.post("/update-bairroEntrega/" + userID, {
    _id: _id,
    id: id,
    bairro_entrega: bairro_entrega,
    valor_entrega: valor_entrega,
  });
};

export const updateCategoriasOrder = async (
  userID,
  _id,
  id_categoria,
  id_empresa,
  order
) => {
  return api.post("/update-categoria/" + userID, {
    _id: _id,
    id_categoria: id_categoria,
    id_empresa: id_empresa,
    order: order,
  });
};

export const updateItensOrder = async (
  userID,
  _id,
  id_item,
  id_empresa,
  order
) => {
  return api.post("/update-item/" + userID, {
    _id: _id,
    id_item: id_item,
    id_empresa: id_empresa,
    order: order,
  });
};

export const updateItemOut = async (userID, _id, id_item, id_empresa, out) => {
  return api.post("/update-item/" + userID, {
    _id: _id,
    id_item: id_item,
    id_empresa: id_empresa,
    out: out,
  });
};

export const updateItemPrice = async (
  userID,
  _id,
  id_item,
  id_empresa,
  price
) => {
  return api.post("/update-item/" + userID, {
    _id: _id,
    id_item: id_item,
    id_empresa: id_empresa,
    price: price,
  });
};

export const updateCategorias = async (
  userID,
  _id,
  id_categoria,
  id_empresa,
  title,
  disponibilidade,
  dia_horario_disponibilidade
) => {
  return api.post("/update-categoria/" + userID, {
    _id: _id,
    id_categoria: id_categoria,
    id_empresa: id_empresa,
    title: title,
    disponibilidade: disponibilidade,
    dia_horario_disponibilidade: dia_horario_disponibilidade,
  });
};

export const updateAdicionaisGroup = async ({
  userID,
  adicional_objId,
  idEmpresa,
  title,
  minimo,
  maximo,
  mandatory,
  calcular_maior_valor
}) => {
  return api.post("/update-grupo-adicionais/" + userID, {
    _id: adicional_objId,
    id_empresa: idEmpresa,
    title: title,
    min: minimo,
    max: maximo,
    mandatory: mandatory,
    calcular_maior_valor: calcular_maior_valor
  });
};

export const updateItens = async (
  userID,
  itemObjId,
  id_item,
  idEmpresa,
  orderItem,
  category_item_id,
  category_item_title,
  title,
  description,
  out,
  images,
  price,
  has_adicional,
  adicionais
) => {
  return api.post("/update-item/" + userID, {
    _id: itemObjId,
    id_item: id_item,
    id_empresa: idEmpresa,
    order: orderItem,
    category_item_id: category_item_id,
    category_item_title: category_item_title,
    title: title,
    description: description,
    out: out,
    images: images,
    price: price,
    has_adicional: has_adicional,
    adicionais: adicionais,
  });
};

export const updateStatusPedido = async (
  userID,
  _id,
  id_pedido,
  status_pedido
) => {
  return api.post("/update-pedido/" + userID, {
    _id: _id,
    id_pedido: id_pedido,
    status_pedido: status_pedido,
    //"status_print":status_print,
  });
};

export const updatePedidoWithEntregador = async (
  userID,
  _id,
  id_pedido,
  entregador
) => {
  return axios.post(`${apiUrl}/update-pedido/${userID}`, {
    _id: _id,
    id_pedido: id_pedido,
    entregador: entregador,
  });
};

export const apiUpdateImportFlag = async (
  idEmpresa,
  importacao_finalizada
) => {
  return api.post(`/update-import-flag/${idEmpresa}`, {
    importacao_finalizada
  });
};

export const ImprimirPedido = async (userID, dadosPedido) => {
  return api.post("/command-print/" + userID, {
    dadosPedido,
  });
};

export const updateStatusPedidoFinalizado = async (
  userID,
  _id,
  id_pedido,
  status_pedido,
  finalizadoAt
) => {
  return api.post("/update-pedido/" + userID, {
    _id: _id,
    id_pedido: id_pedido,
    status_pedido: status_pedido,
    //"status_print":status_print,
    finalizadoAt: finalizadoAt,
  });
};

export const updateStatusPrint = async (userID, _id, id_pedido) => {
  //console.log("caiuNoUpdateStatusPrint>", id_pedido);
  return api.post("/update-pedido/" + userID, {
    _id: _id,
    id_pedido: id_pedido,
    status_pedido: "2",
    status_print: false,
  });
};

export const fecharCaixa = async (
  id_empresa,
  objIdCaixa,
  closedBy,
  saldo_final,
  valor_informado_dinheiro,
  valor_informado_cartao,
  valor_informado_pix
) => {
  return api.post("/closeCaixa/", {
    id_empresa,
    objIdCaixa,
    closedBy,
    saldo_final,
    valor_informado_dinheiro,
    valor_informado_cartao,
    valor_informado_pix,
  });
};
export const addLancamentoCaixa = async (
  id_empresa,
  objIdCaixa,
  descricao,
  tipo_lancamento,
  valor,
  createdBy
) => {
  return api.post("/addLancamentoCaixa/", {
    id_empresa,
    objIdCaixa,
    descricao,
    tipo_lancamento,
    valor,
    createdBy,
  });
};

export const updateEmpresa = async (
  id,
  userID,
  cnpj,
  name,
  razao,
  email,
  cep,
  estado,
  municipio,
  bairro,
  complemento,
  telefone,
  celular
) => {
  console.log("caiu no updateEmpresa Normal");
  return api.post("/update-empresa/" + userID, {
    id,
    cnpj,
    name,
    razao,
    email,
    cep,
    estado,
    municipio,
    bairro,
    complemento,
    telefone,
    celular,
  });
};

export const updateEmpresaAddress = async (
  _id,
  id,
  userID,
  cep,
  estado,
  municipio,
  bairro,
  address_number,
  complemento,
  latitude,
  longitude
) => {
  return api.post("/update-empresa/" + userID, {
    _id,
    id,
    cep,
    estado,
    municipio,
    bairro,
    address_number,
    complemento,
    latitude,
    longitude
  });
};

export const updateCliente = async (
  id,
  userID,
  documento,
  name,
  razao,
  contato,
  email,
  cep,
  estado,
  municipio,
  bairro,
  complemento,
  telefone,
  celular,
  type
) => {
  if (type === "F") {
    return api.post("/update-cliente/" + userID, {
      id,
      documento,
      name,
      contato,
      email,
      cep,
      estado,
      municipio,
      bairro,
      complemento,
      telefone,
      celular,
    });
  } else {
    return api.post("/update-cliente/" + userID, {
      id,
      documento,
      name,
      razao,
      email,
      cep,
      estado,
      municipio,
      bairro,
      complemento,
      telefone,
      celular,
    });
  }
};

export const updateVendedor = async (
  id,
  userID,
  documento,
  name,
  cep,
  estado,
  municipio,
  bairro,
  complemento,
  telefone,
  celular
) => {
  return api.post("/update-vendedor/" + userID, {
    id,
    documento,
    name,
    cep,
    estado,
    municipio,
    bairro,
    complemento,
    telefone,
    celular,
  });
};

export const updateOrcamento = async (
  id,
  userID,
  codigo_cliente,
  nome_cliente,
  codigo_vendedor,
  nome_vendedor,
  total_orc,
  status_orc,
  id_grupo,
  vinculo_empresa,
  items
) => {
  return api.post("/update-orcamento/" + userID, {
    id,
    codigo_cliente,
    nome_cliente,
    codigo_vendedor,
    nome_vendedor,
    total_orc,
    status_orc,
    id_grupo,
    vinculo_empresa,
    items,
  });
};

export const updateStatus = async (id_empresa, userID, status) => {
  return api.post("/update-status/" + userID, { id_empresa, status });
};

export const updateUserImg = async (userID, image) => {
  return api.post("/update-user-img/" + userID, { image });
};

export const deleteUser = async (id, userID) => {
  return api.post("/delete-user/" + userID, { id });
};

export const deleteEntregador = async (id, userID) => {
  return api.post("/delete-entregador/" + userID, { id });
};

export const cancelarPedido = async (id, userID, empresaId, senha) => {
  return api.post("/cancelar-pedido/" + userID, { id, empresaId, senha });
};

export const updateEmpresaCancelamentoPedido = async (payload) => {
  return api.post("/configurar-senha-cancelamento", payload);
};

export const deleteEmpresa = async (userID, id) => {
  return api.post("/delete-empresa/" + userID, { id });
};

export const deleteCategoria = async (userID, id) => {
  return api.post("/delete-categoria/" + userID, { id });
};

export const deleteGrupoAdicional = async (userID, id) => {
  return api.post("/delete-grupo-adicional/" + userID, { id });
};

export const deleteItem = async (userID, id) => {
  return api.post("/delete-item/" + userID, { id });
};

export const deleteEnderecoCliente = async (
  id_empresa,
  telefone,
  enderecoIdToDelete
) => {
  return api.post("/delete-endereco-cliente/", {
    id_empresa,
    telefone,
    enderecoIdToDelete,
  });
};

export const deleteCliente = async (id, userID) => {
  return api.post("/delete-cliente/" + userID, { id });
};

export const deleteVendedor = async (id, userID) => {
  return api.post("/delete-vendedor/" + userID, { id });
};

export const deleteOrcamento = async (id, userID) => {
  return api.post("/delete-orcamento/" + userID, { id });
};

export const deleteRaioEntrega = async (
  userID,
  id_empresa,
  id_raio_entrega
) => {
  return api.post("/delete-raioEntrega/" + userID, {
    id_empresa,
    id_raio_entrega,
  });
};

export const deleteBairroEntrega = async (
  userID,
  id_empresa,
  id_bairro_entrega
) => {
  return api.post("/delete-bairroEntrega/" + userID, {
    id_empresa,
    id_bairro_entrega,
  });
};

export const register = async (
  createdBy,
  name,
  email,
  password,
  confirmpassword,
  vinculo_empresa,
  role
) => {
  return api.post("/auth/register", {
    createdBy,
    name,
    email,
    password,
    confirmpassword,
    vinculo_empresa,
    role,
  });
};

export const registerEntregador = async (
  createdBy,
  id_empresa,
  name,
  telefone,
  veiculo,
  placa
) => {
  return api.post("/registerEntregador", {
    createdBy,
    id_empresa,
    name,
    telefone,
    veiculo,
    placa,
  });
};

export const registerEmpresa = async (
  createdBy,
  cnpj,
  name,
  razao,
  email,
  cep,
  estado,
  municipio,
  bairro,
  complemento,
  telefone,
  celular,
  id_grupo,
  type
) => {
  return api.post("/auth/registerEmpresa", {
    createdBy,
    cnpj,
    name,
    razao,
    email,
    cep,
    estado,
    municipio,
    bairro,
    complemento,
    telefone,
    celular,
    id_grupo,
    type,
  });
};

export const createEmpresaUser = async (
  cnpj,
  nome_empresa,
  email,
  cep,
  estado,
  municipio,
  bairro,
  logradouro,
  address_number,
  complemento,
  telefone,
  type,
  hasComputer,
  nome_pessoa,
  password,
  confirmpassword,
  vinculo_empresa
) => {
  return api.post("/createEmpresaUser", {
    cnpj,
    nome_empresa,
    email,
    cep,
    estado,
    municipio,
    bairro,
    logradouro,
    address_number,
    complemento,
    telefone,
    type,
    hasComputer,
    nome_pessoa,
    password,
    confirmpassword,
    vinculo_empresa,
  });
};

export const registerPedido = async (
  createdBy,
  id_empresa,
  itens,
  celular_cliente,
  nome_cliente,
  tipo_pagamento,
  entrega,
  desconto,
  cpf_cnpj,
  valor_troco,
  valor_total,
  descricao,
  external_id
) => {
  return api.post("/auth/registerPedido", {
    createdBy,
    id_empresa,
    itens,
    celular_cliente,
    nome_cliente,
    tipo_pagamento,
    entrega,
    desconto,
    cpf_cnpj,
    valor_troco,
    valor_total,
    descricao,
    external_id
  });
};

export const registerCategoria = async (
  createdBy,
  id_empresa,
  title,
  disponibilidade,
  dia_horario_disponibilidade,
  only_pdv,
  only_qrcode,
  only_delivery_take_local
) => {
  return api.post("/auth/registerCategoria", {
    createdBy,
    id_empresa,
    title,
    disponibilidade,
    dia_horario_disponibilidade,
    only_pdv,
    only_qrcode,
    only_delivery_take_local,
  });
};

export const registerItem = async (
  createdBy,
  id_empresa,
  category_item_id,
  category_item_title,
  title,
  description,
  out,
  image,
  price,
  disponibilidade
) => {
  return api.post("/auth/registerItem", {
    createdBy,
    id_empresa,
    category_item_id,
    category_item_title,
    title,
    description,
    out,
    image,
    price,
    disponibilidade,
  });
};

export const registerGrupoAdicionais = async (
  createdBy,
  id_empresa,
  title,
  min,
  max,
  mandatory,
  out,
  calcular_maior_valor
) => {
  return api.post("/auth/registerGrupoAdicionais", {
    createdBy,
    id_empresa,
    title,
    min,
    max,
    mandatory,
    out,
    calcular_maior_valor
  });
};

export const registerAdicionais = async (
  createdBy,
  id_grupo,
  id_empresa,
  title,
  price,
  out,
  image
) => {
  return api.post("/auth/registerAdicionais/" + createdBy, {
    createdBy,
    id_grupo,
    id_empresa,
    title,
    price,
    out,
    image,
  });
};

export const updateItemAdicional = async (data) => {
  return api.post("/auth/updateAdicional/" + data.userID, data);
};

export const deleteItemAdicional = async (data) => {
  return api.post("/auth/deleteSubadicional/" + data.userID, data);
};

export const registerCaixaAndOpen = async (
  createdBy,
  id_empresa,
  saldo_inicial
) => {
  return api.post("/registerCaixaAndOpen/", {
    createdBy,
    id_empresa,
    saldo_inicial,
  });
};

export const registerPlanoAdmin = async (
  createdBy,
  title,
  plan_identifier,
  plan_cycle,
  tempo_duracao,
  valor_plano,
  max_cycle
) => {
  return api.post("/auth/registerPlanoAdmin", {
    createdBy,
    title,
    plan_identifier,
    plan_cycle,
    tempo_duracao,
    valor_plano,
    max_cycle
  });
};

export const searchClienteIuguAndGetInvoice = async (
  createdBy,
  email, 
  name, 
  cpf_cnpj, 
  zip_code, 
  number, 
  plan_identifier
) => {
  return api.post("/cadastrarClienteIugu", {
    createdBy,
    email, 
    name, 
    cpf_cnpj, 
    zip_code, 
    number, 
    plan_identifier
  });
};

export const deleteIuguPlan = async (
  createdBy,
  planId
) => {
  return api.post("/delete-plan-iugu/"+createdBy,{
    planId
  });
}

export const registerCliente = async (
  createdBy,
  documento,
  name,
  contato,
  razao,
  email,
  cep,
  estado,
  municipio,
  bairro,
  complemento,
  telefone,
  celular,
  vinculo_empresa,
  type
) => {
  if (type === "F") {
    return api.post("/auth/registerCliente", {
      createdBy,
      documento,
      name,
      contato,
      email,
      cep,
      estado,
      municipio,
      bairro,
      complemento,
      telefone,
      celular,
      vinculo_empresa,
      type,
    });
  } else {
    return api.post("/auth/registerCliente", {
      createdBy,
      documento,
      name,
      razao,
      email,
      cep,
      estado,
      municipio,
      bairro,
      complemento,
      telefone,
      celular,
      vinculo_empresa,
      type,
    });
  }
};

export const registerClienteFromCardapio = async (
  id_empresa,
  nome,
  telefone
) => {
  return api.post("/register-cliente-cardapio", { id_empresa, nome, telefone });
};

export const registerVendedor = async (
  createdBy,
  documento,
  name,
  cep,
  estado,
  municipio,
  bairro,
  complemento,
  telefone,
  celular,
  vinculo_empresa,
  type
) => {
  return api.post("/auth/registerVendedor", {
    createdBy,
    documento,
    name,
    cep,
    estado,
    municipio,
    bairro,
    complemento,
    telefone,
    celular,
    vinculo_empresa,
    type,
  });
};

export const registerOrcamento = async (
  createdBy,
  data_emissao,
  codigo_cliente,
  nome_cliente,
  codigo_vendedor,
  nome_vendedor,
  total_orc,
  status_orc,
  id_grupo,
  vinculo_empresa,
  items
) => {
  return api.post("/auth/registerOrcamento", {
    createdBy,
    data_emissao,
    codigo_cliente,
    nome_cliente,
    codigo_vendedor,
    nome_vendedor,
    total_orc,
    status_orc,
    id_grupo,
    vinculo_empresa,
    items,
  });
};

/*export const retornaClienteAsaas = async (nome_cliente, cpf_cnpj, email_cliente, phone, addressNumber, postalCode) => {   
    return api.post("/retorna-cliente-asaas",{nome_cliente, cpf_cnpj, email_cliente, phone, addressNumber, postalCode}); 
};*/

export const criarAssinaturaAsaasBolPix = async (
  customerID,
  payment_type,
  cycle,
  value,
  nextDueDate
) => {
  return api.post("/criar-assinatura-asaas-bolpix", {
    customerID,
    payment_type,
    cycle,
    value,
    nextDueDate,
  });
};

export const criarAssinaturaAsaasCartao = async (
  customerID,
  nome_cliente,
  cpf_cnpj,
  cycle,
  numero_cartao,
  expiryMonth,
  expiryYear,
  ccv,
  email,
  postalCode,
  addressNumber,
  phone,
  value,
  nextDueDate,
  remoteIp
) => {
  return api.post("/criar-assinatura-asaas-cartao", {
    customerID,
    nome_cliente,
    cpf_cnpj,
    cycle,
    numero_cartao,
    expiryMonth,
    expiryYear,
    ccv,
    email,
    postalCode,
    addressNumber,
    phone,
    value,
    nextDueDate,
    remoteIp,
  });
};

export const criarCobrancaAsaas = async (
  customerID,
  nome_cliente,
  cpf_cnpj,
  numero_cartao,
  expiryMonth,
  expiryYear,
  ccv,
  email,
  postalCode,
  addressNumber,
  phone,
  value,
  nextDueDate,
  remoteIp,
  parcela
) => {
  return api.post("/criar-cobranca-asaas-cartao", {
    customerID,
    nome_cliente,
    cpf_cnpj,
    numero_cartao,
    expiryMonth,
    expiryYear,
    ccv,
    email,
    postalCode,
    addressNumber,
    phone,
    value,
    nextDueDate,
    remoteIp,
    parcela,
  });
};

export const criarCobrancaAsaasBolPix = async (
  customerID,
  payment_type,
  value,
  nextDueDate
) => {
  return api.post("/criar-cobranca-asaas", {
    customerID,
    payment_type,
    value,
    nextDueDate,
  });
};

export const listarAssinaturasAsaas = async (customer_id) => {
  return api.post("/lista-subscription-asaas", { customer_id });
};

export const listarAssinaturasIugu = async (empresa_id, customer_id) => {
  return api.post("lista-subscription-iugu", {empresa_id, customer_id});
}

export const importCardapioIfood = async (url_import, id_empresa) => {
  return api.post("/import-cardapio-ifood", { url_import, id_empresa });
};

export const importCardapioAnotaai = async (url_import, id_empresa) => {
  //console.log("TRECHO API>",url_import)
  return api.post("/import-cardapio-anotaai", { url_import, id_empresa });
};

export const changeStatusLoja = async (id, status_loja) => {
  return api.post("/changeStatusLoja", {id, status_loja});
};

export const getUltimoPedidoID = async (id) => {
  return api.get("/ultimoPedidoID/" + id);
}

export const getDaysToExpireLicense = async (id) => {
  return api.get("/getDaysToExpireLicense/" + id);
}

export const apiCheckImportStatus = async (idEmpresa) => {
  return api.get(`/check-import-status/${idEmpresa}`);
};

export const configurarResetPedido = async (id_empresa, dias_para_reset) => {
  return api.post(`/configurar-reset-pedido`, { id_empresa, dias_para_reset });
};

export const resetPedidoManual = async (id_empresa) => {
  return api.post(`/reset-pedido-counter`, { id_empresa });
};

export const updateStatusBotEmpresa = async (id, status_bot) => {
  return api.patch(`/api/empresa/${id}/status_bot`, { status_bot });
};

export const getQrCodeWhatsapp = async (empresaID) => {
  //setAuthToken(token);
  return api.get("/api/v1/whatsapp/scanQR/" + empresaID);
};

export const sendTextMessageWhatsapp = async (empresaID, number, message) => {
  //setAuthToken(token);
  return api.post("/api/v1/whatsapp/sendTextMessage/" + empresaID, {number, message});
};

// Função para obter os chats
export const getWhatsappChats = async (
  empresaID,
  page,
  pageSize,
  query,
  token
) => {
  setAuthToken(token);
  return api.get(`/api/v1/whatsapp/chats/${empresaID}`, {
    params: {
      page,
      pageSize,
      query,
    },
  });
};

// Função para obter os conversa de um chat com LeadId
export const getWhatsappChatLead = async (
  empresaID,
  leadID,
  page,
  pageSize,
  token
) => {
  setAuthToken(token);
  return api.get(`/api/v1/whatsapp/chats/${empresaID}/messages/${leadID}`, {
    params: {
      page,
      pageSize,
    },
  });
};

export const getEstados = async () => {
  return api.get(
    "https://servicodados.ibge.gov.br/api/v1/localidades/estados/"
  );
};

export const getMunicipios = async (estadoSelecionado) => {
  return api.get(
    "https://servicodados.ibge.gov.br/api/v1/localidades/estados/" +
      estadoSelecionado +
      "/municipios"
  );
};

export const getBairros = async (city, uf) => {
  console.log("Chegou no getBairros da api:",city, uf)
  return api.get(`/carregar-bairros?city=${city}&uf=${uf}`);
};

export const calcularDistancia = async (coordenadasA, coordenadasB) => {
  const { lat: latA, lng: lngA } = coordenadasA;
  const { lat: latB, lng: lngB } = coordenadasB;
  return api.get(`/calcular-distancia?latA=${latA}&lngA=${lngA}&latB=${latB}&lngB=${lngB}`);
};
