import React, { useState, useContext, useEffect } from "react";
import './style.css'
import styled from 'styled-components';
import InputMask from 'react-input-mask'
import cep from 'cep-promise'
import { SidebarContext } from "../../AppRoutes";
import { AuthContext } from "../../contexts/auth";

import { getEstados, getMunicipios, registerCliente } from "../../services/api";

import PermissionGate from "../../services/PermissionGate";

import { useNavigate } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu";

import { useFormik } from "formik";
import * as Yup from "yup";

import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'

import { toast } from 'react-toastify';

const Teste = styled.div`

    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;
`;


const Cliente = () =>{
    const { user } = useContext(AuthContext);

    const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES

    const [cepNumber, setCep] = useState('');
    const [ufs, setUfs] = useState([]);
    const [cities, setCities] = useState('');

    const [estado, setEstado] = useState('');
    const [municipio, setMunicipio] = useState('');
    const [bairro, setBairro] = useState('');
    const [complementoEndereco, setComplementoEndereco] = useState('');

    const [telefone, setTelefone] = useState('');
    const [celular, setCelular] = useState('');

    const [tipo, setTipo] = useState('F');

    useEffect(() => {
        (async () => {
       
            const response = await getEstados();
            //console.log("TESTE ESTADOS:::",response)
            //const teste = [response.data]
            setUfs(response.data);
            //setLoading(false);
            
        })();
    },[]);

    useEffect(() => {
        
        getMunicipios(estado).then((response)=>{
            //console.log("MUNICIPIOS:::",response);
            setCities(response.data);
        })
        
    },[estado]);


    //Validações
    const SignUpSchema = Yup.object().shape({
        email: Yup.string().email("Insira um e-mail válido").required("Obrigatório"),      
        documento: Yup.string().required("Obrigatório"),
        nome: Yup.string().required("Obrigatório"),
        razao: tipo==='J' && Yup.string().required("Obrigatório"),
    });

    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        initialValues: {
          email: "",
          documento: "",
          nome: "",
          contato: "",
          razao: "",
        },
        onSubmit: (values) => {
          //alert(JSON.stringify(values, null, 2));
          //console.log(values);
        
          const documento = values.documento;
          const nome = values.nome;
          const contato = values.contato;
          const razao = values.razao;
          const email = values.email;

          //console.log("CEP:::",cepNumber);
          //console.log("ESTADO:::",estado);
          //console.log("MUNICIPIO:::",municipio);
          //console.log("BAIRRO:::",bairro);
          //console.log("COMPLEMENTO:::",complementoEndereco);

          //console.log("TELEFONE:::",telefone);
          //console.log("CELULAR:::",celular);
          handleSubmitAPI(documento, nome, contato, razao, email, cepNumber, estado, municipio, bairro, complementoEndereco, telefone, celular);
          
        },
    });
    
    const testeVerificaCep = () =>{
        //const idMunicipio = document.getElementById("municipio");
        //console.log('CEP NUMBER:::',cepNumber)
        if(cepNumber!=='_____-___'){
            ////console.log(cepNumber);
            cep(cepNumber).then( response => {
                //console.log(response)      
                setEstado(response.state)
                setMunicipio(response.city)
                //idMunicipio.value = (response.city)
                setBairro(response.neighborhood)
                setComplementoEndereco(response.street)
                ////console.log(estado);
            })    
            
        }
    }

    const navigate = useNavigate();

    const handleBack = () => {
        //setSidebar(!sidebar)
        navigate("/list-cliente");
    }

    const resetMunicipio = () => {
        setMunicipio("");
    }

    const handleTipo = (e) => {
        setTipo(e.target.value);
        //console.log(tipo);
    }



    const handleSubmitAPI = (documento, nome, contato, razao, email, cepNumber, estado, municipio, bairro, complementoEndereco, telefone, celular) =>{
        //e.preventDefault();
        
        const createdBy = user._id;
        const vinculo_empresa = user.vinculo_empresa;
        const type = tipo;
        //console.log("USERDATA:",user);

        //Registrando a Revenda
        //console.log("submit revenda!",{createdBy, cnpj, nome, razao, email, cepNumber, estado, municipio, bairro, complementoEndereco, telefone, celular, vinculo_empresa, type});
        registerCliente(createdBy, documento, nome, contato, razao, email, cepNumber, estado, municipio, bairro, complementoEndereco, telefone, celular, vinculo_empresa, type).then(cast => {
            //console.log("AQUI DEU BOM, CRIOU REVENDA:::",cast)
            if(cast.status===201){
                toast(cast.data.msg, {type: "success"});
                navigate("/list-cliente");
            }
            else{
                toast(cast.data.msg, {type: "error"});
            }
        }).catch(err => {
            //console.log('Oh noooo!!');
            const msg = err.response.data.msg;
            ////console.log("MENSAGEM", msg)
            toast(msg, {type: "error"});
            
            
          });
        
    }


    return(

        <>
        <PermissionGate permissions={['viewCVO']}>
    

            <LeftMenu setSidebar={setSidebar} sidebar={sidebar}/>

            <Teste sidebar={sidebar}>
                <div className="m-5">
                    {/*<form onSubmit={handleSubmitButton}>*/}
                    <form onSubmit={formik.handleSubmit} style={{marginTop:"-30px"}}>
                    
                        <div className="form-header" style={{marginBottom:"0px"}}>
                            <div className="title">
                                <h1>Cadastrar Cliente</h1>
                            </div>
                        </div>

                        <div style={{display:"flex", justifyContent:"right"/*, height:"80px"*/}}>

                            <div className="div-buttons">
                                <div className="continue-button">                           
                                    <button type="submit">
                                        <AiIcons.AiOutlineSave style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Salvar</a> 
                                    </button>                            
                                </div>

                                <div className="back-button">                                                       
                                    <button onClick={handleBack}>
                                        <SlIcons.SlActionUndo style={{color:"#ff4c4c",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a >Voltar</a> 
                                    </button>
                                </div>
                            </div>

                        </div>
                    
                        <div className="input-group">
                            

                            <div className="input-box" style={{width:"15%"}}>
                                <label>Tipo do Cliente<span style={{color:"red"}}>*</span></label>
                                {/*<!-- Custom select structure --> */}
                                <div className="selectdiv" style={{minWidth:"1%", maxWidth:"95%"}}>
                                <label style={{width:"100%"}}>
                                    <select 
                                    id="tipo"
                                    type="tipo"
                                    name="tipo"
                                    onChange={(e) => handleTipo(e)}
                                    value={tipo}
                                    //defaultValue={tipo}
                                    //className={tipo === ""?'invalid-option':'beleza'}
                                    style={{width:"100%"}}
                                    >
                                        {/* <option value="" className="invalid-option">Selecione um Tipo</option> */}
                                        <option value={'F'}>Pessoa Física</option>
                                        <option value={'J'}>Pessoa Jurídica</option>
                                    </select>                                    
                                </label>
                                </div>
                                {/*<!-- Custom select structure --> */}
                            </div>
                                                                    
                        
                            <div className="input-box" style={{width:"15%"}}>
                                <label style={{display:tipo==='J'?'none':''}}>CPF<span style={{color:"red"}}>*</span></label>
                                <label style={{display:tipo==='F'?'none':''}}>CNPJ<span style={{color:"red"}}>*</span></label>
                                <InputMask 
                                mask={tipo==='F'?"999.999.999-99":"99.999.999/9999-99"}
                                placeholder={tipo==='F'?"Digite CPF do Cliente":"Digite CNPJ do Cliente"} 
                                id="documento"
                                type="documento"
                                name="documento"
                                onChange={formik.handleChange}
                                value={formik.values.documento}
                                />
                                {formik.errors.documento && (
                                    <div className="error">{formik.errors.documento}</div>
                                )}
                            </div>
                           

                            <div className="input-box" style={{width: "34%", transition:sidebar?"200ms":"100ms"}}>                                
                                <label style={{display:tipo==='F'?'none':''}}>Nome Fantasia<span style={{color:"red"}}>*</span></label>
                                <label style={{display:tipo==='J'?'none':''}}>Nome<span style={{color:"red"}}>*</span></label>
                                <input 
                                placeholder={tipo==='F'?"Digite o nome do Cliente":"Digite o nome Fantasia"} 
                                id="nome"
                                type="nome"
                                name="nome"
                                onChange={formik.handleChange}
                                value={formik.values.nome}
                                />
                                {formik.errors.nome && (
                                    <div className="error">{formik.errors.nome}</div>
                                )}
                            </div>

                            <div className="input-box" 
                            style={{
                            width: "34%", 
                            transition:sidebar?"200ms":"100ms",
                            display:tipo==='F'?'none':''
                            }}
                            >
                                <label>Razão Social<span style={{color:"red"}}>*</span></label>
                                <input 
                                placeholder="Digite a Razão Social" 
                                id="razao"
                                type="razao"
                                name="razao"
                                onChange={formik.handleChange}
                                value={formik.values.razao}
                                />
                                {formik.errors.razao && (
                                    <div className="error">{formik.errors.razao}</div>
                                )}
                            </div>

                            <div className="input-box" style={{
                            width: "34%", 
                            transition:sidebar?"200ms":"100ms",
                            display:tipo==='J'?'none':''
                            }}
                            >
                                <label>Nome Contato</label>
                                <input 
                                placeholder="Digite o nome do Contato" 
                                id="contato"
                                type="contato"
                                name="contato"
                                onChange={formik.handleChange}
                                value={formik.values.contato}
                                />
                                {formik.errors.contato && (
                                    <div className="error">{formik.errors.contato}</div>
                                )}
                            </div>

                            <div className="input-box" style={{width: "31%", transition:sidebar?"200ms":"100ms"}}>
                                <label>E-mail do Cliente<span style={{color:"red"}}>*</span></label>
                                <input 
                                placeholder="Digite e-mail do Cliente" 
                                id="email"
                                type="email"
                                name="email"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                />
                                {formik.errors.email && (
                                    <div className="error">{formik.errors.email}</div>
                                )}
                            </div>

                            <div className="input-box" style={{width:"21%"}}>
                                <label>CEP</label>
                                <InputMask mask="99999-999" id="cep" type="text" name="cep" onChange={(e) => setCep(e.target.value)} value={cepNumber} onBlur={testeVerificaCep} placeholder="Digite o CEP" />
                            </div>

                            <div className="input-box" style={{width:"23%"}}>
                                <label>Estado</label>
                                {/*<!-- Custom select structure --> */}
                                <div className="selectdiv">
                                    <label style={{width:"100%"}}>
                                        <select 
                                        id="estado"
                                        type="text"
                                        name="estado"
                                        onChange={(e) => setEstado(e.target.value)}
                                        onClick={resetMunicipio}
                                        value={estado}
                                        className={estado === ""?'invalid-option':'beleza'}
                                        style={{width:"100%"}}
                                        >
                                            <option value="" /*className="invalid-option"*/>Escolha o Estado</option>
                                            {ufs.map((uf)=>(
                                                <option key={uf.id} value={uf.sigla} style={{color:"black"}}>
                                                    {uf.nome}
                                                </option>
                                            ))}
                                            
                                        </select>                                      
                                    </label>
                                </div>
                                {/*<!-- Custom select structure --> */}
                                {/* <input id="estado" type="text" name="estado" placeholder="Digite o Estado" /> */}
                            </div>

                            <div className="input-box" style={{width: "23%", transition:sidebar?"200ms":"100ms"}}>
                                <label>Município</label>
                                {/*<!-- Custom select structure --> */}
                                <div className="selectdiv">
                                    <label style={{width:"100%"}}>
                                        <select 
                                        id="municipio"
                                        type="text"
                                        name="municipio"
                                        onChange={(e) => setMunicipio(e.target.value)}
                                        value={municipio}
                                        className={municipio === ""?'invalid-option':'beleza'}
                                        style={{width:"100%"}}
                                        >
                                            <option value="" /*className="invalid-option"*/>Escolha o Município</option>
                                            {
                                            municipio ===""?                                            
                                                cities ? 
                                                    cities.map((city)=>(
                                                        <option key={city.id} value={city.nome} style={{color:"black"}}>
                                                            {city.nome}
                                                        </option>                                                                                                                                        
                                                    ))
                                                :
                                                ''////console.log("n faz nada")
                                            :                                            
                                            <option value={municipio} style={{color:"black"}}>
                                                {municipio}
                                            </option>   
                                            //setMunicipio(municipio)
                                            }
                                            
                                        </select>                                      
                                    </label>
                                </div>
                                {/*<!-- Custom select structure --> */}
                                {/* <input id="municipio" type="text" name="municipio" placeholder="Digite o Município" /> */}
                            </div>

                            <div className="input-box" style={{width:"20%"}}>
                                <label>Bairro</label>
                                <input id="bairro" type="text" name="bairro" 
                                onChange={(e) => setBairro(e.target.value)}
                                value={bairro}
                                placeholder="Digite o Bairro" />
                            </div>

                            <div className="input-box" style={{width: "40%", transition:sidebar?"200ms":"100ms"}}>
                                <label>Complemento do Endereço</label>
                                <input id="complemento" type="text" name="complemento"
                                onChange={(e) => setComplementoEndereco(e.target.value)}
                                value={complementoEndereco}
                                placeholder="Digite o Complemento" />
                            </div>                                                      
                            
                            <div className="input-box" style={{width:"19%"}}>
                                <label htmlFor="telefone">Telefone</label>
                                <InputMask mask="(99)9999-9999" id="telefone" type="tel" name="telefone" 
                                onChange={(e) => setTelefone(e.target.value)}
                                value={telefone}
                                placeholder="(xx) xxxx-xxxx" />
                            </div>

                            <div className="input-box" style={{width:"19%"}}>
                                <label htmlFor="celular">Celular</label>
                                <InputMask mask="(99)99999-9999" id="celular" type="tel" name="celular"
                                onChange={(e) => setCelular(e.target.value)}
                                value={celular}
                                placeholder="(xx) x xxxx-xxxx" />
                            </div>

    

                        </div>

                        {/* <div className="continue-button">
                            <button type="submit"><a>Cadastrar</a> </button>
                        </div> */}
                    </form>
                </div>
            </Teste>
                
        </PermissionGate>       
        </>
        

        );
    };
    
    export default Cliente;