import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './style.css'
import PermissionGate from "../../services/PermissionGate";
import LeftMenu from "../../components/LeftMenu";
import styled from 'styled-components';
import QRCode from 'qrcode.react';
import { SidebarContext } from "../../AppRoutes";
import { useFormik } from "formik";
import * as Yup from "yup";
import { importCardapioAnotaai, importCardapioIfood, apiUpdateImportFlag, apiCheckImportStatus } from '../../services/api'; 
import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import io from 'socket.io-client'; // Adicione a dependência do socket.io-client
import { ProgressBar } from 'react-bootstrap';
import Loading from "react-loading";
import svgSuccess from "../../img/check-symbol-success.svg"

const Teste = styled.div`

    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;
`;


const ImportaCardapio = () => {

    const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const objIdEmpresa = empresaParse._id;
    const idEmpresa = empresaParse.id_empresa;

    const [linkObj, setLinkObj] = useState('');

    const [onIfoodAndAnotaai, setOnIfoodAndAnotaai] = useState(linkObj?linkObj.onIfoodAndAnotaai:'');
    const [importType, setImportType] = useState(linkObj?linkObj.importType:'');
    const [hasVideo, setHasVideo] = useState(false);

    const [progress, setProgress] = useState(null);
    const [importComplete, setImportComplete] = useState(false);

    const isDevelopment = window.location.hostname === 'localhost';
    const apiUrl = isDevelopment 
    ? process.env.REACT_APP_SERVER_URL_DEV 
    : process.env.REACT_APP_SERVER_URL_PROD;

    useEffect(() => {
        // Conectar ao servidor WebSocket
        //const socket = io('http://localhost:5000'); // ou seu URL de servidor
        const wsUrl = apiUrl; // Alterar a URL se o servidor estiver em um endereço diferente
        const socket = io(wsUrl,  {withCredentials: true, transports: ['websocket'], auth: {token: localStorage.getItem('token')}});
        // Entrar na sala da empresa
        socket.emit('joinCompanyRoom', { companyId: idEmpresa.toString(), clientId: 'progressClient' });

        // Ouça o evento de progresso da importação
        socket.on('import-progress', (data) => {
            console.log("chegou algo aqui?", data)
            setProgress(data.progress);
            if (data.progress === 100) {
                setImportComplete(true);
                // Aqui você pode chamar uma função para atualizar a flag no servidor, se necessário
                updateImportFlag();
            }   
        });
        checkImportStatus();
        // Cleanup quando o componente desmontar
        return () => {
            socket.off('import-progress');
            socket.disconnect();
        };
    }, []);

    // Refatorando a função checkImportStatus
    const checkImportStatus = async () => {
        try {
        const response = await apiCheckImportStatus(idEmpresa);
        const data = response.data; // assumindo que está usando axios, onde a resposta está no campo `data`
        
        if (data.importacao_finalizada) {
            setImportComplete(true);
        }
        } catch (error) {
        console.error("Erro ao verificar status de importação:", error);
        }
    };

    const updateImportFlag = async () => {
        try {
            const importacao_finalizada = true;
            const response = await apiUpdateImportFlag(idEmpresa, importacao_finalizada)            
        } catch (error) {
            console.error("Erro ao atualizar flag de importação:", error);
        }
    };

    const handleBack = () => {
        //setSidebar(!sidebar)
        navigate("/");
    }

    //Validações
    const SignUpSchema = Yup.object().shape({
        ifoodLink: importType === 'linkIfood' && Yup.string().required('Campo obrigatório'),
        anotaAiLink: importType === 'linkAnotaAi' && Yup.string().required('Campo obrigatório'),
    });

    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        //enableReinitialize: true,
        initialValues: {
            ifoodLink: linkObj && linkObj.importType === "linkIfood" ? linkObj.importLink : "",
            anotaAiLink: linkObj && linkObj.importType === "linkAnotaAi" ? linkObj.importLink : "",
        },
        onSubmit: (values) => {

            //const password = values.password;
            //const confirmPassword = values.confirmPassword;
            const ifoodLink = values.ifoodLink;
            const anotaAiLink = values.anotaAiLink
            var linkObj = {};
            //handleSubmitAPI();   
            if (importType === 'linkIfood') {
                //importaCardapioIfood(ifoodLink)
                linkObj = {
                    onIfoodAndAnotaai: onIfoodAndAnotaai,
                    importType: 'linkIfood',
                    importLink: ifoodLink
                }
                //importCardapioIfood(ifoodLink, 2);
                //navigate("/endereco", { state: { infoEmpresaObj, passwordObj, linkObj } });
            } else if (importType === 'linkAnotaAi') {
                //importaCardapioAnotaai(anotaAiLink)
                linkObj = {
                    onIfoodAndAnotaai: onIfoodAndAnotaai,
                    importType: 'linkAnotaAi',
                    importLink: anotaAiLink
                }
                //importCardapioAnotaai(anotaAiLink, 2);
                //navigate("/endereco", { state: { infoEmpresaObj, passwordObj, linkObj } });
            }

            handleSubmitAPI(linkObj)

        },
    });


    const handleSubmitAPI = async (linkObj) => {
        if (linkObj) {
            console.log("linkObj>", linkObj);
            try {
                setIsSubmitting(true);
                if (linkObj.importType === 'linkIfood') {
                    console.log("Caiu no import Ifood");
                    const response = await importCardapioIfood(linkObj.importLink, idEmpresa);
                    console.log("RESPOSTA Ifood:", response);
                } else if (linkObj.importType === 'linkAnotaAi') {
                    console.log("Caiu no import AnotaAi");
                    const response = await importCardapioAnotaai(linkObj.importLink, idEmpresa);
                    console.log("RESPOSTA AnotaAi:", response);
                }
            } catch (error) {
                console.error("Erro durante o import:", error);
            } finally {
                setIsSubmitting(false);
            }
        }    
    };

    return (
        <>
            <PermissionGate permissions={['default']}>


                <LeftMenu setSidebar={setSidebar} sidebar={sidebar} />

                <Teste sidebar={sidebar}>
                    <div className="m-5">
                       
                        <div className="containerAddEmpresaForm" style={{marginRight:70, minHeight:"75vh"}}>

                        {importComplete ? (
                                <div style={{display:'flex', flexDirection:'column', gap:20}}>
                                    <h3>Importação do Cardápio concluída!</h3>
                                    <img height={200} src={svgSuccess}></img>
                                </div>
                            ) : 
                            
                            progress !== null && !importComplete ? (
                                <div className="progress-bar" style={{minWidth:500}}>
                                    <a>Importação do cardápio em andamento</a>
                                    <ProgressBar style={{fontSize:19, height:60}} now={progress} label={`${progress}%`} />
                                </div>
                            ) :

                            (
                            <form onSubmit={formik.handleSubmit} className="formAddEmpresa">

                                <div className='informationsAccount'>

                                    <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px', backgroundColor: 'white', color: '#333', margin: 'auto' }}>
                                        <h3>Seu estabelecimento está cadastrado no iFood ou AnotaAi?</h3>
                                        <p>Selecione 'Sim' para sincronizar automaticamente seu cardápio existente e evitar o cadastro manual.</p>
                                        <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
                                            <button type="button"
                                                className={onIfoodAndAnotaai === 'sim' ? 'btnFwdCadastros' : 'btnBackCadastros'}
                                                onClick={() => setOnIfoodAndAnotaai('sim')} style={{ width: '50%' }}>
                                                Sim
                                            </button>                                            
                                        </div>
                                        {
                                            onIfoodAndAnotaai === 'sim' ?
                                                <div>
                                                    <div>
                                                        <h3>Como você deseja importar seu cardápio?</h3>
                                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '20px' }}>
                                                            <button type="button"
                                                                className={importType === 'linkIfood' ? 'btnFwdCadastros' : 'btnBackCadastros'}
                                                                style={{ width: '100%' }} onClick={() => setImportType('linkIfood')}>
                                                                Pelo link do meu cardápio IFood
                                                            </button>
                                                            <button type="button"
                                                                className={importType === 'linkAnotaAi' ? 'btnFwdCadastros' : 'btnBackCadastros'}
                                                                style={{ width: '100%' }} onClick={() => setImportType('linkAnotaAi')}>
                                                                Pelo link do meu cardápio AnotaAi
                                                            </button>
                                                            {/*<button className='btnBackCadastros' style={{ width: '100%' }}>Não desejo importar meu cardápio</button>*/}
                                                        </div>
                                                    </div>
                                                    {
                                                        importType === 'linkIfood' ?
                                                            <div>
                                                                <h3>Para finalizar, cole o link do seu cardápio iFood abaixo</h3>
                                                                <p style={{ marginBottom: '5px', textDecoration: 'underline', color: '#007bff', cursor: 'pointer' }} onClick={() => setHasVideo(true)}>Onde consigo o link da minha loja do iFood?</p>
                                                                {hasVideo ?
                                                                    <div>
                                                                        <p style={{ marginBottom: '20px' }}>Aprenda como copiar o link do seu cardápio iFood!</p>
                                                                        <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
                                                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/example" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                                        </div>
                                                                        <button className='btnBackCadastros' style={{ width: '100%', marginBottom: '10px' }} onClick={() => setHasVideo(false)}>Fechar vídeo</button>
                                                                    </div>
                                                                    : null
                                                                }
                                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '20px' }}>
                                                                    <label htmlFor="ifoodLink">Link do cardápio iFood *</label>
                                                                    <input type="text"
                                                                        id="ifoodLink"
                                                                        name="ifoodLink"
                                                                        value={formik.values.ifoodLink}
                                                                        onChange={formik.handleChange}
                                                                        style={{ padding: '10px', border: '1px solid #ccc' }}
                                                                        className='linkToImportInput'
                                                                        placeholder="https://www.ifood.com.br/delivery/cidade-uf/loja-exemplo-setor-exemplo/..." />
                                                                    {formik.errors.ifoodLink && (
                                                                        <div className="error">{formik.errors.ifoodLink}</div>
                                                                    )}
                                                                </div>
                                                                <div style={{ display: 'flex', gap: '10px' }}>
                                                                    {isSubmitting ?                                                                    
                                                                        <button type="button" disabled={true} className='btnFwdCadastros' style={{ width: '50%' }}>
                                                                            <Loading type="spin" color="white" height={30} width={30} style={{marginLeft:'45%'}}
                                                                            className="zIndexForLoadingSaveButton" />
                                                                        </button>                                                                
                                                                        :
                                                                        <button type="submit" className='btnFwdCadastros' style={{ width: '50%' }}>Importar</button>
                                                                    }
                                                                </div>
                                                            </div>
                                                            : importType === 'linkAnotaAi' &&
                                                            <div>
                                                                <h3>Para finalizar, cole o link do seu cardápio AnotaAi abaixo</h3>
                                                                <p style={{ marginBottom: '5px', textDecoration: 'underline', color: '#007bff', cursor: 'pointer' }} onClick={() => setHasVideo(true)}>Onde consigo o link da minha loja do AnotaAi?</p>
                                                                {hasVideo ?
                                                                    <div>
                                                                        <p style={{ marginBottom: '20px' }}>Aprenda como copiar o link do seu cardápio AnotaAi!</p>
                                                                        <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
                                                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/example" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                                        </div>
                                                                        <button className='btnBackCadastros' style={{ width: '100%', marginBottom: '10px' }} onClick={() => setHasVideo(false)}>Fechar vídeo</button>
                                                                    </div>
                                                                    : null
                                                                }
                                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '20px' }}>
                                                                    <label htmlFor="anotaAiLink">Link do cardápio AnotaAi *</label>
                                                                    <input type="text"
                                                                        id="anotaAiLink"
                                                                        name="anotaAiLink"
                                                                        value={formik.values.anotaAiLink}
                                                                        onChange={formik.handleChange}
                                                                        style={{ padding: '10px', border: '1px solid #ccc' }}
                                                                        className='linkToImportInput'
                                                                        placeholder="https://pedido.anota.ai/loja/nome-da-loja-1?f=msa" />
                                                                    {formik.errors.anotaAiLink && (
                                                                        <div className="error">{formik.errors.anotaAiLink}</div>
                                                                    )}
                                                                </div>
                                                                <div style={{ display: 'flex', gap: '10px' }}>
                                                                    {/*<button type="button" className='btnBackCadastros' style={{ width: '50%' }}>Voltar</button>*/}
                                                                    {isSubmitting ?                                                                    
                                                                        <button type="button" disabled={true} className='btnFwdCadastros' style={{ width: '50%' }}>
                                                                            <Loading type="spin" color="white" height={30} width={30} style={{marginLeft:'45%'}}
                                                                            className="zIndexForLoadingSaveButton" />
                                                                        </button>                                                                
                                                                        :
                                                                        <button type="submit" className='btnFwdCadastros' style={{ width: '50%' }}>Importar</button>
                                                                    }
                                                                </div>
                                                            </div>
                                                    }


                                                </div>
                                                :
                                                null
                                        }

                                    </div>

                                </div>

                            </form>
                            )}
                            
                        </div>
                       
                    </div>
                </Teste>

            </PermissionGate>
        </>
    )
}

export default ImportaCardapio;