import React from 'react';
import { useField } from 'formik';

const CurrencyInput = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);

  const formatCurrency = (value) => {
    if (!value) return '';

    // Remove non-digit characters
    const cleanedValue = value.replace(/\D/g, '');
    
    // Parse to integer for accurate cent calculation
    const numericValue = parseInt(cleanedValue, 10);

    // Format the number as BRL currency
    const formattedValue = (numericValue / 100).toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return `R$ ${formattedValue}`;
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatCurrency(inputValue);
    helpers.setValue(formattedValue);
  };

  return (
    <div>
      <input
        {...field}
        {...props}
        onChange={handleChange}
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default CurrencyInput;
