import { IoClose } from "react-icons/io5";
import './styles.css'

export function Modal({ isOpen, onClose, children}){
    return (
        <div className={`modal-overlay ${isOpen ? 'fadeIn' : 'fadeOut'}`} onClick={onClose}>
            <div className="modal-container" onClick={(event) => event.stopPropagation()} >
                <button className="close-button" onClick={onClose}><IoClose /></button>
                {children}
            </div>
        </div>
    )
}