import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './style.css'
import { SidebarContext } from "../../AppRoutes";
import PermissionGate from "../../services/PermissionGate";
import LeftMenu from "../../components/LeftMenu";
import ConfirmDialog from "../../components/ConfirmDialog";
import styled from 'styled-components';
import QRCode from 'qrcode.react';
import { LuPlusCircle } from "react-icons/lu";
import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import { FaRegTrashAlt } from "react-icons/fa";
import CryptoJS from 'crypto-js';
import { resetPedidoManual, getEmpresaWithObjId, getUltimoPedidoID, configurarResetPedido, updateEmpresaCancelamentoPedido } from "../../services/api";
import { toast } from "react-toastify";
import axios from "axios";
import moment from 'moment-timezone';

const Teste = styled.div`

    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;
`;


const MeusPedidosCfg = () => {

    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user')
    const user = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    const userParse = JSON.parse(user)
    const userID = userParse._id;
    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const idEmpresa = empresaParse.id_empresa;
    const empresaObjId = empresaParse._id;
    const [atualSeqId, setAtualSeqId] = useState(0);
    const [daysToReset, setDaysToReset] = useState(0);
    const [lastResetedDate, setLastResetedDate] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [tela, setTela] = useState("tela1")
    const [cancelOption, setCancelOption] = useState("noPassword");
    const [cancelPassword, setCancelPassword] = useState("");
    const [showInputNewPassword, setShowInputNewPassword] = useState(false);
    const [newCancelPassword, setNewCancelPassword] = useState("");
    const [currentCancelPassword, setCurrentCancelPassword] = useState("");
    const [hasCancelPassword, setHasCancelPassword] = useState(false);
    const [definindoNovaSenha, setDefinindoNovaSenha] = useState(true);
    const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Função para buscar os dados do banco de dados
        const fetchData = async () => {
            try {
                // Supondo que getEmpresaWithObjId seja uma função que retorna uma Promise
                const empresaTemp = await getEmpresaWithObjId(empresaObjId);
                console.log("empresaTemp:", empresaTemp.data);
                if (empresaTemp.data.empresa?.dias_para_reset) {
                    setDaysToReset(empresaTemp.data.empresa.dias_para_reset)
                }
                if (empresaTemp.data.empresa?.last_reset_date) {
                    const dataFormatada = moment(empresaTemp.data.empresa.last_reset_date)
                        .tz("America/Sao_Paulo")
                        .format("DD/MM/YYYY HH:mm");
                    setLastResetedDate(dataFormatada);
                }
                if (empresaTemp.data.empresa?.has_cancel_password) {
                    setCancelOption("standardPassword");
                    setDefinindoNovaSenha(false);
                }
                console.log("empresaTemp.data.empresa.has_cancel_password>>", empresaTemp.data.empresa.has_cancel_password)
                setHasCancelPassword(empresaTemp.data.empresa.has_cancel_password);
                const ultimoPedidoId = await getUltimoPedidoID(idEmpresa)
                if (ultimoPedidoId.data?.ultimoPedidoId) {
                    setAtualSeqId(ultimoPedidoId.data.ultimoPedidoId)
                }
            } catch (error) {
                console.error("Erro ao buscar dados da empresa:", error);
                // Lidar com o erro, talvez definindo algum estado de erro na UI
            }
        };

        fetchData();
    }, [refresh]); // O array vazio [] indica que o useEffect será executado uma vez após a montagem do componente

    const handleBack = () => {
        //setSidebar(!sidebar)
        navigate("/");
    }

    // Função para trocar a senha
    const handleChangePassword = () => {
        // Aqui você pode abrir um modal ou exibir um campo para que o usuário insira a nova senha
        setShowInputNewPassword(!showInputNewPassword);
        setDefinindoNovaSenha(false);
    };

    const handleOpenDialog = () => {
        setConfirmOpen(true)
    }
    const handleReset = () => {
        console.log("clicou no reset!");
        resetPedidoManual(empresaObjId).then(cast => {
            console.log("Resposta do servidor:::", cast)
            if (cast.status === 200) {
                toast(cast.data.msg, { autoClose: 5000, type: "success" });
            } else {
                toast(cast.data.msg, { autoClose: 5000, type: "error" });
            }
        }).catch(err => {
            console.log('Ocorreu algum erro no servidor!', err);
            //console.log(err.response.data.msg);
            toast(err.response.data.msg, { autoClose: 5000, type: "error" });
        }).finally(() => {
            setRefresh(!refresh);
        })
    }

    const saveChanges = async () => {
        console.log("clicou no submit!")
        //setIsSubmitting(true);
        configurarResetPedido(empresaObjId, daysToReset).then(cast => {

            console.log("Resposta do servidor:::", cast)
            if (cast.status === 200) {
                toast(cast.data.msg, { autoClose: 5000, type: "success" });
            } else {
                toast(cast.data.msg, { autoClose: 5000, type: "error" });
            }

        }).catch(err => {
            console.log('Ocorreu algum erro no servidor!', err);
            //console.log(err.response.data.msg);
            toast(err.response.data.msg, { autoClose: 5000, type: "error" });
        }).finally(() => {
            setIsSubmitting(false);
        })
    }

    const alterarCancelamentoPedido = async () => {
        console.log("clicou aqui")
        //setIsSubmitting(true);
        // Se a opção de senha padrão não estiver selecionada, desconsideramos a validação de senha
        if (cancelOption === "noPassword") {
            console.log("CAIU AQUI 1")
            const payload = {
                id_empresa: empresaObjId,
                has_cancel_password: false, // Definimos que a senha de cancelamento não está ativa
            };

            try {
                const response = await updateEmpresaCancelamentoPedido(payload);
                if (response.status === 200) {
                    toast(response.data.msg, { autoClose: 5000, type: "success" });
                    setHasCancelPassword(false);
                    setShowInputNewPassword(false);
                    setRefresh(!refresh);
                    setDefinindoNovaSenha(true);
                } else {
                    toast(response.data.msg, { autoClose: 5000, type: "error" });
                }
            } catch (err) {
                console.log('Ocorreu algum erro no servidor!', err);
                toast(err.response?.data?.msg || "Erro inesperado", { autoClose: 5000, type: "error" });
            } finally {
                setIsSubmitting(false);
            }
            // Encerrar a função aqui, pois não há necessidade de prosseguir
            return;

        }
        // Para a opção "Solicitar senha padrão", continuamos com as validações e envio da senha
        console.log("breakpoint1")
        let senha;
        let senhaAtual;
        let novaSenha;
        if(definindoNovaSenha){
            senha = cancelPassword
            senhaAtual = null
            novaSenha = null            
        }
        if (!definindoNovaSenha){
            senha = null
            senhaAtual = currentCancelPassword
            novaSenha = newCancelPassword
        }

        const payload = {
            id_empresa: empresaObjId,
            has_cancel_password: true,
            senha,
            senhaAtual,
            novaSenha
        };
        console.log("breakpoint2")    
        if(!showInputNewPassword && !definindoNovaSenha){
            console.log("Nao faz nada")
            return
        }   

        try {
            console.log("Está enviando requisição?")
            const response = await updateEmpresaCancelamentoPedido(payload);
            
            console.log("Está obtendo resposta?", response)
            if (response.status === 200) {
                toast(response.data.msg, { autoClose: 5000, type: "success" });
                setHasCancelPassword(true);
                setShowInputNewPassword(false);
                setCancelPassword("");
                setNewCancelPassword("");
                setCurrentCancelPassword("");
            } else {
                toast(response.data.msg, { autoClose: 5000, type: "error" });
            }
        } catch (err) {
            console.log('Ocorreu algum erro no servidor!', err);
            toast(err.response?.data?.msg || "Erro inesperado", { autoClose: 5000, type: "error" });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleDecrement = (value) => {
        setDaysToReset(value - 1);
    };

    const handleIncrement = (value) => {
        setDaysToReset(value + 1);
    };

    return (
        <>
            <PermissionGate permissions={['default']}>


                <LeftMenu setSidebar={setSidebar} sidebar={sidebar} />

                <ConfirmDialog
                    title="Resetar Contador de Pedidos?"
                    open={confirmOpen}
                    setOpen={setConfirmOpen}
                    onConfirm={handleReset}
                >
                    <div>
                        Tem certeza que deseja zerar o contador de pedidos?
                        <br />
                        <span style={{ fontSize: 12 }}>(O próximo pedido cadastrado será iniciado com o identificador 1)</span>
                    </div>
                </ConfirmDialog>

                <Teste sidebar={sidebar}>
                    <div className="m-5">
                        {/*<form onSubmit={handleSubmitButton}>*/}
                        <form /*onSubmit={formik.handleSubmit}*/ style={{ marginTop: "-30px" }}>

                            <div className="form-header" style={{ marginBottom: "0px" }}>
                                <div className="title">
                                    <h1>Configurações de Meus Pedidos</h1>
                                </div>
                            </div>

                            <div style={{ display: "flex", justifyContent: "right"/*, height:"80px"*/ }}>

                                <div className="div-buttons">
                                    <div className="continue-button">
                                        {tela === "tela1" ?
                                        <button type="button" onClick={saveChanges} disabled={isSubmitting}>
                                            <AiIcons.AiOutlineSave style={{ marginRight: "5px", fontSize: "22px", marginBottom: "2px" }} /><a>Salvar</a>
                                        </button>
                                        :
                                        <button type="button" onClick={alterarCancelamentoPedido} disabled={isSubmitting}>
                                            <AiIcons.AiOutlineSave style={{ marginRight: "5px", fontSize: "22px", marginBottom: "2px" }} /><a>Salvar</a>
                                        </button>
                                        }
                                    </div>

                                    <div className="back-button">
                                        <button onClick={handleBack}>
                                            <SlIcons.SlActionUndo style={{ color: "#ff4c4c", marginRight: "5px", fontSize: "18px", marginBottom: "2px" }} /><a >Voltar</a>
                                        </button>
                                    </div>
                                </div>

                            </div>

                            <div className="contentItemComplete">
                                <div className="input-group inputGroup-etapasItem" style={{ height: 140 }}>
                                    <div className={tela === "tela1" ? "etapasAddItem etapaAtiva" : "etapasAddItem"}
                                        style={{ borderBottom: '1px solid lightgray' }} onClick={() => setTela("tela1")}
                                    >
                                        <label>1. Sequência do pedido</label>
                                    </div>
                                    {/*<div className="etapasAddItem" style={{borderBottom:'1px solid lightgray'}}>
                                    <label>2. Adicionais</label>
                                </div>
                                <div className="etapasAddItem" style={{borderBottom:'1px solid lightgray'}}>
                                    <label>3. Classificações</label>
                                </div>*/}
                                    <div className={tela === "tela2" ? "etapasAddItem etapaAtiva" : "etapasAddItem"}
                                        onClick={() => setTela("tela2")}
                                    >
                                        <label>2. Cancelar pedido</label>
                                    </div>
                                </div>

                                <div className="input-group inputGroup-adicinaItem">

                                    <div className="formGroupRow">
                                        {tela === "tela1" &&
                                            <div className="schedule-settings" style={{ minWidth: '50%' }}>
                                                <h3>1. Sequência do pedido</h3>

                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <span style={{ fontWeight: 500 }}>Numeração Atual</span>
                                                    <div _ngcontent-ng-c1712864681="" className="input-box number">
                                                        <input
                                                            id='atualSeqId'
                                                            name="atualSeqId"
                                                            type="number"
                                                            readOnly disabled
                                                            //placeholder={0}
                                                            onKeyDown={(evt) =>
                                                                evt.key === 'e' && evt.preventDefault() ||
                                                                evt.key === 'E' && evt.preventDefault() ||
                                                                evt.key === '-' && evt.preventDefault() ||
                                                                evt.key === '.' && evt.preventDefault() ||
                                                                evt.key === ',' && evt.preventDefault()}
                                                            value={atualSeqId} onChange={(e) => setAtualSeqId(e.target.value)}
                                                            max={9999}
                                                            min={0}
                                                            maxLength=""
                                                        />
                                                    </div>
                                                </div>

                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <span style={{ fontWeight: 500 }}>Dias para zerar <span style={{ color: "red" }}>*</span></span>
                                                    <div className="input-box number" style={{ marginBottom: 0 }}>
                                                        <button type="button" className={daysToReset === 0 ? "decrement disable" : "decrement"} onClick={() => handleDecrement(daysToReset)} style={{ zIndex: "999", position: "relative", marginLeft: 5 }}
                                                        >
                                                            <pedeja-icon
                                                                iconname="minus"
                                                                style={{ height: 14 }}
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={14}
                                                                    height={14}
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    strokeWidth={2}
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    className="feather feather-minus"
                                                                >
                                                                    <line x1={5} y1={12} x2={19} y2={12} />
                                                                </svg>
                                                            </pedeja-icon>
                                                        </button>
                                                        <input
                                                            id='daysToReset'
                                                            name="daysToReset"
                                                            type="number"
                                                            style={{ marginLeft: -45 }}
                                                            //placeholder={0}
                                                            onKeyDown={(evt) =>
                                                                evt.key === 'e' && evt.preventDefault() ||
                                                                evt.key === 'E' && evt.preventDefault() ||
                                                                evt.key === '-' && evt.preventDefault() ||
                                                                evt.key === '.' && evt.preventDefault() ||
                                                                evt.key === ',' && evt.preventDefault()}
                                                            value={daysToReset} onChange={(e) => setDaysToReset(e.target.value)}
                                                            max={9999}
                                                            min={0}
                                                            maxLength=""
                                                        />
                                                        <button type="button" className="increment" onClick={() => handleIncrement(daysToReset)} style={{ marginLeft: -41 }}
                                                        >
                                                            <pedeja-icon style={{ height: 14 }}>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={14}
                                                                    height={14}
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    strokeWidth={2}
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    className="feather feather-plus"
                                                                >
                                                                    <line x1={12} y1={5} x2={12} y2={19} />
                                                                    <line x1={5} y1={12} x2={19} y2={12} />
                                                                </svg>
                                                            </pedeja-icon>
                                                        </button>

                                                        {/*formik.errors.daysToReset && (
                                                        <div className="error" style={{ position: 'absolute' }}>{formik.errors.daysToReset}</div>
                                                    )*/}
                                                    </div>
                                                    <span style={{ fontSize: 13 }}>O número sequencial do pedido {daysToReset <= 0 ? "nunca será zerado" : `será zerado a cada ${daysToReset} dias`}</span>
                                                </div>

                                                <div style={{ marginTop: '1.1rem', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <span style={{ fontWeight: 500 }}>Data do último Reset</span>
                                                        <div _ngcontent-ng-c1712864681="" className="input-box number">
                                                            <input
                                                                id='lastResetedDate'
                                                                name="lastResetedDate"
                                                                //type={!lastResetedDate?"text":"date"}
                                                                type={"text"}
                                                                readOnly disabled
                                                                value={!lastResetedDate ? "Nunca" : lastResetedDate}
                                                                //max={9999}
                                                                //min={0}
                                                                maxLength=""
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <button type="button" className={"simpleResetButton"} onClick={handleOpenDialog}>Zerar Manualmente Agora</button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {tela === "tela2" && (
                                            <div className="schedule-settings" style={{ minWidth: '50%' }}>
                                                <h3>2. Cancelar pedido</h3>

                                                <div className="radio-options" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                                                    <label style={{ marginBottom: '8px' }}>
                                                        <input
                                                            type="radio"
                                                            name="cancelOption"
                                                            value="noPassword"
                                                            checked={cancelOption === "noPassword"}
                                                            onChange={() => {
                                                                setCancelOption("noPassword");
                                                                //setHasCancelPassword(false);
                                                                //setShowInputNewPassword(false);
                                                            }}
                                                            style={{ verticalAlign: 'middle' }}
                                                        />
                                                        <span style={{ marginLeft: '8px', fontWeight: 600 }}>Não solicitar senha para cancelamento de pedidos</span>
                                                        <p style={{ marginLeft: '25px', color: '#666', fontSize: '13px' }}>Qualquer pessoa pode cancelar os pedidos</p>
                                                    </label>

                                                    <label style={{ marginBottom: '8px' }}>
                                                        <input
                                                            type="radio"
                                                            name="cancelOption"
                                                            value="standardPassword"
                                                            checked={cancelOption === "standardPassword"}
                                                            onChange={() => {
                                                                setCancelOption("standardPassword");
                                                                //setHasCancelPassword(true);
                                                            }}
                                                            style={{ verticalAlign: 'middle' }}
                                                        />
                                                        <span style={{ marginLeft: '8px', fontWeight: 600 }}>Solicitar senha padrão para cancelar pedidos</span>
                                                        <p style={{ marginLeft: '25px', color: '#666', fontSize: '13px' }}>Crie uma senha padrão e utilize sempre que precisar cancelar um pedido. Os pedidos só poderão ser cancelados com essa senha.</p>
                                                    </label>
                                                </div>

                                                {cancelOption === "standardPassword" && (
                                                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem', maxWidth: '50%' }} className="input-box">
                                                        {/* Exibir input de senha quando não há senha configurada ou quando `showInputNewPassword` está ativo */}
                                                        {!hasCancelPassword || showInputNewPassword ? (
                                                            <>
                                                                {/* Input para nova senha (configuração inicial ou atualização de senha) */}
                                                                {console.log("hasCancelPassword>>", hasCancelPassword)}
                                                                {definindoNovaSenha ? (
                                                                    <>
                                                                        <label style={{ fontWeight: 500 }}>Definir Senha<span style={{ color: "red" }}> *</span></label>
                                                                        <input
                                                                            type="password"
                                                                            id="cancelPassword"
                                                                            name="cancelPassword"
                                                                            className="input-box"
                                                                            value={cancelPassword}
                                                                            onChange={(e) => setCancelPassword(e.target.value)}
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {/* Caso seja troca de senha, exibe o campo para senha atual e nova senha */}
                                                                        <div className="input-box" style={{ marginBottom: '1rem' }}>
                                                                            <label style={{ fontWeight: 500 }}>Senha Atual<span style={{ color: "red" }}> *</span></label>
                                                                            <input
                                                                                type="password"
                                                                                id="currentCancelPassword"
                                                                                name="currentCancelPassword"
                                                                                value={currentCancelPassword}
                                                                                onChange={(e) => setCurrentCancelPassword(e.target.value)}
                                                                            />
                                                                        </div>

                                                                        <div className="input-box">
                                                                            <label style={{ fontWeight: 500 }}>Digite a Nova Senha<span style={{ color: "red" }}> *</span></label>
                                                                            <input
                                                                                type="password"
                                                                                id="newCancelPassword"
                                                                                name="newCancelPassword"
                                                                                value={newCancelPassword}
                                                                                onChange={(e) => setNewCancelPassword(e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </>
                                                        ) : (
                                                            // Exibir como placeholder quando já há uma senha configurada e troca não foi acionada
                                                            <div className="input-box">
                                                                <label style={{ fontWeight: 500 }}>Senha<span style={{ color: "red" }}> *</span></label>
                                                                <input
                                                                    type="password"
                                                                    id="cancelPassword"
                                                                    name="cancelPassword"
                                                                    className="input-box"
                                                                    value="******" // Mostrar "******" como placeholder
                                                                    disabled
                                                                />
                                                            </div>
                                                        )}

                                                        {/* Exibe botão de troca de senha se já há uma senha configurada */}
                                                        {hasCancelPassword && (
                                                            <button
                                                                type="button"
                                                                onClick={handleChangePassword}
                                                                style={{
                                                                    color: 'blue',
                                                                    textDecoration: 'underline',
                                                                    background: 'none',
                                                                    border: 'none',
                                                                    cursor: 'pointer',
                                                                    marginTop: '5px',
                                                                    padding: '0',
                                                                    display: 'flex'
                                                                }}
                                                            >
                                                                {!showInputNewPassword ? "Trocar senha" : "Cancelar troca de senha"}
                                                            </button>
                                                        )}
                                                    </div>
                                                )}


                                            </div>
                                        )}
                                    </div>

                                </div>



                            </div>

                        </form>
                    </div>
                </Teste>

            </PermissionGate>
        </>
    )
}

export default MeusPedidosCfg;