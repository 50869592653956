import React from 'react';
import loseSales from '../../img/loseSales.png';
import logoPedeJA from '../../img/logoPedeJA.png';
import { CgEnter } from 'react-icons/cg';

import iconPrint from '../../img/print.png';
import chart from '../../img/chart.png';
import support from  '../../img/support.png';
import location from '../../img/location.png';
import list from '../../img/list.png';
import iphone from '../../img/iphone.png';
import padlock from '../../img/padlock.png';
import watch from '../../img/watch.png';
import promotion from '../../img/promotion.png';
import pizza from '../../img/pizza.png';
import test from '../../img/test.png';
import barcode from '../../img/barcode.png';

const LandPage = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <header style={{ backgroundColor: 'transparent', padding: '16px', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img style={{width:140, height:42}} src={logoPedeJA}></img>
            </header>
            <main style={{ display: 'flex', gap: '32px', padding: '32px', alignItems: 'flex-start', justifyContent: "center" }}>
                <section style={{ flex: '0.3 1 0%' }}>
                    <h1 style={{ fontSize: '48px', marginBottom: '16px', color:'#003E63' }}><span style={{color:'rgb(22,171,57)' , textAlign:'center'}}>Automatize</span> seu atendimento e multiplique seu faturamento</h1>
                    <p>Mais de 15 mil donos de delivery já descobriram como oferecer um atendimento rápido, prático e lucrativo</p>
                    
                </section>
                <aside style={{ width: '400px', backgroundColor: 'white', padding: '16px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
                    <h5 style={{ marginBottom: '5px', color:'#003E63' }}>Teste grátis por 30 dias!</h5>
                    <p style={{ marginBottom: '5px', color:'#003E63', fontSize:14 }}>Faça seu cadastro para teste o PedeJá</p>
                    <form style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    <input type="text" placeholder="Seu nome" style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }} />
                    <input type="email" placeholder="Seu email" style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }} />
                    <input type="tel" placeholder="Celular" style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }} />
                    <input type="text" placeholder="Nome do seu delivery" style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }} />
                    <button type="submit" style={{ padding: '12px', backgroundColor: '#0098FC', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>QUERO VENDER MAIS!</button>
                    </form>
                </aside>
            </main>
            <div style={{ backgroundColor: '#D3EEFF', color: 'white', padding: '16px', textAlign: 'center', display:'flex', justifyContent:'center' }}>
                <img height={330} width={250} src={loseSales}></img>
                <div>
                    <p style={{textAlign:'left', color:'#003E63', fontSize:32, fontWeight:'600', width:'450px', paddingTop:50}}>Quantas vendas já perdeu por falta de atendimento?</p>
                    <p style={{color:'#003E63',width:'580px', height:'82px', fontSize:14, fontWeight:500, textAlign:'left'}}>A demora no atendimento via WhatsApp pode resultar em insatisfação dos clientes, perda de vendas, baixa eficiência operacional e prejuízo na imagem da marca do restaurante. Além disso, feedback negativo dos clientes pode comprometer ainda mais a reputação do estabelecimento.</p>
                    <button style={{ padding: '8px', backgroundColor: '#0098FC', color: 'white', border: '1px solid white', borderRadius: '4px', cursor: 'pointer', justifyContent:'flex-start', display:'flex' }}>
                        Testar grátis por 30 dias
                    </button>
                </div>
            </div>

            <main style={{ display: 'flex', gap: '32px', padding: '32px',justifyContent: "flex-start" , flexDirection:'column', height:"600px"}}>
                <div style={{ display:'flex', justifyContent:'center', alignItems:'center' }}>
                    <h1 style={{ fontSize: '48px', marginBottom: '16px', color:'#003E63' }}>Como funciona o PedeJá</h1>                   
                </div>
                <div style={{ width: '100%', backgroundColor: 'white', padding: '16px', borderRadius: '8px', justifyContent:'center', display:'flex' }}>
                    <div>VIDEO</div>                        
                </div>
            </main>

            <main style={{ display: 'flex', gap: '32px', padding: '32px',justifyContent: "flex-start" , flexDirection:'column', background:'rgb(211,238,255)'}}>
                <div className="feature-grid" style={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
                    <h4 style={{ textAlign: 'center', marginBottom: '40px', color:'#003E63' }}>Sistema Completo <span style={{fontWeight:'700'}}>Delivery e Retirada</span> com os seguintes recursos:</h4>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center' }}>
                    {features.map((feature, index) => (
                        <div key={index} style={{ width: '300px', textAlign: 'center', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', background:'white' }}>
                            <div>{feature.icon}</div>
                            <h5 style={{ marginBottom: '10px', marginTop:'10px' }}>{feature.title}</h5>
                            <p style={{fontSize:'15px'}}>{feature.description}</p>
                        </div>
                    ))}
                    </div>
                </div>
            </main>

        </div>
    );
};

const features = [
    {
      title: 'Impressão Automática de Pedidos',
      description: 'Imprima automaticamente seus pedidos seja na impressora do seu balcão ou da sua cozinha.',
      icon: <img src={iconPrint}></img>,
    },
    {
        title: 'Relatórios Completos',
        description: 'Informações importantes sobre o seu negócio como faturamento mensal, diário e pedidos.',
        icon: <img src={chart}></img>,
    },
    {
        title: 'Suporte VIP',
        description: 'Trabalhamos junto com você. Suporte VIP todos os dias 24h para ajudá-lo no que precisar.',
        icon: <img src={support}></img>,
    },
    {
        title: 'Taxas de Entrega',
        description: 'Gerencie de forma fácil suas taxas de entrega, podendo ser por bairros ou por distância.',
        icon: <img src={location}></img>,
    },
    {
        title: 'Editor de Cardápio',
        description: 'Facilmente inclua, modifique ou retire temporariamente seus produtos.',
        icon: <img src={list}></img>,
    },
    {
        title: 'Status do Pedido',
        description: 'Envio de mensagem direto do sistema, para que o cliente acompanhe o andamento do pedido pelo Whatsapp.',
        icon: <img src={iphone}></img>,
    },
    {
        title: 'Os dados dos seus clientes ficam salvos',
        description: 'O cliente preenche as informações uma única vez e o sistema salva todas as informações para os próximos pedidos.',
        icon: <img src={padlock}></img>,
    },
    {
        title: 'Horário de Funcionamento',
        description: 'Abertura e fechamento automática do cardápio.',
        icon: <img src={watch}></img>,
    },
    {
        title: 'Vários sabores em uma mesma pizza',
        description: 'O sistema permite fazer pizzas de 1,2,3 e até 4 sabores.',
        icon: <img src={pizza}></img>,
    },
    {
        title: 'Adicionais dos produtos',
        description: 'Aumente o ticket médio de venda, oferecendo complementos adicionais aos pratos.',
        icon: <img src={test}></img>,
    },
    {
        title: 'QrCode Cardápio Digital',
        description: 'Permite que os clientes visualizem o cardápio do seu estabelecimento de maneira rápida e simples.',
        icon: <img src={barcode}></img>,
    },
    {
        title: 'Promoções',
        description: 'Ative promoções em seu cardápio e venda ainda mais.',
        icon: <img src={promotion}></img>,
    },
    // ... Add all other features here with their respective title, description, and icon path
];

  
export default LandPage;