import React, { useState } from 'react';
import logoPedeJA from '../../img/logoPedeJA.png';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

const AddCredencial = () => {
    const location = useLocation();
    const { infoEmpresaObj, passwordObj, linkObj } = location.state || {};
    //console.log(infoEmpresaObj);

    const navigate = useNavigate();
    const [optSelected, setOptSelected] = useState('credential');

    const handleOptSelected = (opt) => {
       

        if(opt === "info") {
            setOptSelected(opt);
            navigate("/informacoes", {state: {infoEmpresaObj, passwordObj, linkObj}});
        }
        if(opt === "import") {
            if(!passwordObj) {
                toast('Por favor, preencha a senha', {autoClose:5000, type: 'warning'});
                return;
            }
            setOptSelected(opt);
            navigate("/integracao", {state: {infoEmpresaObj, passwordObj, linkObj}});
        }
    }

    //Validações
    const SignUpSchema = Yup.object().shape({
        password: Yup.string().min(6, 'Senha muito curta - mínimo 6 caracteres').required('Campo obrigatório'),
        confirmPassword: Yup.string().min(6, 'Senha muito curta - mínimo 6 caracteres').required('Campo obrigatório'),
    });

    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: true,
        //enableReinitialize: true,
        initialValues: {
          password: passwordObj?passwordObj.password:"",
          confirmPassword: passwordObj?passwordObj.confirmPassword:"",
        },
        onSubmit: (values) => {
          
          const password = values.password;
          const confirmPassword = values.confirmPassword;

          if(password !== confirmPassword) {
            toast('As senhas não conferem', {autoClose:5000, type: 'error'});
            return;
          }

          const passwordObj = {
            password: password,
            confirmPassword: confirmPassword
          }
          navigate("/integracao", {state: {infoEmpresaObj, passwordObj, linkObj}});
          
        },
    });

    const handleBack = () => {
        navigate("/informacoes", {state: {infoEmpresaObj, passwordObj, linkObj}});
    }

    return (    
        <div className='containerAddEmpresa'>
            <div className='divSidePanel'>
                <div className='divSidePanelContent'>
                    <div className='divLogo'>
                        <img width={140} height={42} src={logoPedeJA} alt='logoPedeJA' />
                    </div>
                    <div className='divMenu'>
                        <div className='divMenuItem'>          
                            <div style={{display:'flex', flexDirection:'row'}}>                 
                                {optSelected==="info"?<div className='menuItemSelected'/>:null}
                                <p className={optSelected==="info"?'btnOptionsAddEmpresa checked':"btnOptionsAddEmpresa"} onClick={() => handleOptSelected("info")}>1. Informações da Conta</p>
                            </div>
                            <div style={{display:'flex', flexDirection:'row'}}>
                                {optSelected==="credential"?<div className='menuItemSelected'/>:null}
                                <p className={optSelected==="credential"?'btnOptionsAddEmpresa checked':"btnOptionsAddEmpresa"} onClick={() => handleOptSelected("credential")}>2. Senha</p>
                            </div>
                            <div style={{display:'flex', flexDirection:'row'}}>
                                {optSelected==="import"?<div className='menuItemSelected'/>:null}
                                <p className={optSelected==="import"?'btnOptionsAddEmpresa checked':"btnOptionsAddEmpresa"} onClick={() => handleOptSelected("import")}>3. Importar Cardápio</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>      



            <div className="containerAddEmpresaForm">
                <form onSubmit={formik.handleSubmit} className="formAddEmpresa">

                    <div className='informationsAccount'>

                    <div style={styles.container}>
                        <h1 style={styles.header}>Defina sua senha.</h1>
                        <p style={styles.description}>
                        Para manter sua conta segura, defina uma senha de acesso ao nosso sistema.
                        </p>
                        <div style={styles.inputGroup}>
                        <label style={styles.label} htmlFor="password">Senha *</label>
                        <input style={styles.input} type="password"
                        id="password"
                        name="password"
                        onChange={formik.handleChange}
                        value={formik.values.password}placeholder="Digite sua senha" />
                            {formik.errors.password && (
                                <div className="error">{formik.errors.password}</div>
                            )}
                        </div>
                        <div style={styles.inputGroup}>
                        <label style={styles.label} htmlFor="confirmPassword">Confirmar senha *</label>
                        <input style={styles.input} type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        onChange={formik.handleChange}
                        value={formik.values.confirmPassword}
                        placeholder="Confirme sua senha" />
                            {formik.errors.confirmPassword && (
                                <div className="error">{formik.errors.confirmPassword}</div>
                            )}
                        </div>
                        <div style={styles.buttonGroup}>
                        <button type="button" onClick={()=>handleBack()} style={styles.button} className='btnBackCadastros'>Voltar</button>
                        <button type="submit" style={styles.button} className='btnFwdCadastros'>Próximo</button>
                        </div>
                    </div>
                
                    </div>
                    
                </form>
            </div>



        </div>     
    );
}   


const styles = {
    container: {
      //maxWidth: '400px',
      margin: '0 auto',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    header: {
      fontSize: '24px',
      marginBottom: '10px',
    },
    description: {
      fontSize: '16px',
      marginBottom: '20px',
    },
    inputGroup: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
    label: {
      fontSize: '16px',
      fontWeight: 'bold',
    },
    input: {
      padding: '10px',
      fontSize: '16px',
      border: '1px solid #ccc',
      borderRadius: '4px',
    },
    buttonGroup: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    button: {
      padding: '10px 20px',
      fontSize: '16px',
      borderRadius: '4px',
      cursor: 'pointer',
    }
};
  

export default AddCredencial;

