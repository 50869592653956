import React, { useEffect, useState, useContext } from "react";
import Loading from 'react-loading';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LeftMenu from "../../components/LeftMenu";
import pagamentoImg from '../../img/pagamento.png';
import './styles.css';
import moment from 'moment';
import { useFormik } from "formik";
import * as Yup from "yup";
//import creditCardType from 'credit-card-type';
import valid from 'card-validator'; //import statement
import InputMask from 'react-input-mask'
import axios from 'axios';
import { listarAssinaturasAsaas } from "../../services/api";
import { SidebarContext } from "../../AppRoutes";
import { RiErrorWarningLine } from "react-icons/ri";
import { MdPix } from "react-icons/md";
import { FaBarcode } from "react-icons/fa6";
import { IoIosCard } from "react-icons/io";
import { toast } from "react-toastify";


const Teste = styled.div`
  display: flex;
  margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
  height:auto;
  width:auto;
  transition: 150ms;
  background-color:rgb(247,247,247)!important;
  --background-color:white!important;
  overflow: initial;
  z-Index: 9;
`;


const PaymentMethods = () => {
    const navigate = useNavigate();
    const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES

    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const idEmpresa = empresaParse.id_empresa;
    const cnpj = empresaParse.cnpj;
    const razao = empresaParse.razao;
    const nomeEmpresa = empresaParse.name;

    const [subscriptionData, setSubscriptionData] = useState(null);
    const [paymentData, setPaymentData] = useState(null);
    const [payment_type, setPayment_type] = useState(null);
    const [person_type, setPerson_type] = useState("cnpj");
    const [isFinalizando, setIsFinalizando] = useState(false);
    const [currentDay, setCurrentDay] = useState(null);
    const [isChangingPaymentType, setIsChangingPaymentType] = useState(false);

    useEffect(() => {
        const listarAssinaturas = async (id) => {
            const assinaturas = await listarAssinaturasAsaas(id);
            console.log("Assinaturas>", assinaturas);
            setSubscriptionData(assinaturas.data.subscriptionData?.data[0]);
            setPaymentData(assinaturas.data.paymentData?.data[0]);
            if(assinaturas.status === 204){
                navigate('/planos');
            }
        }
        listarAssinaturas(empresaParse.customer_asaas.id);
    }, []);

    const handleFinalizarPagamentoPendente = () => {
        window.open(paymentData.invoiceUrl);
    }

    const handlePaymentType = (tipo_pagamento) => {
        if(tipo_pagamento==="credito"){
            setPayment_type("credito");            
        }else if(tipo_pagamento==="boleto"){
            setPayment_type("boleto");
        }else if(tipo_pagamento==="pix"){
            setPayment_type("pix");
        }
    }

    const handlePersonType = (tipo_pessoa) => {
        if(tipo_pessoa==="cnpj"){
            setPerson_type("cnpj");
        }else if(tipo_pessoa==="cpf"){
            setPerson_type("cpf");
        }                
    }

    const handleChangePaymentType = () => {
        setIsChangingPaymentType(true);
    }


    //Validações
    const SignUpSchema = Yup.object().shape({
        //tipo_pessoa: Yup.string().required("Campo obrigatório"),        
        cpf_cnpj: Yup.string().required("Campo obrigatório"),
        nomeOrRazao: Yup.string().required("Campo obrigatório"),
        email: Yup.string().email("Email inválido").required("Campo obrigatório"),
        cep: Yup.string().required("Campo obrigatório"),
        numero: Yup.string().required("Campo obrigatório"),
        telefone: Yup.string().required("Campo obrigatório"),

        num_cartao: Yup.string()
        .test('test-number', // this is used internally by yup
        'Cartão de crédito invalido', //validation message
        value => valid.number(value).isValid) // return true false based on validation
        .required("Campo obrigatório"),  
        
        validade: Yup.string().required("Campo obrigatório"),

        cvv: Yup.string()
        .matches(/^[0-9]{3,4}$/, 'CVV inválido') 
        .required("Campo obrigatório")
        
    });

    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        //enableReinitialize: true,
        initialValues: {
            tipo_pessoa: "",
            num_cartao: "",
            validade: "",
            cvv: "" ,
            cpf_cnpj: "",
            nomeOrRazao:"",
            email: "",
            cep: "",
            numero: "",
            telefone: "",
        },
        onSubmit: (values) => {
         
            //const tipo_pessoa = values.tipo_pessoa;
            const num_cartao = values.num_cartao;
            const validade = values.validade;
            const cvv = values.cvv;
            const cpf_cnpj = values.cpf_cnpj;
            const nomeOrRazao = values.nomeOrRazao;

            const email = values.email;
            const cep = values.cep;
            const numero = values.numero;
            const telefone = values.telefone;

            console.log("PASSOU AQUI")

            //handleSubmitAPI(num_cartao,validade,cvv,cpf_cnpj,nomeOrRazao,email,cep,numero,telefone);
          
        },
    });

    const handleChangePaymentApi = () => {
        console.log("Mudando forma de pagamento");
        setIsFinalizando(true);
    }


    return (
        <div>
            <LeftMenu setSidebar={setSidebar} sidebar={sidebar} />
            <Teste sidebar={sidebar}>
                <div className="m-5 planos">
                    <div className="input-group inputGroup-adicinaItem">

                        <h5>Forma de pagamento atual</h5>
                        <div className="content-info-plan" style={{ width: '100%' }}>
                            <h6>Forma de pagamento selecionada:</h6>

                            {paymentData?.billingType === 'CREDIT_CARD' && (
                                <div className="d-flex align-items-center">
                                    <IoIosCard style={{ fontSize: '30px', marginRight: '10px' }} />
                                    <span>Cartão de crédito</span>
                                </div>
                            )}
                            {paymentData?.billingType === 'BOLETO' && (
                                <div className="d-flex align-items-center">
                                    <FaBarcode style={{ fontSize: '30px', marginRight: '10px' }} />
                                    <span>Boleto</span>
                                </div>
                            )}
                            {paymentData?.billingType === 'PIX' && (
                                <div className="d-flex align-items-center">
                                    <MdPix style={{ fontSize: '30px', marginRight: '10px' }} />
                                    <span>Pix</span>
                                </div>
                            )}

                            <button className="changePaymentType" onClick={handleChangePaymentType}>
                                <span>ALTERAR FORMA DE PAGAMENTO</span>
                            </button>
                            {isChangingPaymentType && (
                                
                                <div className="payment-methods">                                    

                                    <div className="title">Escolha uma forma de pagamento:</div>
                                    <div className="methods">
                                        <div className="method credit-card" onClick={() => handlePaymentType("credito")}
                                            style={{
                                                background: payment_type === "credito" ? "aliceblue" : "",
                                                border: payment_type === "credito" ? "1px solid #007bff" : ""
                                            }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                                <input type="radio" name="payment_type" value="credit-card" checked={payment_type === "credito"} className="inputBoxRadioPersonalized" readOnly />
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <span>Cartão de crédito</span>                                             
                                                </div>
                                            </div>
                                            <div><IoIosCard style={{ fontSize: 30 }} /></div>
                                        </div>
                                        <div className="method boleto" onClick={() => handlePaymentType("boleto")}
                                            style={{
                                                background: payment_type === "boleto" ? "aliceblue" : "",
                                                border: payment_type === "boleto" ? "1px solid #007bff" : ""
                                            }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                                <input type="radio" name="payment_type" value="credit-card" checked={payment_type === "boleto"} className="inputBoxRadioPersonalized" readOnly />
                                                <span>Boleto</span>
                                            </div>
                                            <div><FaBarcode style={{ fontSize: 30 }} /></div>
                                        </div>
                                        <div className="method pix" onClick={() => handlePaymentType("pix")}
                                            style={{
                                                background: payment_type === "pix" ? "aliceblue" : "",
                                                border: payment_type === "pix" ? "1px solid #007bff" : ""
                                            }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                                <input type="radio" name="payment_type" value="credit-card" checked={payment_type === "pix"} className="inputBoxRadioPersonalized" readOnly />
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <span>Pix</span>
                                                </div>
                                            </div>
                                            <div><MdPix style={{ fontSize: 30 }} /></div>
                                        </div>
                                    </div>


                                    {
                                        payment_type === "credito" ?
                                            <div className="credit-card-form">
                                                <form onSubmit={formik.handleSubmit}>
                                                    <div className="input-box">
                                                        <label>Escolha o tipo de pessoa:</label>

                                                        <div className="typePersonDiv">
                                                            <div style={{ display: 'flex', gap: 5 }}>
                                                                <input type="radio" name="person_type" value="CNPJ" checked={person_type === "cnpj"} className="inputBoxRadioPersonalized" readOnly
                                                                    onClick={() => handlePersonType("cnpj")} />
                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    <span style={{ fontSize: 12, fontWeight: 600 }}>CNPJ</span>
                                                                    <span style={{ fontSize: 12 }}>Pessoa Jurídica</span>
                                                                </div>
                                                            </div>
                                                            <div style={{ display: 'flex', gap: 5 }}>
                                                                <input type="radio" name="person_type" value="CPF" checked={person_type === "cpf"} className="inputBoxRadioPersonalized" readOnly
                                                                    onClick={() => handlePersonType("cpf")} />
                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    <span style={{ fontSize: 12, fontWeight: 600 }}>CPF</span>
                                                                    <span style={{ fontSize: 12 }}>Pessoa Física</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ justifyContent: "space-between", display: 'flex' }}>
                                                        <div className="input-box planosFormInputs" style={{ width: "60%" }}>
                                                            <label>Número do cartão<span style={{ color: "red" }}>*</span></label>
                                                            <InputMask
                                                                placeholder="_ _ _ _  _ _ _ _  _ _ _ _  _ _ _ _"
                                                                id="num_cartao"
                                                                type="num_cartao"
                                                                name="num_cartao"
                                                                mask="9999 9999 9999 9999"
                                                                onChange={formik.handleChange}
                                                                value={formik.values.num_cartao}
                                                            />
                                                            {formik.errors.num_cartao && (
                                                                <div className="error">{formik.errors.num_cartao}</div>
                                                            )}
                                                        </div>

                                                        <div className="input-box planosFormInputs" style={{ width: "15%" }}>
                                                            <label>Validade<span style={{ color: "red" }}>*</span></label>
                                                            <InputMask
                                                                placeholder="_ _ / _ _ _ _"
                                                                mask="99/9999"
                                                                id="validade"
                                                                type="validade"
                                                                name="validade"
                                                                onChange={formik.handleChange}
                                                                value={formik.values.validade}
                                                            />
                                                            {formik.errors.validade && (
                                                                <div className="error">{formik.errors.validade}</div>
                                                            )}
                                                        </div>

                                                        <div className="input-box planosFormInputs" style={{ width: "15%" }}>
                                                            <label>CVV<span style={{ color: "red" }}>*</span></label>
                                                            <InputMask
                                                                placeholder="_ _ _"
                                                                mask="999"
                                                                id="cvv"
                                                                type="cvv"
                                                                name="cvv"
                                                                onChange={formik.handleChange}
                                                                value={formik.values.cvv}
                                                            />
                                                            {formik.errors.cvv && (
                                                                <div className="error">{formik.errors.cvv}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div style={{ justifyContent: "space-between", display: 'flex' }}>

                                                        <div className="input-box planosFormInputs" style={{ width: "42%" }}>
                                                            <label>{person_type === "cnpj" ? "CNPJ" : "CPF"}<span style={{ color: "red" }}>*</span></label>
                                                            <InputMask
                                                                placeholder={person_type === "cnpj" ? "Digite seu CNPJ" : "Digite seu CPF"}
                                                                id="cpf_cnpj"
                                                                type="cpf_cnpj"
                                                                name="cpf_cnpj"
                                                                mask={person_type === "cnpj" ? "99.999.999/9999-99" : "999.999.999-99"}
                                                                onChange={formik.handleChange}
                                                                value={formik.values.cpf_cnpj}
                                                            />
                                                            {formik.errors.cpf_cnpj && (
                                                                <div className="error">{formik.errors.cpf_cnpj}</div>
                                                            )}
                                                        </div>
                                                        <div className="input-box planosFormInputs" style={{ width: "42%" }}>
                                                            <label>{person_type === "cnpj" ? "Razão Social" : "Nome (conforme escrito no cartão)"}<span style={{ color: "red" }}>*</span></label>
                                                            <input
                                                                placeholder={person_type === "cnpj" ? "Escreva aqui sua Razão Social" : "Escreva aqui seu Nome"}
                                                                id="nomeOrRazao"
                                                                type="nomeOrRazao"
                                                                name="nomeOrRazao"
                                                                onChange={formik.handleChange}
                                                                value={formik.values.nomeOrRazao}
                                                            />
                                                            {formik.errors.nomeOrRazao && (
                                                                <div className="error">{formik.errors.nomeOrRazao}</div>
                                                            )}
                                                        </div>
                                                    
                                                    </div>

                                                    <div style={{ justifyContent: "space-between", display: 'flex' }}>

                                                        <div className="input-box planosFormInputs" style={{ width: "40%" }}>
                                                            <label>Email<span style={{ color: "red" }}>*</span></label>
                                                            <InputMask
                                                                placeholder="Digite seu Email"
                                                                id="email"
                                                                type="email"
                                                                name="email"
                                                                //mask="99.999.999/9999-99"
                                                                onChange={formik.handleChange}
                                                                value={formik.values.email}
                                                            />
                                                            {formik.errors.email && (
                                                                <div className="error">{formik.errors.email}</div>
                                                            )}
                                                        </div>
                                                        <div className="input-box planosFormInputs" style={{ width: "25%" }}>
                                                            <label>CEP<span style={{ color: "red" }}>*</span></label>
                                                            <InputMask
                                                                placeholder="Digite seu CEP"
                                                                id="cep"
                                                                type="cep"
                                                                name="cep"
                                                                mask={"99999-999"}
                                                                onChange={formik.handleChange}
                                                                value={formik.values.cep}
                                                            />
                                                            {formik.errors.cep && (
                                                                <div className="error">{formik.errors.cep}</div>
                                                            )}
                                                        </div>

                                                        <div className="input-box planosFormInputs" style={{ width: "25%" }}>
                                                            <label>Número<span style={{ color: "red" }}>*</span></label>
                                                            <input
                                                                placeholder="Digite o número do endereço"
                                                                id="numero"
                                                                type="numero"
                                                                name="numero"
                                                                onChange={formik.handleChange}
                                                                value={formik.values.numero}
                                                            />
                                                            {formik.errors.numero && (
                                                                <div className="error">{formik.errors.numero}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div style={{ justifyContent: "space-between", display: 'flex' }}>

                                                        <div className="input-box planosFormInputs" style={{ width: "45%" }}>
                                                            <label>Telefone<span style={{ color: "red" }}>*</span></label>
                                                            <InputMask
                                                                placeholder="(62) 9 9999-9999"
                                                                id="telefone"
                                                                type="telefone"
                                                                name="telefone"
                                                                mask={"(99) 9 9999-9999"}
                                                                onChange={formik.handleChange}
                                                                value={formik.values.telefone}
                                                            />
                                                            {formik.errors.telefone && (
                                                                <div className="error">{formik.errors.telefone}</div>
                                                            )}
                                                        </div>

                                                        <div className="input-box planosFormInputs" style={{ width: "45%" }}>
                                                            <label>Cupom de desconto</label>
                                                            <input
                                                                placeholder="Ex: CUPOM10HOJE"
                                                                id="cupom"
                                                                type="cupom"
                                                                name="cupom"
                                                                onChange={formik.handleChange}
                                                                value={formik.values.cupom}
                                                            />
                                                            {formik.errors.cupom && (
                                                                <div className="error">{formik.errors.cupom}</div>
                                                            )}
                                                        </div>


                                                    </div>

                                                    <div className="input-box planosFormInputs" style={{ width: "100%", paddingTop: "28px", marginBottom: "0px" }}>
                                                        {
                                                            isFinalizando ?
                                                                <button className="buttonFinalizarPagamento" readOnly={isFinalizando ? true : false} disabled={isFinalizando ? true : false} style={{ cursor: isFinalizando ? 'not-allowed' : '' }}>
                                                                    <Loading type='spin' color='white' height={24} width={24} />
                                                                </button>
                                                                :
                                                                <button type="submit" className="buttonFinalizarPagamento">
                                                                    <span style={{ color: 'white', fontWeight: '700', fontSize: 20 }}>FINALIZAR PAGAMENTO</span>
                                                                </button>
                                                        }
                                                    </div>

                                                    <div className="input-box planosFormInputs" style={{ width: "98%", paddingTop: "28px", marginBottom: "0px" }}>
                                                        <div className="info" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <p style={{ background: "rgb(217,255,230)", justifyContent: "space-between", display: "flex" }}>
                                                                <span>Cupom aplicado com sucesso!</span>
                                                            </p>
                                                            <span style={{ fontSize: 20, fontWeight: '700' }}>Total a pagar: R$ 150,00</span>

                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            :
                                            payment_type === "boleto" ?
                                                <div className="boleto-form">

                                                    <div className="input-box planosFormInputs" style={{ width: "100%", paddingTop: "28px", marginBottom: "0px" }}>
                                                        {
                                                            isFinalizando ?
                                                                <button className="buttonFinalizarPagamento" readOnly={isFinalizando ? true : false} disabled={isFinalizando ? true : false} style={{ cursor: isFinalizando ? 'not-allowed' : '' }}>
                                                                    <Loading type='spin' color='white' height={24} width={24} />
                                                                </button>
                                                                :
                                                                <button type="submit" className="buttonFinalizarPagamento" onClick={() => handleChangePaymentApi()}>
                                                                    <span style={{ color: 'white', fontWeight: '700', fontSize: 20 }}>FINALIZAR PAGAMENTO</span>
                                                                </button>
                                                        }
                                                    </div>

                                                </div>
                                                :
                                                payment_type === "pix" ?
                                                    <div className="pix-form">
                                                        <div className="input-box planosFormInputs" style={{ width: "100%", paddingTop: "28px", marginBottom: "0px" }}>
                                                            {
                                                                isFinalizando ?
                                                                    <button className="buttonFinalizarPagamento" readOnly={isFinalizando ? true : false} disabled={isFinalizando ? true : false} style={{ cursor: isFinalizando ? 'not-allowed' : '' }}>
                                                                        <Loading type='spin' color='white' height={24} width={24} />
                                                                    </button>
                                                                    :
                                                                    <button type="submit" className="buttonFinalizarPagamento" onClick={() => handleChangePaymentApi()}>
                                                                        <span style={{ color: 'white', fontWeight: '700', fontSize: 20 }}>FINALIZAR PAGAMENTO</span>
                                                                    </button>
                                                            }
                                                        </div>
                                                    </div>
                                                    : ""
                                    }
                                </div>

                            )}

                            {paymentData?.status === "PENDING" && !isChangingPaymentType || paymentData?.status === "OVERDUE" && !isChangingPaymentType ? 
                                <>
                                    <h6>Pagamento pendente:</h6>
                                    <div className="pendingPayment">
                                        <RiErrorWarningLine style={{ fontSize: '30px', color: 'rgb(255, 153, 0)' }} />
                                        <span>Você possui um pagamento pendente, clique no botão abaixo para finalizar o pagamento</span>
                                        <button type="button" onClick={handleFinalizarPagamentoPendente} className="buttonFinalizarPagamento" style={{ width: '100%' }}>
                                            <span style={{ color: 'white', fontWeight: '700', fontSize: 20 }}>FINALIZAR PAGAMENTO</span>
                                        </button>
                                    </div>
                                </>
                                : null
                            }

                        </div>

                    </div>
                </div>
            </Teste>
        </div>
    );
}

export default PaymentMethods; 