import styled from "styled-components";
import React, {useState} from 'react'
import './style.css';
import InputMask from 'react-input-mask';
import { toast } from "react-toastify";

const DivPedidoContainer = styled.div`
  z-index:15;
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ showModalCpfCnpj }) => (showModalCpfCnpj ? 'none' :'flex' )};
  float: left;
  min-width: 100%;
  min-height: 100%;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 5px 0;
  list-style: none;
  background-color: rgba(0,0,0,0.5);
  background-clip: padding-box;
  align-items:center;
  justify-content:center;
  opacity: ${({ showModalCpfCnpj }) => (showModalCpfCnpj ? '0' :'1' )};
  transition: opacity .3s ease-in-out;

  // Add keyframes for the fade-in effect
  animation: fadeIn .3s ease-in-out;
  animation-fill-mode: forwards;

  // Define the keyframes for the animation
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const DivPedido = styled.div`
    z-index:20;
    position: absolute;
    top: 15%;
    left: 30%;
    float: left;
    min-width: 40%;
    min-height: 15%;
    max-width: 40%;
    padding: 5px 0;
    list-style: none;
    --background-color: white;
    background-color:rgb(247,247,247)!important;
    background-clip: padding-box;
    border-radius: 15px;
    padding:20px;

`;

const ModalCpfCnpj = ({
    showModalCpfCnpj,
    setShowModalCpfCnpj,
    cpfCnpjValue,
    setCpfCnpjValue
}) => {    

    const closeModalCpfCnpj = () => {
        setShowModalCpfCnpj(!showModalCpfCnpj);
    }


    const saveModalCpfCnpj = () => {
        if (cpfCnpjValue.length < 14) {
            toast.error('CPF/CNPJ incompleto!');
            return;
        }
        if (cpfCnpjValue.length > 14 && cpfCnpjValue.length < 18) {
            toast.error('CPF/CNPJ incompleto!');
            return;
        }
        closeModalCpfCnpj();
    }

    const dynamicMask = () => {
        if (!cpfCnpjValue || cpfCnpjValue.length === 0) 
            return "999999999999999999";
    
        if (cpfCnpjValue.replace(/\D/g, "").length < 12) {
            return "999.999.999-999999";
         } else {
            return "99.999.999/9999-99";
        }
    }

    return (
        <DivPedidoContainer showModalCpfCnpj={showModalCpfCnpj}>
            <DivPedido>     
                <div className="divModalEntregaPDV" style={{minHeight:130}}> 
                    <div className="divTitleAndContentModalEntregaPDV">         
                        <div className="closeModalPedido" onClick={() => closeModalCpfCnpj()}>
                            <div
                                iconname="x"
                                iconcolor="#2B2B2B"
                                iconsize={18}
                                className="iconCancel"
                                style={{ height: 18, display: 'flex' }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={18}
                                    height={18}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#2B2B2B"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-x"
                                >
                                    <line x1={18} y1={6} x2={6} y2={18} />
                                    <line x1={6} y1={6} x2={18} y2={18} />
                                </svg>
                            </div>
                        </div>

                        <h4>CPF/CNPJ</h4>

                        <div style={{ marginBottom: '20px' }}>
                            <label htmlFor="cpf_cnpj" style={{ display: 'block', marginBottom: '5px' }}>Digite seu CPF ou CNPJ:</label>
                            <InputMask //mask={maskCpf_Cnpj} 
                            autoComplete="off"
                            mask={dynamicMask()} 
                            name='cpf_cnpj' 
                            onChange={(e) => {
                                setCpfCnpjValue(e.currentTarget.value);
                            }} 
                            value={cpfCnpjValue}
                            maskChar={''}
                            type="cpf_cnpj" id="cpf_cnpj" placeholder="Digite aqui"
                            style={{ width: '100%', padding: '10px', marginBottom: '10px', border: '1px solid #ccc', borderRadius: '4px' }} 
                            />
                        </div>
                        <button type="submit" onClick={() => saveModalCpfCnpj()}
                        style={{ padding: '10px 20px', border: 'none', borderRadius: '4px', backgroundColor: 'rgb(49, 140, 213)', color: 'white', width:'100%' }}>
                            Salvar
                        </button>

                    </div>
                    
                </div>
            </DivPedido>
        </DivPedidoContainer>
    )

}

export default ModalCpfCnpj;