import CurrencyInput from "../Form/CurrencyInput";
import { Modal } from "../Modal";
import { FaCashRegister } from "react-icons/fa6";
import { Formik, Form } from "formik";

import * as Yup from "yup";
import "./styles.css";
import { formatPrice } from "../../utils/formatPrice";
import { useEffect, useState } from "react";
import Loading from "react-loading";

import { toast } from "react-toastify";

const getInitialValues = (initialPrice) => ({
  amount: `R$ ${formatPrice(initialPrice)}`,
});

const validationSchema = Yup.object({
  amount: Yup.string().required("Obrigatório informar um valor inicial"),
});

export function ModalOpenCaixa({
  isOpen,
  onClose,
  initialPrice,
  handleOpen,
  refetch,
}) {
  const [initialValues, setInitialValues] = useState(
    getInitialValues(initialPrice)
  );

  const [isSubmiting, setIsSubmiting] = useState(false);

  useEffect(() => {
    setInitialValues(getInitialValues(initialPrice));
  }, [initialPrice]);

  const handleSubmit = (values) => {
    try {
      setIsSubmiting(true);
      handleOpen(values);
      onClose();
      toast("Caixa aberto com sucesso.", { type: "success" });
      refetch();
    } catch (e) {
      if (e.response?.data?.msg) {
        toast(e.response.data.msg, { type: "error" });
      } else {
        toast("Erro ao abrir carrinho", { type: "error" });
      }
    } finally {
      setIsSubmiting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="modal-open-content">
        <h3 className="title-open">Abertura de caixa</h3>
        <FaCashRegister style={{ fontSize: "3rem" }} />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {() => (
            <Form>
              <div className="input-box">
                <label htmlFor="amount">
                  Informe o valor de abertura do caixa
                </label>
                <CurrencyInput
                  label="amount"
                  name="amount"
                  type="text"
                  placeholder="R$ 0,00"
                />
              </div>
              <div className="modal-buttons">
                <button
                  className="button-default cancel-button"
                  type="button"
                  onClick={onClose}
                >
                  Cancelar
                </button>
                <button
                  className="button-default confirm-button"
                  type="submit"
                  disabled={isSubmiting}
                >
                  {isSubmiting ? (
                    <>
                      <Loading
                        type="spin"
                        color="white"
                        height={20}
                        width={20}
                      />
                      ...Enviando
                    </>
                  ) : (
                    "Confirmar"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
