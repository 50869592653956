import { useQuery } from "react-query";
import { getEmpresaWithObjId } from "../../services/api";

const useGetEmpresa = (empresaId) => {
  return useQuery(["getEmpresaWithObjId", empresaId], () =>
    getEmpresaWithObjId(empresaId)
  ); // Save Itens in cache
};

export default useGetEmpresa;
