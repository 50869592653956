import React, { useState, useContext, useEffect } from "react";
import './style.css'
import styled from 'styled-components';
import InputMask from 'react-input-mask'
import cep from 'cep-promise'
import CryptoJS from 'crypto-js';
import { AuthContext } from "../../contexts/auth";
import InputMoney from "../ListItem/InputMoney";
import { SidebarContext } from "../../AppRoutes";
import { registerGrupoAdicionais } from "../../services/api";

import PermissionGate from "../../services/PermissionGate";
import { useNavigate } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu"; //TESTE

import { useFormik } from "formik";
import * as Yup from "yup";

import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import { BsPlusCircle } from "react-icons/bs";
import { FaTrash } from 'react-icons/fa'

import { toast } from "react-toastify";

const Teste = styled.div`

    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;
`;


const AddAdicional = () =>{
    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user')
    const user = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    const userParse = JSON.parse(user)
    const userID = userParse._id;
    var userImg = null;
    const navigate = useNavigate();
    //console.log(empresa)
    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const idEmpresa = empresaParse.id_empresa;

    const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES

    const [mandatory, setMandatory] = useState(false);
    const [minimo, setMinimo] = useState(mandatory?1:0);
    const [maximo, setMaximo] = useState(1);
    const [valuePreco, setValuePreco] = useState(0);
    const [calcularMaiorValor, setCalcularMaiorValor] = useState(false);
    const handleMinimoChange = (event) => {
        const value = Math.min(Math.max(0, event.target.valueAsNumber), maximo);
        setMinimo(value);
    };

    const handleMaximoChange = (event) => {
        const value = Math.max(minimo, event.target.valueAsNumber);
        setMaximo(value);
    };

    const incrementMinimo = () => {
        setMinimo((prevMinimo) => (prevMinimo < maximo ? prevMinimo + 1 : prevMinimo));
    };

    const decrementMinimo = () => {
        setMinimo((prevMinimo) => (prevMinimo > 0 ? prevMinimo - 1 : prevMinimo));
    };

    const incrementMaximo = () => {
        setMaximo((prevMaximo) => prevMaximo + 1);
    };

    const decrementMaximo = () => {
        setMaximo((prevMaximo) => (prevMaximo > minimo ? prevMaximo - 1 : prevMaximo));
    };

    useEffect(() => {
        // Ajusta minimo quando mandatory muda
        if (mandatory && minimo === 0) {
          setMinimo(1);
        } else if (!mandatory && minimo !== 0) {
          setMinimo(0);
        }
    }, [mandatory]);

    useEffect(() => {
        // Ajusta mandatory quando minimo muda
        setMandatory(minimo > 0);
      }, [minimo]);

    //Validações
    const SignUpSchema = Yup.object().shape({
        title: Yup.string().required("Obrigatório"),
        //description: Yup.string().required("Obrigatório"),
    });

    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        initialValues: {
          title: "",
          //description: "",
        },
        onSubmit: (values) => {
          //alert(JSON.stringify(values, null, 2));
          console.log(values);
          
          const title = values.title;
          //const description = values.description;
          //const preco = values.preco;

          handleSubmitAPI(title);
          
        },
    });

    const onChange = (event, nameObject, isNumber) => {
        setValuePreco(isNumber ? Number(event.target.value) : event.target.value);
        formik.setFieldValue("preco", isNumber ? Number(event.target.value) : event.target.value );
    };

    const [isFocused, setIsFocused] = useState(false);
    const handleFocus = () => {
        setIsFocused(true);
    };
    
    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleBack = () => {
        //setSidebar(!sidebar)
        navigate("/list-adicional");
    }


    const handleSubmitAPI = (title) =>{
        //e.preventDefault();
        
        const createdBy = userID;
        const out = false;
        //Registrando a empresa
        console.log("submit adicional!",{createdBy, idEmpresa, title, minimo, maximo, mandatory, out, calcularMaiorValor});
        registerGrupoAdicionais(createdBy, idEmpresa, title, minimo, maximo, mandatory, out, calcularMaiorValor).then(cast => {
            console.log("CRIOU ADICIONAL:::",cast)
            if(cast.status===201){
                toast(cast.data.msg, {autoClose: 5000, type: "success"});
                navigate("/list-adicional");
            }
            else {
                toast(cast.data.msg, {autoClose: 5000, type: "error"});
            }
            
        }).catch(err => {
            console.log('Oh noooo!!');
            const msg = err.response.data.msg;
            //console.log("MENSAGEM", msg)
            toast(msg, {autoClose: 5000, type: "error"});
            
        });
        
    }


    return(

        <>
        <PermissionGate permissions={['default']}>
    

            <LeftMenu setSidebar={setSidebar} sidebar={sidebar}/>

            <Teste sidebar={sidebar}>
                <div className="m-5">
                    {/*<form onSubmit={handleSubmitButton}>*/}
                    <form onSubmit={formik.handleSubmit} style={{marginTop:"-30px"}}>
                    
                        <div className="form-header" style={{marginBottom:"0px"}}>
                            <div className="title">
                                <h1>Cadastrar Grupo de Adicionais</h1>
                            </div>
                        </div>

                        <div style={{display:"flex", justifyContent:"right"/*, height:"80px"*/}}>

                            <div className="div-buttons">
                                <div className="continue-button">                           
                                    <button type="submit">
                                        <AiIcons.AiOutlineSave style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Salvar</a> 
                                    </button>                            
                                </div>

                                <div className="back-button">                                                       
                                    <button onClick={handleBack}>
                                        <SlIcons.SlActionUndo style={{color:"#ff4c4c",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a >Voltar</a> 
                                    </button>
                                </div>
                            </div>

                        </div>
                    
                        <div className="input-group inputGroup-Adicionais">

                            <div className="containerBox-adicionais">

                                <div className="row-adicionais-1">
                                    <div className="input-box" style={{width: "90%", transition:sidebar?"200ms":"100ms"}}>
                                        <label>Nome do Grupo<span style={{color:"red"}}>*</span></label>
                                        <input 
                                        placeholder="Digite o nome do grupo de adicionais" 
                                        id="title"
                                        type="title"
                                        name="title"
                                        onChange={formik.handleChange}
                                        value={formik.values.title}
                                        />
                                        {formik.errors.title && (
                                            <div className="error">{formik.errors.title}</div>
                                        )}
                                    </div>
                                    
                                    {/* 
                                    <div className="input-box" style={{width: "48%", transition:sidebar?"200ms":"100ms"}}>
                                        <label>Descrição<span style={{color:"red"}}>*</span></label>
                                        <input 
                                        placeholder="Ex: Adicional de queijo, Adicional de bacon, etc." 
                                        id="description"
                                        type="description"
                                        name="description"
                                        onChange={formik.handleChange}
                                        value={formik.values.description}
                                        />
                                        {formik.errors.description && (
                                            <div className="error">{formik.errors.description}</div>
                                        )}
                                    </div>
                                    */}
                                </div>

                                <div className="row-adicionais-2">
                                    <div className="input-box" style={{width: "30%", transition:sidebar?"200ms":"100ms"}}>
                                        <label style={{color:'gray', paddingBottom:8}}>Quantidade de adicionais que o cliente pode escolher?</label>
                                        <div className="quantity-control">
                                            <div className="quantity-control-individual">
                                                <label>Mínimo</label>
                                                <div className="input-groupCadastroAdicionais">                                        
                                                    <button type="button" className="btnDecrement_" onClick={decrementMinimo}>-</button>
                                                    <input type="number" value={minimo} onChange={handleMinimoChange} />
                                                    <button type="button" className="btnIncrement_" onClick={incrementMinimo}>+</button>
                                                </div>
                                            </div>
                                            <div className="quantity-control-individual">
                                                <label>Máximo</label>
                                                <div className="input-groupCadastroAdicionais">                                       
                                                    <button type="button" className="btnDecrement_" onClick={decrementMaximo}>-</button>
                                                    <input type="number" value={maximo} onChange={handleMaximoChange} />
                                                    <button type="button" className="btnIncrement_" onClick={incrementMaximo}>+</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="radio-button-groupAdicionais" style={{width: "30%", transition:sidebar?"200ms":"100ms"}}>
                                        <div className="radio-optionAdicionais">
                                            <input
                                            id="opcional"
                                            type="radio"
                                            name="mandatory"
                                            value="opcional"
                                            checked={!mandatory}
                                            onChange={() => setMandatory(false)}
                                            />
                                            <div className="labelOptAdicionais">
                                                <label htmlFor="opcional">Opcional</label>
                                                <span>Seu cliente pode optar por não escolher adicionais</span>
                                            </div>
                                        </div>
                                        <div className="radio-optionAdicionais">
                                            <input
                                            id="obrigatorio"
                                            type="radio"
                                            name="mandatory"
                                            value="obrigatorio"
                                            checked={mandatory}
                                            onChange={() => setMandatory(true)}
                                            />
                                            <div className="labelOptAdicionais">
                                                <label htmlFor="obrigatorio">Obrigatório</label>
                                                <span>Seu cliente precisa escolher adicionais</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="input-box" style={{width:"30%"}}>
                                        <label>Calcular maior valor entre os Adicionais?</label>
                                        <span style={{fontSize:11}}>O preço calculado sempre será do adicional com maior valor</span>
                                        {/*<!-- Custom select structure --> */}
                                        <div className="selectdiv">
                                            <label style={{width:"100%"}}>
                                                <select 
                                                id="calcular_maior_valor"
                                                type="text"
                                                name="calcular_maior_valor"
                                                onChange={(e) => setCalcularMaiorValor(e.target.value === "true")}
                                                value={calcularMaiorValor}
                                                className={calcularMaiorValor === ""?'invalid-option':'beleza'}
                                                style={{width:"100%"}}
                                                >
                                                    <option value={false} style={{color:"black"}}>
                                                        Não
                                                    </option>                                                    
                                                    <option value={true} style={{color:"black"}}>
                                                        Sim
                                                    </option>
                                                    
                                                    
                                                    
                                                </select>                                      
                                            </label>
                                        </div>
                                        {/*<!-- Custom select structure --> */}
                                    </div>

                                    {/* 
                                    <div className="input-box precoItemCustom" style={{width: "23%", transition:sidebar?"200ms":"100ms"}}>
                                        <label>Preço</label>  
                                        <InputMoney
                                            onChange={(event) => onChange(event, 'price', true)}
                                            value={valuePreco}
                                            onFocus={handleFocus}
                                            onBlur={handleBlur}
                                            margin="0px 0px 16px 0px"
                                            style={{
                                            outline: isFocused ? '2px solid #4070f4' : 'none',
                                            borderRadius:10,
                                            margin: '0.6rem 0 0.2rem 0',
                                            }}
                                            className=""
                                            placeholder="Preço"
                                        />                                
                                        {formik.errors.preco && (
                                            <div className="error">{formik.errors.preco}</div>
                                        )}
                                    </div>
                                    */}
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </Teste>
                
        </PermissionGate>       
        </>
        

        );
    };
    
    export default AddAdicional;