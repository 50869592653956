import React, { useState, useContext, useEffect } from "react";
import Loading from 'react-loading';
import { Br, Cut, Line, Printer, Text, Row, render } from 'react-thermal-printer';
import './style.css'
import './styleResponsive.css'
import styled from 'styled-components';
import axios from "axios";
import { toast } from "react-toastify";
import pdfMake from "pdfmake/build/pdfmake";
import html2canvas from 'html2canvas';
//import html2pdf from 'html2pdf.js';
import CryptoJS from 'crypto-js';
import imageUrl from '../../img/logoBlue.png'
import io from 'socket.io-client';
import { SidebarContext } from "../../AppRoutes";
import { AuthContext } from "../../contexts/auth";

import { getUser, getVinculoEmpresa, getPedido, updateStatusPrint, updateStatusPedido, updateStatusPedidoFinalizado, getPedidosByStatus } from "../../services/api";

import PermissionGate from "../../services/PermissionGate";

import { useNavigate } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu";
import { GiFullMotorcycleHelmet } from "react-icons/gi";
import { MdStorefront } from "react-icons/md";

import { useFormik } from "formik";
import * as Yup from "yup";
import moment from 'moment'
import momentTz from 'moment-timezone';
import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import * as FiIcons from 'react-icons/fi'
import { BsFillGearFill } from "react-icons/bs";
import { FaMotorcycle, FaRegClock, FaStore } from "react-icons/fa";
import { IoMdPin } from "react-icons/io";

import ModalEditTempoEntrega from "../../components/ModalEditTempoEntrega";
import ModalPedido from "../../components/ModalPedido";
import { setRef } from "@material-ui/core";
import ModalAddEntregador from "./ModalAddEntregador";

const Teste = styled.div`

    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    --background-color:white!important;
    overflow: initial;
    z-Index: 9;
`;

const Ordercolumn = styled.div`
    min-width: 25%;
    max-width: 25%;    
`;

const MenuAjuda = styled.div`
    position: absolute;
    top: 90px;
    right: 0px;
    background: white;
    height: 60px;
    width: max-content;
    border-radius: 10px;
    box-shadow: 1px 1px 5px 2px lightgray;
    display: inline-grid;
    justify-content: center;
    align-items: center;
    justify-items: center
`;



const ModalUserOptions = styled.div`
  font-size: 14px;
  position: absolute;
  top: 82px;
  left: 150px;
  display: ${({ showOptions }) => (showOptions ? 'none' : '')};
  float: left;
  min-width: 160px;
  margin: 2px 0 0;
  padding: 5px 0;
  list-style: none;
  text-align: left;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 4px;
  background-color: #fff;
  --background-color:rgb(247,247,247)!important;
  background-clip: padding-box;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
  z-index: 25;

  li{
    font-weight: 400;
    line-height: 1.42857143;
    display: block;
    clear: both;
    padding: 3px 20px;
    white-space: nowrap;
    color: #58595b;
  }
  li:hover{
    background: #f5f5f5
  }
`;


const User = () => {
    //const { user } = useContext(AuthContext);
    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const objIdEmpresa = empresaParse._id;
    const idEmpresa = empresaParse.id_empresa;
    const vinculo_empresa = empresaParse.cnpj;

    const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES

    var [tempoBalcaoMinBD, setTempoBalcaoMinBD] = useState(0);
    var [tempoBalcaoMaxBD, setTempoBalcaoMaxBD] = useState(0);
    var [tempoEntregaMinBD, setTempoEntregaMinBD] = useState(0);
    var [tempoEntregaMaxBD, setTempoEntregaMaxBD] = useState(0);
    var [tipoImpressao, setTipoImpressao] = useState("");
    const [refresh, setRefresh] = useState(false);

    const [filtroSelecionado, setFiltroSelecionado] = useState("all");

    const [pedidosAnalise, setPedidosAnalise] = useState([]);
    const [pedidosProducao, setPedidosProducao] = useState([]);
    const [pedidosEntrega, setPedidosEntrega] = useState([]);
    const [pedidosFinalizado, setPedidosFinalizado] = useState([]);

    const [pedidos, setPedidos] = useState([]);
    const navigate = useNavigate();
    const [infoPedido, setInfoPedido] = useState({});
    const [filterIsLoading, setFilterIsLoading] = useState(false);
    const [showModalAddEntregador, setShowModalAddEntregador] = useState(true);
    const [pedidoToAddEntregador, setPedidoToAddEntregador] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [originalPedidos, setOriginalPedidos] = useState([]); // Para armazenar a lista completa de pedidos
    //const [imageBase64, setImageBase64] = useState("");
    //const [pdfBase64, setPdfBase64] = useState("");
    var imageDataURL = null;
    const [isLoading, setIsLoading] = useState(false);
    const [hasCancelPassword, setHasCancelPassword] = useState(false);
    //const [printJobId, setPrintJobId] = useState('');

    /*useEffect(() => {
        if (refresh) {
            const fetchDataAndSetPedidos = async () => {
                const fetchedPedidos = await fetchData();
                setPedidos(fetchedPedidos);
            };          
            fetchDataAndSetPedidos();
            setRefresh(false);
        }
    }, [refresh]);

    const fetchDataAndSetPedidosForFilters = async () => {
        const fetchedPedidos = await fetchData();
        setPedidos(fetchedPedidos);
    };
    
    const fetchData = async () => {
        const response = await getVinculoEmpresa(userID);
        //const vinculo = response.data.vinculo.cnpj;
        //console.log("response>",response.data.vinculo);
        //const pedidosResponse = await getPedidos(userID, id_empresa);
        const pedidosAnalise = await getPedidosByStatus(userID, idEmpresa, vinculo_empresa, '1');
        const pedidosProducao = await getPedidosByStatus(userID, idEmpresa, vinculo_empresa, '2');
        const pedidosEntrega = await getPedidosByStatus(userID, idEmpresa, vinculo_empresa, '3');
        const pedidosFinalizados = await getPedidosByStatus(userID, idEmpresa, vinculo_empresa, '4');
        //console.log("pedidosAnaliseResponse>",pedidosAnaliseResponse.data.pedidos);
        setTipoImpressao(response.data.vinculo.tipo_impressao);
        setTempoBalcaoMinBD(parseInt(response.data.vinculo.tempoBalcaoMin));
        setTempoBalcaoMaxBD(parseInt(response.data.vinculo.tempoBalcaoMax));
        setTempoEntregaMinBD(parseInt(response.data.vinculo.tempoEntregaMin));
        setTempoEntregaMaxBD(parseInt(response.data.vinculo.tempoEntregaMax));
        
    //setPedidos(pedidosResponse.data.pedidos);
        setRefresh(false);
        return [
            ...pedidosAnalise.data.pedidos,
            ...pedidosProducao.data.pedidos,
            ...pedidosEntrega.data.pedidos,
            ...pedidosFinalizados.data.pedidos,
          ];
    };*/
    const fetchData = async () => {
        const response = await getVinculoEmpresa(userID);
        //const { vinculo_empresa } = response.data.vinculo; // Ajuste conforme a estrutura de sua resposta
        const pedidosAnalise = await getPedidosByStatus(userID, idEmpresa, vinculo_empresa, '1');
        const pedidosProducao = await getPedidosByStatus(userID, idEmpresa, vinculo_empresa, '2');
        const pedidosEntrega = await getPedidosByStatus(userID, idEmpresa, vinculo_empresa, '3');
        const pedidosFinalizados = await getPedidosByStatus(userID, idEmpresa, vinculo_empresa, '4');
        //console.log("pedidosFinalizados>>", pedidosFinalizados)
        setTipoImpressao(response.data.vinculo.tipo_impressao);
        setTempoBalcaoMinBD(parseInt(response.data.vinculo.tempoBalcaoMin));
        setTempoBalcaoMaxBD(parseInt(response.data.vinculo.tempoBalcaoMax));
        setTempoEntregaMinBD(parseInt(response.data.vinculo.tempoEntregaMin));
        setTempoEntregaMaxBD(parseInt(response.data.vinculo.tempoEntregaMax));
        setHasCancelPassword(response.data.vinculo.has_cancel_password)

        setRefresh(false);
        setFilterIsLoading(false);
        return [...pedidosAnalise.data.pedidos, ...pedidosProducao.data.pedidos, ...pedidosEntrega.data.pedidos, ...pedidosFinalizados.data.pedidos];
    };

    const fetchDataAndSetPedidosForFilters = async (paramReceived) => {
        const fetchedPedidos = await fetchData();
        paramReceived === "all" && setPedidos(fetchedPedidos);
        paramReceived === "delivery" && setPedidos(fetchedPedidos.filter(pedido => pedido.entrega.tipo_entrega.toLowerCase() === "entrega"));
        paramReceived === "balcao" && setPedidos(fetchedPedidos.filter(pedido => pedido.entrega.tipo_entrega.toLowerCase() === "retirada"));

    };

    useEffect(() => {
        setFilterIsLoading(true);
        fetchDataAndSetPedidosForFilters(filtroSelecionado);
    }, [refresh]);

    useEffect(() => {
        ////const intervalId = setInterval(() => {
        ////    fetchData().then(newPedidos => setPedidos(newPedidos)); // COMENTARIO TEMPORARIO, TESTAR SOCKET IO
        ////}, 5 * 1000); // Atualizar a cada 60 segundos

        setPedidosAnalise([]);
        setPedidosProducao([]);
        setPedidosEntrega([]);
        setPedidosFinalizado([]);
        pedidos.map((dados, i) => {
            //console.log(dados);console.log(i);
            if (dados.status_pedido == '1') {
                setPedidosAnalise(pedidosAnteriores => [...pedidosAnteriores, dados]);
                //console.log("dados adicionados>", dados);
            }
            if (dados.status_pedido == '2') {
                setPedidosProducao(pedidosAnteriores => [...pedidosAnteriores, dados])
            }
            if (dados.status_pedido == '3') {
                setPedidosEntrega(pedidosAnteriores => [...pedidosAnteriores, dados])
            }
            if (dados.status_pedido == '4') {
                setPedidosFinalizado(pedidosAnteriores => [...pedidosAnteriores, dados])
            }
        })

        ////return () => clearInterval(intervalId);
    }, [pedidos, refresh]); // Sem dependências, então o efeito será executado apenas uma vez

    const isDevelopment = window.location.hostname === 'localhost';
    const apiUrl = isDevelopment
        ? process.env.REACT_APP_SERVER_URL_DEV
        : process.env.REACT_APP_SERVER_URL_PROD;
    /*useEffect(() => {
        const wsUrl = apiUrl; // Alterar a URL se o servidor estiver em um endereço diferente
        const socket = io(wsUrl , {withCredentials: true, transports: ['websocket'], auth: {token: localStorage.getItem('token')}});
        //console.log(idEmpresa)
        socket.emit('joinCompanyRoom', { companyId: idEmpresa.toString(), clientId: 'reactClient' });
        socket.on('novoPedido', (data) => {
            //console.log(data.msg, data.pedido);
            console.log("NOVO PEDIDO RECEBIDO!");
            // Atualize seu estado ou UI aqui com os dados do novo pedido
            fetchData().then(newPedidos => setPedidos(newPedidos));
            fetchData().then(newPedidos => setOriginalPedidos(newPedidos));
        });

        return () => {
            socket.off('novoPedido');
        };
    }, []); // Adicione idEmpresa ao array de dependências para recriar o efeito quando ele mudar
    */
    useEffect(() => {
        const fetchPedidos = async () => {
            const data = await fetchData();
            setOriginalPedidos(data); // Armazena os pedidos originais
            setPedidos(data); // Inicializa os pedidos com os dados originais
        };

        fetchPedidos();

        const wsUrl = apiUrl; // Alterar a URL se o servidor estiver em um endereço diferente
        const socket = io(wsUrl, { withCredentials: true, transports: ['websocket'], auth: { token: localStorage.getItem('token') } });

        socket.emit('joinCompanyRoom', { companyId: idEmpresa.toString(), clientId: 'reactClient' });

        socket.on('novoPedido', async (data) => {
            console.log("NOVO PEDIDO RECEBIDO!");
            const newPedidos = await fetchData();
            setOriginalPedidos(newPedidos);
            setPedidos(newPedidos);
        });

        return () => {
            socket.off('novoPedido');
        };
    }, [idEmpresa]);

    useEffect(() => {
        const filteredPedidos = originalPedidos.filter(dados =>
            dados.nome_cliente.toLowerCase().includes(searchQuery.toLowerCase()) ||
            dados.id_pedido_counter?.toString().includes(searchQuery) ||
            dados.id_pedido.toString().includes(searchQuery)
        );
        setPedidos(filteredPedidos);
    }, [searchQuery, originalPedidos]);
    //useEffect(() => {
    // This effect will run whenever the `pedidos` state variable changes
    //console.log('Pedidos have changed:', pedidos);
    //}, [pedidos]); // add `pedidos` to the dependency array to trigger this effect when it changes


    const handleCadastroPDV = () => {
        navigate('/pdv');
    }


    const handleAddEntregador = (event, pedido) => {
        event.stopPropagation();
        console.log("ADD ENTREGADOR");
        setPedidoToAddEntregador(pedido);
        setShowModalAddEntregador(!showModalAddEntregador);
    }

    const handleAvancar = async (event, _id, id_pedido, status_pedido) => {
        event.stopPropagation();
        setIsLoading(true); // Inicia o carregamento

        let newStatus = "";
        let finalizadoAt = "";
        let response = null;

        switch (status_pedido) {
            case "1":
                newStatus = "2";
                break;
            case "2":
                newStatus = "3";
                break;
            case "3":
                newStatus = "4";
                //finalizadoAt = moment().local().format("DD/MM/YYYY");
                //finalizadoAt = momentTz().tz('America/Sao_Paulo').format("DD/MM/YYYY");
                finalizadoAt = new Date();
                break;
            default:
                setIsLoading(false);
                return; // Se o status_pedido não é 1, 2, ou 3, termina a função
        }

        try {
            // Unifica a chamada da API dependendo do caso
            if (status_pedido === "3") {
                response = await updateStatusPedidoFinalizado(userID, _id, id_pedido, newStatus, finalizadoAt);
            } else {
                response = await updateStatusPedido(userID, _id, id_pedido, newStatus);
            }
            setRefresh(true); // Atualiza o estado para forçar recarga dos dados
            toast(response.data.msg, { autoClose: 2000, type: "success" });
        } catch (err) {
            toast(err.response?.data?.msg || "An error occurred", { autoClose: 2000, type: "error" });
        } finally {
            setIsLoading(false); // Finaliza o carregamento imediatamente após a conclusão ou falha da chamada da API
        }
    };

    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user')
    const user = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    //const user = localStorage.getItem('user')
    const userParse = JSON.parse(user)
    //console.log(userParse)
    //const userParse = user;
    const userID = userParse._id;
    const [showEditTempoEntregaEmpresa, setEditTempoEntregaEmpresa] = useState(true);
    const [_idEmpresaEdit, set_idEmpresaEdit] = useState('');
    //const [showOptions, setUserOptions] = useState(true);
    const INITIAL_DATA = {
        value: "",
        label: 'Selecione uma empresa',
    };
    const [selectData, setselectData] = useState(INITIAL_DATA);

    const [showPedido, setShowPedido] = useState(true);


    const totalValuePerOrder = pedidos.map((order) => {
        const totalValue = order.itens.reduce((acc, item) => {
            return acc + (item.valor * item.quantidade);
        }, 0);
        return { ...order, totalValue };
    });

    const handleEditTempoEntrega = async idToEdit => {
        setEditTempoEntregaEmpresa(!showEditTempoEntregaEmpresa);
        set_idEmpresaEdit(idEmpresa);
        //setUserOptions(!showOptions);
        /*const response = await getUser(idToEdit);
        if(showEditTempoEntregaEmpresa){       
            if (response.data.user.vinculo_empresa){                
                const responseVinculo = await getVinculoEmpresa(idToEdit);                     
                //console.log("TEM EMPRESA VINCULADA!",responseVinculo.data.vinculo.id_empresa)
                set_idEmpresaEdit(responseVinculo.data.vinculo.id_empresa);                
            }    
        }*/
    }

    const handleFilterAll = async () => {
        setFilterIsLoading(true);
        setFiltroSelecionado("all");
        await fetchDataAndSetPedidosForFilters("all"); // Garantindo que os dados estão atualizados;              
    }

    const handleFilterDelivery = async () => {
        setFilterIsLoading(true);
        setFiltroSelecionado("delivery");
        await fetchDataAndSetPedidosForFilters("delivery"); // Garantindo que os dados estão atualizados
        //setPedidos(currentPedidos => currentPedidos.filter(pedido => pedido.entrega.tipo_entrega.toLowerCase() === "entrega"));        
    };

    const handleFilterBalcao = async () => {
        setFilterIsLoading(true);
        setFiltroSelecionado("balcao");
        await fetchDataAndSetPedidosForFilters("balcao"); // Garantindo que os dados estão atualizados
        //setPedidos(currentPedidos => currentPedidos.filter(pedido => pedido.entrega.tipo_entrega.toLowerCase() === "retirada"));
    };

    const handleShowPedido = async (idPedidoToOpen, id_empresa) => {

        setShowPedido(!showPedido);
        const response = await getPedido(userID, id_empresa, vinculo_empresa, idPedidoToOpen);
        if (showPedido) {
            if (response.data.pedido) {
                setInfoPedido(response.data.pedido[0]);
            }
        }
    }

    return (

        <>
            <PermissionGate permissions={['default']}>


                <LeftMenu setSidebar={setSidebar} sidebar={sidebar} />

                <Teste sidebar={sidebar}>

                    <MenuAjuda>
                        <div className="item-menu-help-info">
                            <svg
                                width={30}
                                height={30}
                                viewBox="0 0 30 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                id="shaking"
                            >
                                <path
                                    d="M28.9167 24.4441V22.2775C28.916 21.3173 28.5965 20.3846 28.0082 19.6258C27.42 18.867 26.5964 18.325 25.6667 18.085"
                                    stroke="currentColor"
                                    strokeWidth="1.3"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M22.4166 24.4443V22.2777C22.4166 21.1284 21.96 20.0262 21.1474 19.2135C20.3347 18.4009 19.2325 17.9443 18.0833 17.9443H9.41658C8.26731 17.9443 7.16511 18.4009 6.35246 19.2135C5.5398 20.0262 5.08325 21.1284 5.08325 22.2777V24.4443"
                                    stroke="currentColor"
                                    strokeWidth="1.3"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M21.3333 5.08496C22.2654 5.32362 23.0915 5.86572 23.6815 6.6258C24.2715 7.38587 24.5917 8.32069 24.5917 9.28288C24.5917 10.2451 24.2715 11.1799 23.6815 11.94C23.0915 12.7 22.2654 13.2421 21.3333 13.4808"
                                    stroke="currentColor"
                                    strokeWidth="1.3"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M13.7501 13.611C16.1433 13.611 18.0834 11.6709 18.0834 9.27767C18.0834 6.88444 16.1433 4.94434 13.7501 4.94434C11.3568 4.94434 9.41675 6.88444 9.41675 9.27767C9.41675 11.6709 11.3568 13.611 13.7501 13.611Z"
                                    stroke="currentColor"
                                    strokeWidth="1.3"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M8.02771 1.81393C6.65952 2.40269 4.00254 4.64734 4.32012 8.9159M3.20673 1.3753C2.33013 2.1057 1.18146 3.37006 1.36262 5.9846"
                                    stroke="currentColor"
                                    strokeWidth="1.3"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="number-box number-box--active">1</div>
                        </div>

                    </MenuAjuda>


                    <ModalEditTempoEntrega setEditTempoEntregaEmpresa={setEditTempoEntregaEmpresa} showEditTempoEntregaEmpresa={showEditTempoEntregaEmpresa}
                        setRefresh={setRefresh}
                        selectData={selectData}
                        setselectData={setselectData}
                        _idEmpresaEdit={_idEmpresaEdit}
                        tempoBalcaoMinBD={tempoBalcaoMinBD}
                        tempoBalcaoMaxBD={tempoBalcaoMaxBD}
                        tempoEntregaMinBD={tempoEntregaMinBD}
                        tempoEntregaMaxBD={tempoEntregaMaxBD}
                        tipoImpressao={tipoImpressao}
                    //usernameEdit={usernameEdit} 
                    //emailEdit={emailEdit} 
                    //roleEdit={roleEdit}    
                    //editPerfil={false}
                    />

                    <ModalPedido setShowPedido={setShowPedido} showPedido={showPedido}
                        setRefresh={setRefresh}
                        infoPedido={infoPedido}
                        handleAvancar={handleAvancar}
                        userID={userID}
                        hasCancelPassword={hasCancelPassword}
                    />

                    <ModalAddEntregador showModalAddEntregador={showModalAddEntregador}
                        setShowModalAddEntregador={setShowModalAddEntregador}
                        pedidoToAddEntregador={pedidoToAddEntregador}
                        objIdEmpresa={objIdEmpresa}
                        userID={userID}
                        setRefresh={setRefresh}
                    />

                    <div className="m-5">
                        <div style={{ marginTop: "-30px", width: '96%' }}>

                            <div className="form-header" style={{ marginBottom: "0px" }}>
                                <div className="title">
                                    <h1>Meus Pedidos</h1>
                                </div>
                            </div>

                            {/*<div className="imgDashboard" style={{zIndex:"10", marginTop:"30px"}}>
                            <img src={imageUrl} width={"100%"} height={"100%"} className='dashImg' style={{transition:"100ms"}}/>
                        </div> */}
                            <div style={{ display: 'inline-flex', width: '100%', justifyContent: 'space-between' }}>
                                <div style={{ display: 'inline-flex', width: '60%', justifyContent: 'space-between', justifyItems: 'center', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', height: 'max-content', width: 'max-content', boxShadow: '0px 0px 3px 2px lightgray', borderRadius: '10px' }}>
                                        <div onClick={() => handleFilterAll()} style={{
                                            cursor: 'pointer', width: 79, height: 50, padding: 16, borderRadius: '10px 0px 0px 10px',
                                            background: filtroSelecionado === "all" ? '#318CD5' : 'white', justifyContent: 'center', alignItems: 'center', gap: 8, display: 'inline-flex'
                                        }}>
                                            <div style={{ color: filtroSelecionado === "all" ? 'white' : '#001B30', fontSize: 14, fontWeight: '700', letterSpacing: 0.48, wordWrap: 'break-word' }}>Todos</div>
                                        </div>
                                        <div style={{
                                            cursor: 'pointer', width: 132, height: 50, padding: 16, background: filtroSelecionado === "delivery" ? '#318CD5' : 'white',
                                            justifyContent: 'center', alignItems: 'center', gap: 8,
                                            display: 'inline-flex',
                                            borderLeft: '1px solid lightgray',
                                            borderRight: '1px solid lightgray'
                                        }} onClick={() => handleFilterDelivery()}>
                                            <div style={{ color: filtroSelecionado === "delivery" ? 'white' : '#001B30', fontSize: 14, fontWeight: '700', letterSpacing: 0.48, wordWrap: 'break-word' }}>
                                                <GiFullMotorcycleHelmet style={{ fontSize: 20 }} />
                                                <span style={{ paddingLeft: 8 }}>Delivery</span>
                                            </div>
                                        </div>
                                        <div onClick={() => handleFilterBalcao()} style={{
                                            cursor: 'pointer', width: 132, height: 50, padding: 16, borderRadius: '0px 10px 10px 0px',
                                            background: filtroSelecionado === "balcao" ? '#318CD5' : 'white', justifyContent: 'center', alignItems: 'center', gap: 8, display: 'inline-flex'
                                        }}>
                                            <div style={{ color: filtroSelecionado === "balcao" ? 'white' : '#001B30', fontSize: 14, fontWeight: '700', letterSpacing: 0.48, wordWrap: 'break-word' }}>
                                                <MdStorefront style={{ fontSize: 20 }} />
                                                <span style={{ paddingLeft: 8 }}>Balcão</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="input-box-list home" style={{ width: "50%" }}>
                                        <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                                            <input style={{ width: '100%' }} value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                                                className="input-fieldClienteOuPedido" placeholder="Buscar pelo cliente ou nº do pedido" />
                                            <i className="icon"><FiIcons.FiSearch /></i>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ display: 'inline-flex' }}>
                                    <div className="div-buttons">
                                        <div className="continue-button">
                                            <button onClick={handleCadastroPDV}>
                                                <AiIcons.AiOutlinePlusCircle style={{ marginRight: "5px", fontSize: "22px", marginBottom: "2px" }} /><a>Novo Pedido</a>
                                            </button>
                                        </div>
                                    </div>

                                </div>

                            </div>


                        </div>

                        <div className="Ordercolumn" style={{ display: 'inline-flex', justifyContent: 'space-between', width: '96%', height: '674px', boxShadow: '0px 0px 5px 2px rgb(0,0,0,.1)' }}>

                            {filterIsLoading ? (
                                <Loading type='spin' color='rgb(49, 140, 213)' height={56} width={56} className="loadingSpinHomeFilters" />
                            )
                                : null
                            }

                            {/* Pedidos em Análise */}
                            <Ordercolumn
                                typecolumn="analysis"
                                className="column"
                            //style={{display:'table-row'}}
                            >
                                <div className="containerAnalise">

                                    <div className="header-column-analise">

                                        <div className="header-column--align">
                                            <h4>Em análise</h4>
                                        </div>
                                        <h4>{pedidosAnalise.length}</h4>

                                    </div>

                                    <div className="blocoAnalise">
                                        <div className="column-container column-container--first">
                                            <div className="time">
                                                <div>
                                                    <p className="titleOptEmp">
                                                        <b>Balcão: </b>
                                                        <span>{tempoBalcaoMinBD} a {tempoBalcaoMaxBD} min</span>
                                                    </p>
                                                    <p className="titleOptEmp">
                                                        <b>Delivery: </b>
                                                        <span>{tempoEntregaMinBD} a {tempoEntregaMaxBD} min</span>
                                                    </p>
                                                    <p className="titleOptEmp">
                                                        <b>Aceite automático: </b>
                                                        <span>{tipoImpressao == 'manual' ? 'Desativado' : tipoImpressao == 'automatico' ? 'Ativado' : 'Não definido'}</span>
                                                    </p>
                                                </div>
                                                <div className="text-edit" onClick={() => handleEditTempoEntrega(userID)} style={{ cursor: "pointer" }}>
                                                    {" "}
                                                    Editar{" "}
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            pedidosAnalise.length == 0 ?
                                                <div key={Math.random()} className="column-container column-container--first">
                                                    <div className="text">
                                                        <p style={{ marginBottom: 0, justifyContent: 'center', textAlign: 'center', display: 'flex' }}>Nenhum pedido no momento.</p>
                                                        <p style={{ marginBottom: 0, justifyContent: 'center', textAlign: 'center', display: 'flex' }}>
                                                            Compartilhe os seus links nas redes sociais e receba pedidos!
                                                        </p>
                                                    </div>
                                                </div>
                                                :
                                                pedidos && pedidos.map((dados, i) => (

                                                    dados.status_pedido == '1' ?
                                                        <div key={Math.random()} className="bloco-pedidos" onClick={() => handleShowPedido(dados._id, dados.id_empresa)}>
                                                            <div className="pedido-time">
                                                                <div className="tag-pedido">
                                                                    <div className="icon-pedido">
                                                                        {dados.entrega.tipo_entrega == "Retirada" ? <FaStore /> : <FaMotorcycle />}
                                                                    </div>
                                                                    <span className="bold">Pedido #{dados.id_pedido_counter ?? dados.id_pedido}</span>

                                                                </div>
                                                                <div className="time-container">
                                                                    <div className="time-div">
                                                                        <FaRegClock />
                                                                        <span>{moment.parseZone(dados.createdAt).local().format("HH:mm")}</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="cliente-total">
                                                                <div className="bloco">
                                                                    <div className="linha">
                                                                        <div className="texto">{dados.nome_cliente}</div>
                                                                        {dados.counter_qtd_pedido > 1 ?
                                                                            <div className="label-recorrencia-pedidoMoreThanOne">
                                                                                <div className="caixaNumero">
                                                                                    <span style={{ fontSize: 10 }}>{dados.counter_qtd_pedido}</span>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div className="label-recorrencia-pedido">
                                                                                <div className="caixaNumero">
                                                                                    <span style={{ fontSize: 10 }}>1º</span>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className="linha">
                                                                        <div className="texto">
                                                                            {dados.celular_cliente}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="bloco">
                                                                    <div className="linha">
                                                                        <div className="texto bold">Total:</div>
                                                                        <div className="texto bold">
                                                                            R$ {dados.valor_total && dados.valor_total.toFixed(2).toString().replace('.', ',')}
                                                                        </div>
                                                                    </div>
                                                                    <div className="linha">
                                                                        <div className="texto">Pagamento:</div>
                                                                        <div className="texto">{dados.tipo_pagamento}</div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="bloco-entrega">
                                                                {dados.entrega.tipo_entrega == "Retirada" ?
                                                                    <div className="flex-entrega">
                                                                        <div className="bloco-footer">
                                                                            <div className="pin" style={{ display: 'flex', marginTop: 1 }}>
                                                                                <IoMdPin className="pin" />
                                                                            </div>
                                                                            <div className="texto">Retirada no Local</div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="flex-entrega">
                                                                        <div className="bloco-footer">
                                                                            <div className="pin">
                                                                                <IoMdPin className="pin" />
                                                                            </div>
                                                                            <div className="texto">
                                                                                {`${dados.entrega.endereco.rua}, ${dados.entrega.endereco.numero} 
                                                                    ${dados.entrega.endereco.complemento && " - " + dados.entrega.endereco.complemento}, ${dados.entrega.endereco.bairro}`}
                                                                            </div>
                                                                        </div>
                                                                        <div className="bloco" onClick={(event) => handleAddEntregador(event, dados)}>
                                                                            {dados.entregador ?
                                                                                <div className="texto underline">{dados.entregador.name}</div>
                                                                                :
                                                                                <div className="texto underline">Escolher Entregador</div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>

                                                            <div style={{ marginTop: 10 }}>
                                                                <div className="button-avancar-pedido">
                                                                    <button onClick={(event) => handleAvancar(event, dados._id, dados.id_pedido, dados.status_pedido)}>
                                                                        <div>
                                                                            <a>Avançar Pedido</a>
                                                                            <AiIcons.AiOutlineArrowRight style={{ marginLeft: "2px", fontSize: "22px", marginBottom: "2px", fontColor: 'white' }} />
                                                                        </div>
                                                                    </button>
                                                                    {/*isLoading ? 
                                                                <button>                                                               
                                                                    <Loading type='spin' color='rgb(49, 140, 213)' height={32} width={32} />
                                                                </button>
                                                                :
                                                                <button onClick={(event) => handleAvancar(event, dados._id, dados.id_pedido, dados.status_pedido)}>                                                                 
                                                                    <div>
                                                                        <a>Avançar Pedido</a>
                                                                        <AiIcons.AiOutlineArrowRight style={{marginLeft: "2px",fontSize: "22px",marginBottom: "2px", fontColor:'white'}}/>
                                                                    </div>                                                                                                                                 
                                                                </button>
                                                            */}
                                                                </div>
                                                            </div>

                                                        </div>
                                                        :
                                                        null

                                                ))

                                        }

                                    </div>
                                </div>

                            </Ordercolumn>

                            {/* Pedidos em produção */}
                            <Ordercolumn
                                typecolumn="production"
                                className="column"
                            //style={{display:'table-row'}}
                            >
                                <div className="containerProducao">
                                    <div className="header-column-producao">
                                        <div className="header-column--align">
                                            <h4>Em produção</h4>
                                        </div>
                                        <h4>{pedidosProducao.length}</h4>
                                    </div>

                                    <div className="blocoProducao">

                                        {pedidosProducao.length == 0 ?
                                            <div key={Math.random()} className="column-container column-container--first">
                                                <div className="text">
                                                    <p style={{ marginBottom: 0, justifyContent: 'center', textAlign: 'center', display: 'flex' }}>Nenhum pedido no momento.</p>
                                                    <p style={{ marginBottom: 0, justifyContent: 'center', textAlign: 'center', display: 'flex' }}>
                                                        Receba pedidos e visualize os que estão em produção.
                                                    </p>
                                                </div>
                                            </div>
                                            :
                                            pedidos && pedidos.map((dados, i) => (
                                                dados.status_pedido == '2' ?
                                                    <div key={Math.random()} className="bloco-pedidos" onClick={() => handleShowPedido(dados._id, dados.id_empresa)}>
                                                        <div className="pedido-time">
                                                            <div className="tag-pedido">
                                                                <div className="icon-pedido">
                                                                    {dados.entrega.tipo_entrega == "Retirada" ? <FaStore /> : <FaMotorcycle />}
                                                                </div>
                                                                <span className="bold">Pedido #{dados.id_pedido_counter ?? dados.id_pedido}</span>

                                                            </div>
                                                            <div className="time-container">
                                                                <div className="time-div">
                                                                    <FaRegClock />
                                                                    <span>{moment.parseZone(dados.createdAt).local().format("HH:mm")}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="cliente-total">
                                                            <div className="bloco">
                                                                <div className="linha">
                                                                    <div className="texto">{dados.nome_cliente}</div>
                                                                    {dados.counter_qtd_pedido > 1 ?
                                                                        <div className="label-recorrencia-pedidoMoreThanOne">
                                                                            <div className="caixaNumero">
                                                                                <span style={{ fontSize: 10 }}>{dados.counter_qtd_pedido}</span>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className="label-recorrencia-pedido">
                                                                            <div className="caixaNumero">
                                                                                <span style={{ fontSize: 10 }}>1º</span>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="linha">
                                                                    <div className="texto">
                                                                        {dados.celular_cliente}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="bloco">
                                                                <div className="linha">
                                                                    <div className="texto bold">Total:</div>
                                                                    <div className="texto bold">
                                                                        R$ {dados.valor_total && dados.valor_total.toFixed(2).toString().replace('.', ',')}
                                                                    </div>
                                                                </div>
                                                                <div className="linha">
                                                                    <div className="texto">Pagamento:</div>
                                                                    <div className="texto">{dados.tipo_pagamento}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="bloco-entrega">
                                                            {dados.entrega.tipo_entrega == "Retirada" ?
                                                                <div className="flex-entrega">
                                                                    <div className="bloco-footer">
                                                                        <div className="pin" style={{ display: 'flex', marginTop: 1 }}>
                                                                            <IoMdPin className="pin" />
                                                                        </div>
                                                                        <div className="texto">Retirada no Local</div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="flex-entrega">
                                                                    <div className="bloco-footer">
                                                                        <div className="pin">
                                                                            <IoMdPin className="pin" />
                                                                        </div>
                                                                        <div className="texto">
                                                                            {`${dados.entrega.endereco.rua}, ${dados.entrega.endereco.numero} 
                                                                    ${dados.entrega.endereco.complemento && " - " + dados.entrega.endereco.complemento}, ${dados.entrega.endereco.bairro}`}
                                                                        </div>
                                                                    </div>
                                                                    <div className="bloco" onClick={(event) => handleAddEntregador(event, dados)}>
                                                                        {dados.entregador ?
                                                                            <div className="texto underline">{dados.entregador.name}</div>
                                                                            :
                                                                            <div className="texto underline">Escolher Entregador</div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                        <div style={{ marginTop: 10 }}>
                                                            <div className="button-avancar-pedido">

                                                                <button onClick={(event) => handleAvancar(event, dados._id, dados.id_pedido, dados.status_pedido)}>
                                                                    <div>
                                                                        <a>Avançar Pedido</a>
                                                                        <AiIcons.AiOutlineArrowRight style={{ marginLeft: "2px", fontSize: "22px", marginBottom: "2px", fontColor: 'white' }} />
                                                                    </div>
                                                                </button>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    :
                                                    null
                                            ))
                                        }

                                    </div>

                                </div>

                            </Ordercolumn>

                            {/* Pedidos prontos para Entrega */}
                            <Ordercolumn
                                _ngcontent-ng-c2041748172=""
                                typecolumn="ready"
                                className="column"
                                _nghost-ng-c2960941283=""
                                style={{ display: 'table-row' }}
                            >
                                <div className="containerPronto">
                                    <div className="header-column-pronto">
                                        <div className="header-column--align" style={{ display: 'inline-flex' }}>
                                            <h4>Prontos para entrega</h4>
                                        </div>
                                        <h4>{pedidosEntrega.length}</h4>
                                    </div>

                                    <div className="blocoPronto">
                                        {pedidosEntrega.length == 0 ?
                                            <div key={Math.random()} className="column-container column-container--first">
                                                <div className="text">
                                                    <p style={{ marginBottom: 0, justifyContent: 'center', textAlign: 'center', display: 'flex' }}>Nenhum pedido no momento.</p>
                                                    <p style={{ marginBottom: 0, justifyContent: 'center', textAlign: 'center', display: 'flex' }}>
                                                        Receba pedidos e visualize os que estão prontos para entrega.
                                                    </p>
                                                </div>
                                            </div>
                                            :
                                            pedidos && pedidos.map((dados, i) => (
                                                dados.status_pedido == '3' ?
                                                    <div key={Math.random()} className="bloco-pedidos" onClick={() => handleShowPedido(dados._id, dados.id_empresa)}>
                                                        <div className="pedido-time">
                                                            <div className="tag-pedido">
                                                                <div className="icon-pedido">
                                                                    {dados.entrega.tipo_entrega == "Retirada" ? <FaStore /> : <FaMotorcycle />}
                                                                </div>
                                                                <span className="bold">Pedido #{dados.id_pedido_counter ?? dados.id_pedido}</span>

                                                            </div>
                                                            <div className="time-container">
                                                                <div className="time-div">
                                                                    <FaRegClock />
                                                                    <span>{moment.parseZone(dados.createdAt).local().format("HH:mm")}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="cliente-total">
                                                            <div className="bloco">
                                                                <div className="linha">
                                                                    <div className="texto">{dados.nome_cliente}</div>
                                                                    {dados.counter_qtd_pedido > 1 ?
                                                                        <div className="label-recorrencia-pedidoMoreThanOne">
                                                                            <div className="caixaNumero">
                                                                                <span style={{ fontSize: 10 }}>{dados.counter_qtd_pedido}</span>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className="label-recorrencia-pedido">
                                                                            <div className="caixaNumero">
                                                                                <span style={{ fontSize: 10 }}>1º</span>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="linha">
                                                                    <div className="texto">
                                                                        {dados.celular_cliente}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="bloco">
                                                                <div className="linha">
                                                                    <div className="texto bold">Total:</div>
                                                                    <div className="texto bold">
                                                                        R$ {dados.valor_total && dados.valor_total.toFixed(2).toString().replace('.', ',')}
                                                                    </div>
                                                                </div>
                                                                <div className="linha">
                                                                    <div className="texto">Pagamento:</div>
                                                                    <div className="texto">{dados.tipo_pagamento}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="bloco-entrega">
                                                            {dados.entrega.tipo_entrega == "Retirada" ?
                                                                <div className="flex-entrega">
                                                                    <div className="bloco-footer">
                                                                        <div className="pin" style={{ display: 'flex', marginTop: 1 }}>
                                                                            <IoMdPin className="pin" />
                                                                        </div>
                                                                        <div className="texto">Retirada no Local</div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="flex-entrega">
                                                                    <div className="bloco-footer">
                                                                        <div className="pin">
                                                                            <IoMdPin className="pin" />
                                                                        </div>
                                                                        <div className="texto">
                                                                            {`${dados.entrega.endereco.rua}, ${dados.entrega.endereco.numero} 
                                                                    ${dados.entrega.endereco.complemento && " - " + dados.entrega.endereco.complemento}, ${dados.entrega.endereco.bairro}`}
                                                                        </div>
                                                                    </div>
                                                                    <div className="bloco" onClick={(event) => handleAddEntregador(event, dados)}>
                                                                        {dados.entregador ?
                                                                            <div className="texto underline">{dados.entregador.name}</div>
                                                                            :
                                                                            <div className="texto underline">Escolher Entregador</div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                        <div style={{ marginTop: 10 }}>
                                                            <div className="button-avancar-pedido">

                                                                <button onClick={(event) => handleAvancar(event, dados._id, dados.id_pedido, dados.status_pedido)}>
                                                                    <div>
                                                                        <a>Avançar Pedido</a>
                                                                        <AiIcons.AiOutlineArrowRight style={{ marginLeft: "2px", fontSize: "22px", marginBottom: "2px", fontColor: 'white' }} />
                                                                    </div>
                                                                </button>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    :
                                                    null
                                            ))
                                        }
                                    </div>
                                </div>

                            </Ordercolumn>


                            {/* Pedidos Finalizados */}
                            <Ordercolumn
                                _ngcontent-ng-c2041748172=""
                                typecolumn="ready"
                                className="column"
                                _nghost-ng-c2960941283=""
                                style={{ display: 'table-row' }}
                            >
                                <div className="containerFinalizado">
                                    <div className="header-column-finalizado">
                                        <div className="header-column--align" style={{ display: 'inline-flex' }}>
                                            <h4>Finalizados</h4>
                                            <div className="header-column-clear" style={{ display: 'flex', alignItems: 'center', marginLeft: 6 }}>
                                                <pedeja-icon
                                                    iconname="check-circle"
                                                    iconsize={18}
                                                    pedejatooltip="Finalizar todos os pedidos não relacionados a mesa"
                                                    iconcolor="#FFFFFF"
                                                    style={{ height: 18, display: 'flex', alignItems: 'center' }}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={18}
                                                        height={18}
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="#FFFFFF"
                                                        strokeWidth={3}
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        className="feather feather-check-circle"
                                                    >
                                                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                                                        <polyline points="22 4 12 14.01 9 11.01" />
                                                    </svg>
                                                </pedeja-icon>
                                                {/**/}
                                            </div>
                                        </div>
                                        <h4>{pedidosFinalizado.length}</h4>
                                    </div>

                                    <div className="blocoFinalizado">
                                        {pedidosFinalizado.length == 0 ?
                                            <div key={Math.random()} className="column-container column-container--first">
                                                <div className="text">
                                                    <p style={{ marginBottom: 0, justifyContent: 'center', textAlign: 'center', display: 'flex' }}>Nenhum pedido no momento.</p>
                                                    <p style={{ marginBottom: 0, justifyContent: 'center', textAlign: 'center', display: 'flex' }}>
                                                        Receba pedidos e visualize os finalizados.
                                                    </p>
                                                </div>
                                            </div>
                                            :
                                            pedidos && pedidos.map((dados, i) => (
                                                dados.status_pedido == '4' ?
                                                    <div key={Math.random()} className="bloco-pedidos" onClick={() => handleShowPedido(dados._id, dados.id_empresa)}>
                                                        <div className="pedido-time">
                                                            <div className="tag-pedido">
                                                                <div className="icon-pedido">
                                                                    {dados.entrega.tipo_entrega == "Retirada" ? <FaStore /> : <FaMotorcycle />}
                                                                </div>
                                                                <span className="bold">Pedido #{dados.id_pedido_counter ?? dados.id_pedido}</span>
                                                                {dados.cancelado &&
                                                                    <div className="divSpanCancelado">
                                                                        <span>CANCELADO</span>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="time-container">
                                                                <div className="time-div">
                                                                    <FaRegClock />
                                                                    <span>{moment.parseZone(dados.createdAt).local().format("HH:mm")}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="cliente-total">
                                                            <div className="bloco">
                                                                <div className="linha">
                                                                    <div className="texto">{dados.nome_cliente}</div>
                                                                    {dados.counter_qtd_pedido > 1 ?
                                                                        <div className="label-recorrencia-pedidoMoreThanOne">
                                                                            <div className="caixaNumero">
                                                                                <span style={{ fontSize: 10 }}>{dados.counter_qtd_pedido}</span>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className="label-recorrencia-pedido">
                                                                            <div className="caixaNumero">
                                                                                <span style={{ fontSize: 10 }}>1º</span>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="linha">
                                                                    <div className="texto">
                                                                        {dados.celular_cliente}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="bloco">
                                                                <div className="linha">
                                                                    <div className="texto bold">Total:</div>
                                                                    <div className="texto bold">
                                                                        R$ {dados.valor_total && dados.valor_total.toFixed(2).toString().replace('.', ',')}
                                                                    </div>
                                                                </div>
                                                                <div className="linha">
                                                                    <div className="texto">Pagamento:</div>
                                                                    <div className="texto">{dados.tipo_pagamento}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="bloco-entrega">
                                                            {dados.entrega.tipo_entrega == "Retirada" ?
                                                                <div className="flex-entrega">
                                                                    <div className="bloco-footer">
                                                                        <div className="pin" style={{ display: 'flex', marginTop: 1 }}>
                                                                            <IoMdPin className="pin" />
                                                                        </div>
                                                                        <div className="texto">Retirada no Local</div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="flex-entrega">
                                                                    <div className="bloco-footer">
                                                                        <div className="pin">
                                                                            <IoMdPin className="pin" />
                                                                        </div>
                                                                        <div className="texto">
                                                                            {`${dados.entrega.endereco.rua}, ${dados.entrega.endereco.numero} 
                                                                    ${dados.entrega.endereco.complemento && " - " + dados.entrega.endereco.complemento}, ${dados.entrega.endereco.bairro}`}
                                                                        </div>
                                                                    </div>
                                                                    <div className="bloco">
                                                                        {dados.entregador &&
                                                                            <div className="texto underline">{dados.entregador.name}</div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>



                                                    </div>
                                                    :
                                                    null

                                            ))
                                        }
                                    </div>
                                </div>

                            </Ordercolumn>

                        </div>



                    </div>
                </Teste>

            </PermissionGate>
        </>


    );
};

export default User;