import React, { useContext, useState } from "react";

import "./style.css";
import { PiCashRegister } from "react-icons/pi";
import { MdLockOpen } from "react-icons/md";
import { TbClockHour4 } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

import { ModalOpenCaixa } from "../../../components/ModalOpenCaixa";
import { AuthContext } from "../../../contexts/auth";
import { registerCaixaAndOpen } from "../../../services/api";
import { convertPrice } from "../../../utils/convertPrice";
import { CaixaContext } from "../../../contexts/caixaContext";

function ClosedCaixa() {
  const { user } = useContext(AuthContext);

  const empresa = localStorage.getItem('empresa')
  const empresaParse = JSON.parse(empresa)
  const idEmpresa = empresaParse.id_empresa;

  const { caixa, refetch, refetchStatus } = useContext(CaixaContext);

  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const onOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  const handleOpenCaixa = async ({ amount }) => {
    try {
      await registerCaixaAndOpen(
        user.id,
        idEmpresa,
        convertPrice(amount)
      );
      refetchStatus();
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="container-caixa">
        <div className="content">
          <div className="buttons">
            <button className="button-default open-button" onClick={onOpen}>
              ABRIR CAIXA
              <MdLockOpen />
            </button>
            <button
              className="button-default previous-button"
              onClick={() => navigate("/caixa/historico")}
            >
              CAIXAS ANTERIORES
              <TbClockHour4 />
            </button>
          </div>
          <div className="card-caixa">
            <header>
              <PiCashRegister style={{ fontSize: "6rem" }} />
              <h2>Caixa Fechado</h2>
            </header>
            <div className="caixa-text">
              <p>
                A operação de caixa de um pedido é lançado apenas quando ele é
                concluído.
              </p>
              <p>
                O caixa será aberta automaticamente, caso esteja fechada, quando
                um pedido for concluído
              </p>
              <p>
                Não esqueça de fechar o caixa no final do expediente. Cixas que
                foram abertos a mais de 24h e não possuem movimentações nas
                últimas 4h, serão fechadas automaticamente todos os dias pela
                manhã.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ModalOpenCaixa
        isOpen={isOpen}
        onClose={onClose}
        initialPrice={caixa.saldo_final}
        handleOpen={handleOpenCaixa}
        refetch={refetchStatus}
      />
    </>
  );
}

export default ClosedCaixa;
