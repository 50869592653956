import React, {useEffect, useState, useContext} from "react";
import Loading from 'react-loading';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LeftMenu from "../../components/LeftMenu";
import pagamentoImg from '../../img/pagamento.png';
import './styles.css';
import moment from 'moment';
import { useFormik } from "formik";
import * as Yup from "yup";
//import creditCardType from 'credit-card-type';
import valid from 'card-validator'; //import statement
import InputMask from 'react-input-mask'
import axios from 'axios';
import { listarAssinaturasAsaas } from "../../services/api";
import { SidebarContext } from "../../AppRoutes";
import { RiErrorWarningLine } from "react-icons/ri";
import { MdPix } from "react-icons/md";
import { FaBarcode } from "react-icons/fa6";
import { IoIosCard } from "react-icons/io";
import { toast } from "react-toastify";


const Teste = styled.div`
  display: flex;
  margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
  height:auto;
  width:auto;
  transition: 150ms;
  background-color:rgb(247,247,247)!important;
  --background-color:white!important;
  overflow: initial;
  z-Index: 9;
`;


const Geral = () => {
    const navigate = useNavigate();
    const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES

    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const idEmpresa = empresaParse.id_empresa;
    const cnpj = empresaParse.cnpj;
    const razao = empresaParse.razao;
    const nomeEmpresa = empresaParse.name;

    const [subscriptionData, setSubscriptionData] = useState(null);
    const [paymentData, setPaymentData] = useState(null);

    useEffect(() => {
        const listarAssinaturas = async (id) => {
            const assinaturas = await listarAssinaturasAsaas(id);
            console.log("Assinaturas>",assinaturas);
            setSubscriptionData(assinaturas.data.subscriptionData?.data[0]);
            setPaymentData(assinaturas.data.paymentData?.data[0]);
            if(assinaturas.status === 204){
                navigate('/planos');
            }
        }
        listarAssinaturas(empresaParse.customer_asaas.id);
    }, []);

    const handleClickGoToPayments = () => {
        navigate('/forma-pagamento');
    }


    return (
        <div>
            <LeftMenu setSidebar={setSidebar} sidebar={sidebar}/>
            <Teste sidebar={sidebar}>
                <div className="m-5 planos">
                    <div className="input-group inputGroup-adicinaItem">
                        <div className="content-info-plan" style={{width:'30%'}}>
                            <img src={pagamentoImg} alt="icone" style={{width:'100px'}}/>                            
                            <h6>Informações de pagamentos</h6>
                            <div>
                                <span style={{fontWeight:600}}>Valor:</span>
                                <p>R$ {subscriptionData?.value.toFixed(2).toString().replace('.',',')} p/ {subscriptionData?.cycle==="MONTHLY"?"mês":"ano"}</p>
                            </div>
                            <div>
                                <span style={{fontWeight:600}}>Forma de pagamento:</span>
                                <p>{subscriptionData?.billingType}</p>
                            </div>

                            <p className="goToPayments" onClick={handleClickGoToPayments}>Ir para pagamentos</p>
                        </div>

                        <div className="content-info-plan" style={{width:'64%'}}>
                            <img src="https://cdn-icons-png.flaticon.com/512/2920/2920324.png" alt="icone" style={{width:'100px'}}/>
                            <h6>Informações do plano</h6>

                            {paymentData?.status==="PENDING" || paymentData?.status==="OVERDUE" ?
                                <div className="pendingPayment">
                                    <RiErrorWarningLine style={{fontSize:'30px', color:'rgb(255, 153, 0)'}}/>
                                    <p>Pagamento pendente</p>
                                </div>
                                :
                                <div style={{display:'flex', width:'100%', justifyContent:'space-around'}}>
                                    <div>
                                        <div className="infoPlanSpanText">
                                            <span style={{fontWeight:600}}>Plano contratado</span>
                                            <span>Premium</span>
                                        </div>
                                        <div className="infoPlanSpanText">
                                            <span style={{fontWeight:600}}>Período do plano</span>
                                            <span>{subscriptionData?.cycle==="MONTHLY"?"Mensal":"Anual"}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="infoPlanSpanText">
                                            <span style={{fontWeight:600}}>Início do plano</span>
                                            <span>{moment(subscriptionData?.dateCreated).format("DD/MM/YYYY")}</span>
                                        </div>
                                        <div className="infoPlanSpanText">
                                            <span style={{fontWeight:600}}>Renova em</span>
                                            <span>Indeterminado</span>
                                        </div>                                
                                    </div>
                                </div>
                            }
                            
                        </div>
                        
                    </div> 
                </div>  
            </Teste>
        </div>
    );
}

export default Geral; 