export function formatPrice(num) {
  if (!num) return "0,00";
  // Convert the number to a string with two decimal places
  let numStr = num.toFixed(2);

  // Split the integer and decimal parts
  let parts = numStr.split(".");

  // Format the integer part with thousand separators
  let integerPart = parts[0];
  let decimalPart = parts[1];

  // Use a regular expression to add thousand separators
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  // Combine the integer and decimal parts with a comma
  let brPrice = integerPart + "," + decimalPart;

  return brPrice;
}
