import React, { useContext, useState } from "react";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

import { IoAddCircleOutline } from "react-icons/io5";

import { IoMdLock } from "react-icons/io";
import { SlRefresh } from "react-icons/sl";
import { FiSearch } from "react-icons/fi";

import SangriaModal from "../../../components/Caixa/Modals/SangriaModal";
import { Modal } from "../../../components/Modal";
import SuprimentoModal from "../../../components/Caixa/Modals/SuprimentoModal";
import CloseCaixaModal from "../../../components/Caixa/Modals/CloseCaixaModal";
import { CaixaContext } from "../../../contexts/caixaContext";
import { formatPrice } from "../../../utils/formatPrice";
import Loading from "react-loading";
import { TbClockHour4 } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { IoMdOpen } from "react-icons/io";
import { getPedido } from "../../../services/api";
import { AuthContext } from "../../../contexts/auth";
import ModalPedido from "../../../components/ModalPedido";
import { toast } from "react-toastify";

function OpenedCaixa() {
  const [currentModal, setCurrentModal] = useState(null);

  const [showPedido, setShowPedido] = useState(true);
  const [infoPedido, setInfoPedido] = useState({});
  const [isPedidoLoading, setIsPedidoLoading] = useState(false);

  const empresa = localStorage.getItem('empresa')
  const empresaParse = JSON.parse(empresa)
  //onst objIdEmpresa = empresaParse._id;
  //const idEmpresa = empresaParse.id_empresa;
  const vinculo_empresa = empresaParse.cnpj;

  const { user } = useContext(AuthContext);

  console.log("user", user);

  const navigate = useNavigate();

  const {
    caixa,
    sangriasValue,
    suprimentosValue,
    caixaValue,
    isLoading,
    refetch,
    dinheiroValue,
    cartaoValue,
    pixValue,
  } = useContext(CaixaContext);

  const handleShowPedido = async (pedidoId) => {
    setShowPedido(!showPedido);
    try {
      const response = await getPedido(
        user._id,
        caixa.id_empresa,
        vinculo_empresa,
        pedidoId
      );
      if (showPedido) {
        setIsPedidoLoading(true);
        if (response.data.pedido) {
          //console.log(response.data.pedido)
          setInfoPedido(response.data.pedido[0]);
        }
        setIsPedidoLoading(false);
      }
    } catch (e) {
      console.log("error", e);
      if (e?.response?.data?.msg) {
        toast(e?.response.data.msg, { type: "error" });
      } else {
        toast("Erro ao tentar buscar pedido", { type: "error" });
      }
    }
  };

  const onCloseModal = () => {
    setCurrentModal(null);
  };

  const modalContent = {
    sangria: () => <SangriaModal onClose={onCloseModal} />,
    suprimento: () => <SuprimentoModal onClose={onCloseModal} />,
    closeCaixa: () => <CloseCaixaModal onClose={onCloseModal} />,
  };

  return (
    <>
      <div className="content-page">
        <div className="m-5">
          <div className="list-header">
            <div className="title">
              <h1>Caixa Aberto</h1>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between" /*, height:"80px"*/,
            }}
          >
            <div className="input-box-list" style={{ width: "30%" }}>
              <input
                className="input-field"
                id="search"
                placeholder="Pesquisar"
              />
              <i className="icon">
                <FiSearch />
              </i>
            </div>

            <div className="caixa-buttons">
              <button
                type="button"
                className="close-caixa-button"
                onClick={() => setCurrentModal("closeCaixa")}
              >
                <IoMdLock />
                FECHAR CAIXA
              </button>
              <button
                type="button"
                className="add-button"
                onClick={() => setCurrentModal("sangria")}
              >
                <IoAddCircleOutline />
                SANGRIA
              </button>
              <button
                type="button"
                className="add-button"
                onClick={() => setCurrentModal("suprimento")}
              >
                <IoAddCircleOutline />
                SUPRIMENTO
              </button>
              <button className="reload-button" onClick={() => refetch()}>
                <SlRefresh />
                Atualizar
              </button>
              <button
                className="reload-button"
                onClick={() => navigate("/caixa/historico")}
              >
                <TbClockHour4 />
                Histórico
              </button>
            </div>
          </div>
          {isLoading ? (
            <Loading
              type="spin"
              color="rgb(49, 140, 213)"
              height={56}
              width={56}
              className="loadingSpinHomeFilters"
            />
          ) : (
            <div className="caixa-infos">
              <table className="table">
                <thead>
                  <tr /*style={{borderBottom:"2px solid #4281FF"}}*/>
                    <th scope="col" style={{ paddingLeft: "15px" }}>
                      DATA/HORA
                    </th>
                    <th scope="col" style={{ paddingLeft: "15px" }}>
                      DESCRIÇÃO
                    </th>
                    <th scope="col" style={{ paddingLeft: "15px" }}>
                      VALOR
                    </th>
                    <th scope="col" style={{ paddingLeft: "15px" }}>
                      FORMA DE PAGAMENTO
                    </th>
                    <th scope="col" style={{ paddingLeft: "15px" }}></th>
                  </tr>
                </thead>
                <tbody className={"result search"}>
                  {caixa.caixa_items.length > 0 &&
                    caixa.caixa_items.map((item) => (
                      <tr
                        key={item._id}
                        className={`${item.id_pedido ? "pedido" : "default"}`}
                        onClick={() =>
                          item.id_pedido && handleShowPedido(item.id_pedido)
                        }
                      >
                        <td>
                          {new Date(item.createdAt).toLocaleDateString(
                            "pt-BR",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}
                        </td>
                        <td>
                          <>
                            {item.descricao &&
                            item.descricao
                              .toLowerCase()
                              .includes("pedido n") ? (
                              <>
                                <span>{item.descricao}</span>
                              </>
                            ) : (
                              `${item.descricao}`
                            )}
                          </>
                        </td>
                        <td>{formatPrice(item.valor)}</td>
                        <td>{item.tipo_lancamento}</td>
                        <td>{item.id_pedido && <IoMdOpen />}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div className="resume-caixa">
                <div className="resume-title">
                  <p>RESUMO</p>
                </div>
                <div className="resume-main-infos">
                  <p>
                    IDENTIFICADOR: <span>{caixa._id}</span>
                  </p>
                  <div>
                    <p>ABERTO EM:</p>
                    <span>
                      {new Date(caixa.createdAt).toLocaleDateString("pt-BR", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </span>
                  </div>
                  <p className="badge">ABERTO</p>
                </div>
                <p className="resume-divisor">RESUMO DO CAIXA</p>
                <div className="resume-second-box">
                  <div className="resume-second-info">
                    <p>SALDO INICIAL</p>
                    <span>{formatPrice(caixa.saldo_inicial)}</span>
                  </div>
                  <div className="resume-second-info">
                    <p>SUPRIMENTOS</p>
                    <span>{formatPrice(suprimentosValue)}</span>
                  </div>
                  <div className="resume-second-info">
                    <p>SANGRIAS</p>
                    <span>{formatPrice(sangriasValue)}</span>
                  </div>
                </div>
                <p className="resume-divisor">VALORES EM CAIXA</p>
                <div className="resume-second-box">
                  <div className="resume-second-info">
                    <p>VALOR EM DINHEIRO</p>
                    <span>{formatPrice(dinheiroValue)}</span>
                  </div>
                  <div className="resume-second-info">
                    <p>VALOR EM CARTÃO</p>
                    <span>{formatPrice(cartaoValue)}</span>
                  </div>
                  <div className="resume-second-info">
                    <p>VALOR EM PIX</p>
                    <span>{formatPrice(pixValue)}</span>
                  </div>
                  <div className="resume-second-info">
                    <p style={{ fontWeight: "bold" }}>TOTAL EM CAIXA</p>
                    <span>{formatPrice(caixaValue)}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {currentModal && (
        <Modal isOpen={!!currentModal} onClose={() => setCurrentModal(null)}>
          {modalContent[currentModal]()}
        </Modal>
      )}
      {infoPedido && (
        <ModalPedido
          showPedido={showPedido}
          setShowPedido={setShowPedido}
          infoPedido={infoPedido}
          showActions={false}
        />
      )}
    </>
  );
}

export default OpenedCaixa;
