import styled from "styled-components";
import React from 'react'

import PermissionGate from "../../services/PermissionGate";

import { useFormik } from "formik";
import * as Yup from "yup";

import * as AiIcons from 'react-icons/ai';
import { getRevendas, getEmpresas, updateUser } from "../../services/api";
import AsyncSelect from "react-select/async";

import { AuthContext } from "../../contexts/auth";
import { useEffect, useContext, useState } from "react";

import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CryptoJS from 'crypto-js';

import { useNavigate } from "react-router-dom";	

const DivEditUserContainer = styled.div`
  z-index:15;
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ showEditUser }) => (showEditUser ? 'none' :'flex' )};
  float: left;
  min-width: 100%;
  min-height: 100%;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 5px 0;
  list-style: none;
  background-color: rgba(0,0,0,0.5);
  background-clip: padding-box;
  align-items:center;
  justify-content:center;
`;

const DivEditUser = styled.div`
    z-index:20;
    position: absolute;
    top: 10%;
    left: 25%;
    float: left;
    min-width: 32%;
    min-height: 45%;
    max-width: 50%;
    padding: 5px 0;
    list-style: none;
    --background-color: white;
    background-color:rgb(247,247,247)!important;
    background-clip: padding-box;
    border-radius: 15px;
`;

const ModalEditUser = ({setEditUser, showEditUser, setRefresh, 
    selectData,
    setselectData,
    _idUserEdit, 
    usernameEdit, 
    emailEdit, 
    roleEdit, 
    editPerfil,
}) => {
    
    const { user } = useContext(AuthContext);

    const [resetPassword, setResetPassword] = useState(false);


    

    const mapResponseToValuesAndLabels = (data) => ({
    value: data.id_empresa,
    label: data.id_empresa+" - "+data.cnpj+" - "+data.name,
    });
      
    async function callApi(value) {
    
        const data = await getEmpresas(user._id)
        .then((response) => response.data.empresa)//response.json())
        .then((response) => response.map(mapResponseToValuesAndLabels))
        .then((response) =>
            response.filter((i) => i.label.toLowerCase().includes(value.toLowerCase()))
        );

        return data;
    }

    /*if(_idUserEdit!=''){
        //console.log("IdToEdit",_idUserEdit)
        //console.log('empresaCnpj',empresaVinculoCnpj)
        setselectData({value:'', label:'teste2'});
    }*/   

    //Validações
    const SignUpSchema = Yup.object().shape({
        email: Yup.string().email("Insira um e-mail válido").required("Obrigatório"),
        password: resetPassword && Yup.string().required("Obrigatório"),
        // confirmPassword: resetPassword && Yup.string().required("Obrigatório"),
        tipo: Yup.string().required("Obrigatório"),
        vinculo: selectData.value === '' && Yup.string().required("Obrigatório"),
        nome: Yup.string().required("Obrigatório"),
    });

    

    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        enableReinitialize: true,
        //enableReinitialize: showEditUser,
        initialValues: {
          email: emailEdit,
          password: "",
          //confirmPassword: "",
          tipo: roleEdit,
          //vinculo: empresaVinculoID ? empresaVinculoID+" | "+empresaVinculoCnpj +" | "+empresaVinculoNome : "" ,
          nome: usernameEdit,
        },
        onSubmit: (values) => {
          //alert(JSON.stringify(values, null, 2));
          //console.log(values);
          //console.log("RoleEdit:::",roleEdit);

          const email = values.email;
          const password=values.password;
          const name=values.nome;
          const vinculo_empresa=selectData.value;
          //console.log("vinculo_empresa",vinculo_empresa)
          //const vinculo='';
          var tipo = "";
          //console.log("values.tipo:::",values.tipo)
          if(values.tipo==="Administrador" || values.tipo==="Admin"){
            tipo = "Admin";
          }
          if(values.tipo=="Empresa" || values.tipo==="empresa"){
            tipo = "empresa";
          }
          if(values.tipo=="Revenda" || values.tipo==="revenda"){
            tipo = "revenda";
          }
          
          handleSubmitAPI(_idUserEdit, email,password,name,vinculo_empresa,tipo);
          
        },
    });

    // Lógica para atualizar os dados do usuário no localStorage
    // Get the existing data
    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user')
    var existing = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    //var existing = localStorage.getItem('user');
    // Convert the localStorage string to an array
    existing = JSON.parse(existing);

    const handleSubmitAPI = (_idUserEdit, email, password, nome, vinculo_empresa, role) => {
        const userID = user._id;
        ////console.log(_idUserEdit, userID, email, password, nome, vinculo_empresa, role);

    
        //console.log(_idUserEdit, userID, email, password, nome, vinculo_empresa, role);
        updateUser(_idUserEdit, userID, email, password, nome, vinculo_empresa, role).then(cast => {
            //console.log("Usuário editado com Sucesso!:::",cast)
            if(cast.status===200){

                if(editPerfil){
                    //console.log("Editou o próprio perfil")
                    // Atualiza os dados do usuário no localStorage
                    existing.email = email;
                    existing.name = nome;                    
                    // Save back to localStorage
                    localStorage.setItem('user', JSON.stringify(existing));
                }                

                toast(cast.data.msg,{ autoClose: 5000 , type: "success"});
                //navigate("/list-users");
                setRefresh(true);
                setEditUser(!showEditUser);
            }else{
                toast(cast.data.msg,{ autoClose: 5000 , type: "error"});
            }
            //navigate("/");
        }).catch(err => {
            //console.log('Ocorreu algum erro no servidor!');
            //console.log(err.response.data.msg);
            toast(err.response.data.msg,{ autoClose: 5000 , type: "error"});
        });
       

    }
    
    const closeEditUser = () => setEditUser(!showEditUser);



    return (
        <DivEditUserContainer showEditUser={showEditUser}>
            <DivEditUser>

                {/* // Teste Toastify */}                       
                {/* <ToastContainer position="top-center"/> */}

                {/* Teste Upload Images */}
                {/* <AiIcons.AiOutlineCloseCircle  onClick={closeEditUser} style={{marginLeft:"96%", fontSize:"25px", cursor:"pointer"}}/> */}

                <div className="m-5" style={{width:"95%"}}>
                <div style={{marginBottom:"20px", marginTop:"-30px", fontSize:"25px"}}>
                    {editPerfil ?
                        <h4>Editar Perfil</h4>
                        :
                        <h4>Editar Usuário</h4>
                    }
                </div>

                    {/* ///////////////////////////////// */}
                    <form onSubmit={formik.handleSubmit} style={{marginTop:"-30px", maxWidth:"920px"}}>    

                        <div style={{display:"flex", justifyContent:"right"/*, height:"80px"*/}}>
                            <div className="continue-button" style={{width:"150px", display:"flex", justifyContent:"right"}}>
                                <button style={{marginTop:"12px", height:"49.6px", width:"150px"}} type="submit">
                                <a><AiIcons.AiOutlineSave style={{marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/>Salvar</a> 
                                </button>
                            </div>                                 
                            <div className="back-button">                                                       
                                <button onClick={closeEditUser} type="button">    
                                    <AiIcons.AiOutlineCloseCircle style={{color:"#ff4c4c",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a>Fechar</a> 
                                </button>
                            </div>                            
                        </div>           
                    
                        <div className="input-group-edit" style={{paddingBottom:"0px", marginBottom:"0px"}}>

                            <div className="input-box" style={{width:"49%"}}>
                                <label>Nome</label>
                                <input 
                                placeholder="Digite o nome do Usuário"
                                id="nome"
                                type="nome"
                                name="nome"
                                //onChange={e => setUsernameEdit(e.target.value)/*formik.handleChange*/}
                                //value={usernameEdit}
                                onChange={formik.handleChange}
                                value={formik.values.nome}
                                />
                                {formik.errors.nome && (
                                    <div className="error">{formik.errors.nome}</div>
                                )}
                            </div>


                            <div className="input-box" style={{width: "49%"}}>
                                <label htmlFor="email">E-mail de acesso</label>
                                <input 
                                placeholder="Digite o e-mail que será utilizado para acessar o sistema" 
                                id="email"
                                name="email"
                                type="email"
                                //value={email}
                                //onChange={e => setEmail(e.target.value)}
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                />
                                {formik.errors.email && (
                                    <div className="error">{formik.errors.email}</div>
                                )}
                            </div>

                            {editPerfil ? null :
                            <div className="input-box" style={{width: "30%"}}>
                                <label>Papel do Usuário</label>
                                {/*<!-- Custom select structure --> */}
                                <div className="selectdiv" style={{minWidth:"168px"}}>
                                <label style={{width:"100%"}}>
                                    <select 
                                    id="tipo"
                                    type="tipo"
                                    name="tipo"
                                    onChange={formik.handleChange}
                                    value={formik.values.tipo}
                                    className={formik.values.tipo == ""?'invalid-option':'beleza'}
                                    style={{width:"100%"}}
                                    >
                                        {/* <option value="" className="invalid-option">Selecione um Tipo</option> */}
                                        {
                                        roleEdit == "Admin" ? 
                                        <>    
                                        <PermissionGate permissions={['viewRoleAdmin']}><option>Administrador</option></PermissionGate>   
                                        <PermissionGate permissions={['viewRoleEmpresa']}><option>Empresa</option></PermissionGate>                                                                                                               
                                        <PermissionGate permissions={['viewRoleRevenda']}><option>Revenda</option></PermissionGate> 
                                        </> 
                                        :
                                        roleEdit == "empresa" ? 
                                        <>      
                                        <PermissionGate permissions={['viewRoleEmpresa']}><option>Empresa</option></PermissionGate>                               
                                        <PermissionGate permissions={['viewRoleAdmin']}><option>Administrador</option></PermissionGate>                                         
                                        <PermissionGate permissions={['viewRoleRevenda']}><option>Revenda</option></PermissionGate> 
                                        </>                                                                     
                                        :
                                        <>   
                                        <PermissionGate permissions={['viewRoleRevenda']}><option>Revenda</option></PermissionGate>  
                                        <PermissionGate permissions={['viewRoleAdmin']}><option>Administrador</option></PermissionGate>   
                                        <PermissionGate permissions={['viewRoleEmpresa']}><option>Empresa</option></PermissionGate>                                                                                                                                                       
                                        </>  
                                        }
                                        
                                    </select>
                                    {formik.errors.tipo && (
                                    <div className="error" style={{fontWeight: "lighter"}}>{formik.errors.tipo}</div>
                                    )}
                                </label>
                                </div>
                                {/*<!-- Custom select structure --> */}
                            </div>  
                            }

                            <div className="input-box" 
                            style={{
                                width: resetPassword?"33%":"38%", 
                                }}>
                                <label>Deseja Redefinir a Senha?</label>
                                {/*<!-- Custom checbox structure --> */}
                                    <div style={{marginTop:"10px"}}>
                                        <input type="checkbox" id="reset" name="reset" onChange={() => setResetPassword(!resetPassword)} checked={resetPassword}/>
                                        <label htmlFor="reset" style={{marginLeft:"5px"}}>Sim, refinir agora</label>
                                    </div>

                                    {/* <div>
                                        <input type="checkbox" id="resetSim" name="resetSim" checked={props.checked === option.value}/>
                                        <label for="resetSim">Sim</label>
                                    </div>                                  */}
                                {/*<!-- Custom checkbox structure --> */}
                            </div> 

                            <div className="input-box" 
                            style={{
                            width: resetPassword?"30%":"10%",
                            //height: !resetPassword && "0rem",
                            opacity: resetPassword?"1":"0",
                            //marginBottom: !resetPassword && "0rem",
                            transition: "200ms linear"
                            }}>
                                
                                <label htmlFor="password">Nova Senha</label>                            
                                <input 
                                placeholder="Digite a nova senha" 
                                id="password"
                                name="password"
                                type="password"
                                //value={password}
                                //onChange={e => setPassword(e.target.value)}
                                onChange={formik.handleChange}
                                value={formik.values.password}
                                />
                                {formik.errors.password && (
                                    <div className="error">{formik.errors.password}</div>
                                )}
                            </div>
                                                                                
    
                            {editPerfil ? null :
                            <div className="input-box" style={{width:"100%", transition:"200ms"}}>
                                <label>Vincular Empresa</label>

                                {/*<!-- Custom select structure --> */}
                                <div className="selectdiv-teste" style={{width:"100%", maxWidth:"100%"}}>
                                    <label style={{width:"100%"}}>
                                    <AsyncSelect
                                        cacheOptions
                                        loadOptions={callApi}
                                        onChange={(data) => {
                                        setselectData(data);
                                        }}
                                        value={selectData}
                                        defaultOptions
                                        id="vinculo"
                                        type="vinculo"
                                        name="vinculo"   
                                        className="asyncSelectInput"                                    
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,                                                
                                                margin:"0.6rem 0",
                                                paddingLeft: "2.5rem",
                                                height: "45px",
                                                border:"none",
                                                outline: state.isFocused?'1px solid #4070f4;':'0',
                                                borderRadius: "10px",
                                                boxShadow: "1px 1px 6px #0000001c",
                                                fontSize: "0.8rem",                                               
                                            }),
                                            input:(style) => ({
                                                ...style,
                                                color:"black",
                                            }),
                                            singleValue: (baseStyles) => ({
                                                ...baseStyles,
                                                color:selectData.value?"black":"gray",
                                            }),
                                            valueContainer: (baseStyles) => ({
                                                ...baseStyles,
                                                fontWeight: "lighter",
                                            }),
                                            option: (styles) => {
                                                return {
                                                ...styles,
                                                fontWeight: "lighter", 
                                                color: "black",                                               
                                                };
                                            },                                           
                                        }}         
                                    />                                        
                                        {formik.errors.vinculo && (
                                        <div className="error" style={{fontWeight: "lighter"}}>{formik.errors.vinculo}</div>
                                        )}
                                    </label>
                                </div> 
                                {/*<!-- Custom select structure --> */}
                                {/*<!-- Custom select structure --> }
                                <div className="selectdiv" style={{width:"100%", maxWidth:"100%"}}>
                                <label style={{width:"100%"}}>
                                    <select 
                                    id="vinculo"
                                    type="vinculo"
                                    name="vinculo"
                                    onChange={formik.handleChange}
                                    value={formik.values.vinculo}
                                    className={formik.values.vinculo == ""?'invalid-option':'beleza'}
                                    style={{width:"100%", maxWidth:"100%"}}
                                    >
                                        {
                                        empresaVinculoCnpj !== '' ?
                                                                              
                                        user.role=='Admin' ?  
                                            <>
                                            <option>{formik.values.vinculo}</option>                                                                                      
                                            {empresas.map((empresa) => ( 
                                                empresa.id_empresa !==  parseInt(formik.values.vinculo.split(" | ")[0]) &&                                                                                                            
                                                <option key={empresa._id} value={empresa.id_empresa+" | "+ empresa.cnpj+" | "+ empresa.name}>{empresa.id_empresa+" | "+ empresa.cnpj+" | "+ empresa.name}</option>                                                                                                                                                                   
                                            ))}
                                            </> 
                                            :
                                            <>
                                            <option>{formik.values.vinculo}</option> 
                                            {revendas.map((revenda) => (   
                                                revenda.id_empresa !==  parseInt(formik.values.vinculo.split(" | ")[0]) &&                                                                                             
                                                <option key={revenda._id} value={revenda.id_empresa+" | "+revenda.cnpj+" | "+ revenda.name}>{revenda.id_empresa+" | "+revenda.cnpj+" | "+ revenda.name}</option>                                                                                                                           
                                            ))}
                                            </> 
                                        :
                                        // <option value="" className="invalid-option">Vincular acesso na empresa</option>
                                        
                                        user.role=='Admin' ?     
                                            <>
                                            <option className="invalid-option" value={''}>Vincular acesso na empresa</option>                                                                                                                             
                                            {empresas.map((empresa) => (                                                                                                                
                                                <option key={empresa._id} value={empresa.id_empresa+" | "+empresa.cnpj+" | "+ empresa.name}>{empresa.id_empresa+" | "+empresa.cnpj+" | "+ empresa.name}</option>                                                                                                                        
                                            ))}
                                            </>
                                            :
                                            <>
                                            <option className="invalid-option" value={''}>Vincular acesso na empresa</option>     
                                            {revendas.map((revenda) => (                                                                                               
                                                <option key={revenda._id} value={revenda.id_empresa+" | "+revenda.cnpj+" | "+ revenda.name}>{revenda.id_empresa+" | "+revenda.cnpj+" | "+ revenda.name}</option>                                                                                                                           
                                            ))}
                                            </>
                                        
                                        }
                                       
  
                                    </select>
                                    {formik.errors.vinculo && (
                                    <div className="error" style={{fontWeight: "lighter"}}>{formik.errors.vinculo}</div>
                                    )}
                                </label>
                                </div>
                                {<!-- Custom select structure --> */}
                            </div> 
                            } 

                        </div>

                    </form>
                    {/* ////////////////////////////////////   */}

                
                                            
                {/* <a className="btn btn-sm btn-danger" onClick={closeModelImg} style={{color:"rgb(120,120,120)", background:"white"}}>Fechar</a> */}

                </div>
                {/* Fim Upload Images */}
                

            </DivEditUser>
        </DivEditUserContainer>
    )
}

export default ModalEditUser;