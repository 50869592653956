import { useContext } from "react";
import { CaixaContext } from "../../../contexts/caixaContext";
import OpenedCaixa from "../OpenedCaixa";
import ClosedCaixa from "../ClosedCaixa";

const CaixaComponent = () => {
  const { caixa, isLoading } = useContext(CaixaContext);

  return <>{caixa.status === "open" ? <OpenedCaixa /> : <ClosedCaixa />}</>;
};

export default CaixaComponent;
